import React from 'react'
import _ from 'lodash'

import { NavigationEntry } from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { WorkflowNavRoute } from './workflow-nav-route'
import { WorkflowNode } from './workflow-node'

interface IWorkflowNavEntryProps {
  entry: NavigationEntry
  onSelect: (id: string) => void
}

export const WorkflowNavEntry = (props: IWorkflowNavEntryProps) => {
  const { entry, onSelect } = props
  return (
    <WorkflowNode onClick={entry.sourceId ? () => onSelect(entry.sourceId) : undefined}>
      <h4>{entry.sourceId}</h4>
      <p>Destinations:</p>
      {_.map(entry.destinations, (route) => (
        <WorkflowNavRoute route={route} onSelect={onSelect} />
      ))}
    </WorkflowNode>
  )
}
