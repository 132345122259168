declare let __API_HOST__: string
declare let __PROXY_HOST__: string

import { Entity } from 'shared-libs/models/entity'
import moment from 'moment'
import { getCloudAccountFromApi, isProductionApi } from 'shared-libs/helpers/utils'
import { Store } from 'shared-libs/models/store'
import _ from 'lodash'
import { SchemaUris } from 'shared-libs/models/schema'

const IOS_CRASHYLTICS_PROJECT = 'ios:co.LoadDOCs.appstore'
const ANDROID_CRASHLYTICS_PROJECT = 'android:com.convoy.loaddoc'

const CLIENT_DATA_INTERVAL = 7 * 24 * 3600   // 1 week

export function getKibanaUrlForEntity(entity: Entity): string {
  const entityId = entity.get('uniqueId')
  const creationDate = moment(entity.get('creationDate')).toISOString()
  const host = __API_HOST__ || __PROXY_HOST__

  if (isProductionApi(host)) {
    return `http://logserver.c.withvector-infrastructure.internal:5601/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${creationDate}',to:now))&_a=(columns:!(msg),filters:!(),index:'aafec930-e8ae-11eb-8873-79692bfbd1a0',interval:auto,query:(language:kuery,query:%22${entityId}%22),sort:!(!('@timestamp',desc)))`
  } else {
    const cloudAccount = getCloudAccountFromApi(host)
    return `http://logserver.c.withvector-infrastructure.internal:5601/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${creationDate}',to:now))&_a=(columns:!(msg),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'87e35bf0-e8ae-11eb-8873-79692bfbd1a0',key:cloud.account.id,negate:!f,params:(query:${cloudAccount}),type:phrase),query:(match_phrase:(cloud.account.id:${cloudAccount})))),index:'87e35bf0-e8ae-11eb-8873-79692bfbd1a0',interval:auto,query:(language:kuery,query:%22${entityId}%22),sort:!(!('@timestamp',desc)))`
  }
}

export function getAmplitudeLinkForUserId(userId: string): string {
  return `https://analytics.amplitude.com/withvector/project/472741/search/${userId}`
}

export function getSematextLinkForUserId(user: Entity, entity?: Entity): string {
  const userId = user.get('uniqueId')
  const startDateString = entity ? entity.get('creationDate') : user.get('creationDate')
  const endDate = Date.now()
  const startDate = moment(startDateString).toDate().getTime()
  const offset = (endDate - startDate) / 1000
  return `https://apps.sematext.com/ui/logs/64035/explore?ad-hoc=userProperties.userId.raw%3A${userId}%3Afalse%3Af&startDate=${startDate}&endDate=${endDate}&offset=${offset}`
}

export function getIosCrashylticsLinkForUserId(userId: string) {
  return getCrashlyticsLink(userId, IOS_CRASHYLTICS_PROJECT)
}

export function getAndroidCrashylticsLinkForUserId(userId: string) {
  return getCrashlyticsLink(userId, ANDROID_CRASHLYTICS_PROJECT)
}

function getCrashlyticsLink(userId: string, projectName: string) {
  return `https://console.firebase.google.com/u/0/project/loaddocs-api/crashlytics/app/${projectName}/search?time=last-ninety-days&q=${userId}&types=crash`
}

interface RelatedEntities {
  users: Entity[]
  entities: Entity[]
}
export async function fetchRelatedEntities(store: Store, entity: Entity): Promise<RelatedEntities> {
  const userEdges = entity.getFilteredAssociations((details) => {
    return (
      details.subschema.schema.entityType === SchemaUris.USER &&
      details.value.entityId !== entity.get('uniqueId')
    )
  })
  const userIds = _.map(userEdges, (edge) => edge.value.entityId)

  const otherEdges = entity.getFilteredAssociations((details) => {
    return (
      details.subschema.schema.entityType !== SchemaUris.USER &&
      details.value.entityId !== entity.get('uniqueId')
    )
  })
  const otherEdgeIds = _.map(otherEdges, (edge) => edge.value.entityId)

  const [users, entities] = await Promise.all([
    store.findRecords(_.uniq(userIds)),
    store.findRecords(_.uniq(otherEdgeIds)),
  ])

  return { users, entities }
}
