import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import apis from 'browser/app/models/apis'
import { getPostedByName } from 'browser/app/utils/utils'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { If } from 'browser/components/atomic-elements/atoms/if'
import { CommentInput } from 'browser/components/atomic-elements/organisms/feed/comment-input'
import { CommentItem } from 'browser/components/atomic-elements/organisms/feed/document-events/comment-item'

const formatDate = (date) => {
  return moment(date).format('ddd, MMM D, YYYY, h:mm A')
}

interface ICommentsListProps extends IBaseProps {
  activity: any
  entity: any
  onNewComment: (arg0: any) => void
}

// TODO(peter/louis): We should maybe import CommentList from 'browser/components/document-card/comment-list';
// but they differ on arguments activity vs document
export class CommentsList extends React.Component<ICommentsListProps, any> {
  public static defaultProps: Partial<ICommentsListProps> = {
    activity: {},
    onNewComment: _.noop,
  }

  constructor(props) {
    super(props)
    this.state = {
      comments: this.props.activity.comments || [],
    }
  }

  public render() {
    return (
      <div
        className={this.props.className}
        data-debug-id='details-comments-panel'
      >

        <div className='c-detailPanel-scrollArea js-c-detailPanel-scrollArea'>
          <div className='u-bumperTop'>
            <If
              className='u-textCenter u-bumperTop u-bumperBottom--xl c-helpBlock'
              condition={this.state.comments.length === 0}
            >
              There are no comments
            </If>
            {this.state.comments.map(this.renderCommentItem)}
          </div>
        </div>
        <CommentInput
          onSubmit={this.handlePostComment}
        />
      </div>
    )
  }

  private handlePostComment = (comment) => {
    const { entity } = this.props
    const comments = this.state.comments
    return entity.addComment(comment, navigator.language).then((data) => {
      comments.push({
        comment: data.comment.text,
        posted_by: data.createdBy,
        posted_time: data.creationDate,
      })
      this.setState({ comments })
      this.props.onNewComment(comment)
    })
  }

  private renderCommentItem(comment, index) {
    const item = {
      comment: {
        text: comment.comment,
      },
      createdBy: {
        displayName: getPostedByName(comment.posted_by),
      },
      creationDate: comment.posted_time,
    }
    return (
      <CommentItem
        isFirst={index === 0}
        item={item}
        key={index}
      />
    )
  }
}
