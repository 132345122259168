import _ from 'lodash'
import Platform from 'platform'
import PropTypes from 'prop-types'
import React from 'react'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'

const IntroModalAppStore = require('images/intro-modal/appstoreDownload.svg')
const IntroModalPlayStore = require('images/intro-modal/playstoreDownload.svg')

type IGetMobileAppProps = IBaseProps

export class GetMobileApp extends React.Component<IGetMobileAppProps, any> {

  public static contextTypes = {
    settings: PropTypes.object,
  }

  public render() {
    let content
    if (Platform.os.family === 'iOS') {
      content = this.renderIOSContent()
    } else if (Platform.os.family === 'Android') {
      content = this.renderAndroidContent()
    } else {
      content = this.renderUnknownPlatformContent()
    }

    return (
      <AppNavigatorContext.Consumer>
        {({ settings }) => (
          <React.Fragment>
            <Head
              title='Get Mobile App'
            />
            <div
              className='u-height100 js-pagesIndex mr3 ml3'
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div className='f3 b pb2'>
                Get the Vector App
              </div>
              {content}
            </div>
          </React.Fragment>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderAndroidContent() {
    return (
      <a
        href='https://play.google.com/store/apps/details?id=com.convoy.loaddoc&amp;ah=l6_TxXcmvGYIdjuqHYBWyNn2mbY'
        style={{ width: '50%' }}
      >
        <img
          alt='Download app for Android Devices'
          src={IntroModalPlayStore}
          style={{ display: 'block', margin: 'auto'}}
        />
      </a>
    )
  }

  private renderIOSContent() {
    return (
      <a
        href='https://itunes.apple.com/us/app/loaddocs/id1041596066'
        style={{ width: '60%' }}
      >
        <img
          alt='Download app for iOS Devices'
          src={IntroModalAppStore}
          style={{ display: 'block', margin: 'auto' }}
        />
      </a>
    )
  }

  private renderUnknownPlatformContent() {
    return (
      <div className='row'>
        <div className='col-xs-6'>
          <a
            href='https://play.google.com/store/apps/details?id=com.convoy.loaddoc&amp;ah=l6_TxXcmvGYIdjuqHYBWyNn2mbY'
          >
            <img
              alt='Download app for Android Devices'
              src={IntroModalPlayStore}
            />
          </a>
        </div>
        <div className='col-xs-6'>
          <a
            href='https://itunes.apple.com/us/app/loaddocs/id1041596066'
          >
            <img
              alt='Download app for iOS Devices'
              src={IntroModalAppStore}
            />
          </a>
        </div>
      </div>
    )
  }

}
