import _ from 'lodash'

import React from 'react'
import ReactDOM from 'react-dom'
import { components } from 'react-select'
import { linter } from '@codemirror/lint'
import { SyntaxNode } from '@lezer/common'
import { syntaxTree } from '@codemirror/language'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { EditorSelection, EditorState, StateField } from '@codemirror/state'
import { hoverTooltip, EditorView, Tooltip } from '@codemirror/view'
import { Classes, Icon, Intent, Position, Tooltip as BlueprintTooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import 'browser/app/pages/app/tools/_tools.scss'

import { Try } from 'shared-libs/helpers/utils'
import { edgeFromEntity } from 'shared-libs/helpers/formulas/modules'
import { Entity } from 'shared-libs/models/entity'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { EntityErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/entity-error-block'
import { EntitySelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/entity-select-field'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { LocalStorageContext } from 'browser/contexts/local-storage/local-storage-context'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import apis from 'browser/app/models/apis'
import { Toast } from 'browser/mobile/components/toast/toast'

type ICloningToolProps = IBaseProps

type HoverInfo = {
  path: string[]
  node: SyntaxNode
  view: EditorView
}

interface ICloningToolState {
  sourceEntity?: Entity
  cloneText?: string
  hoverInfo?: HoverInfo
  errors?: any
}

const STORAGE_KEY_SOURCE_UUID = 'vector.cloning-tool.source-uuid'
const STORAGE_KEY_CLONE_JSON = 'vector.cloning-tool.clone-json'

function TooltipContainer({ children, title }) {
  return (
    <div className="ma2">
      <div>{children}</div>
      <div className="tc">{title}</div>
    </div>
  )
}

export class CloningTool extends React.Component<ICloningToolProps, ICloningToolState> {
  static contextType = LocalStorageContext
  declare context: React.ContextType<typeof LocalStorageContext>

  private sourceEditor = React.createRef<CodeMirrorInput>()
  private destinationEditor = React.createRef<CodeMirrorInput>()
  private tooltipPortalRoot: HTMLDivElement

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    // recover in-progress work
    const sourceUUID = this.context.storedValues[STORAGE_KEY_SOURCE_UUID]
    const cloneText = this.context.storedValues[STORAGE_KEY_CLONE_JSON]

    if (sourceUUID) {
      // reload source entity
      void apis
        .getStore()
        .findRecord(sourceUUID)
        .then((sourceEntity: Entity) => {
          this.setState({
            sourceEntity,
          })
        })
    }

    this.setState({
      cloneText,
    })
  }

  render() {
    return (
      <div className="grid-block vertical">
        <CardHeader className="c-cardHeader--nonSeparating">
          <CardHeaderItem
            className="c-cardHeader-item--grow c-cardHeader-item--center"
            title="Entity Cloner"
          />
          {this.renderGlobalToolbar()}
        </CardHeader>
        {this.renderCard()}
        {this.renderTooltipPortal()}
      </div>
    )
  }

  private renderErrors() {
    const { errors, cloneText } = this.state
    if (_.isEmpty(errors)) {
      return
    }
    const json = Try(() => JSON.parse(cloneText))
    if (!json) {
      return
    }
    const entity = new Entity(json, apis)
    return <EntityErrorBlock entity={entity} errors={errors} />
  }

  private renderCard() {
    return (
      <div className="c-tools grid-block">
        <div className="grid-content">
          {this.renderErrors()}
          {this.renderEntitySelect()}
          {this.renderEditors()}
        </div>
      </div>
    )
  }

  private renderGlobalToolbar() {
    const { sourceEntity, cloneText } = this.state

    const clearable = !_.isNil(sourceEntity) || !_.isEmpty(cloneText)
    const clonable = !_.isNil(sourceEntity)
    const saveable = !_.isNil(sourceEntity)

    return (
      <>
        <Button className="ml2" isDisabled={!clearable} size="large" onClick={this.handleResetData}>
          Clear
        </Button>
        <Button
          className="ml2"
          isDisabled={!clonable}
          size="large"
          onClick={this.handleCloneSource}
        >
          Clone
        </Button>
        <Button
          className="ml2"
          isDisabled={!saveable}
          intent={Intent.PRIMARY}
          size="large"
          onClick={this.handleSave}
        >
          Save
        </Button>
      </>
    )
  }

  private renderEntitySelect() {
    const { sourceEntity } = this.state
    const sourceEdge = sourceEntity ? edgeFromEntity(sourceEntity) : undefined

    return (
      <Section title="Select Source Entity">
        <EntitySelectField
          entityType="/1.0/entities/metadata/entity.json"
          isCreatable={false}
          placeholder="Type to search, or enter a UUID"
          orders={[
            {
              path: 'modifiedDate',
              type: 'descending',
              valueType: 'date',
            },
          ]}
          isRequired={true}
          showLinkIcon={true}
          returnValueAsEdge={false}
          optionRenderer={(props) => this.renderOption(props)}
          onChange={this.handleSelectedSourceEntity}
          value={sourceEdge}
        />
      </Section>
    )
  }

  private renderOption(props: any, subheadingPaths?: string[]) {
    const entity: Entity = props?.data
    if (!entity || !(entity instanceof Entity)) {
      return null
    }
    const title = entity.get('precomputation.displayName') || entity.displayName
    if (!title) {
      return null
    }
    return (
      <components.Option {...props}>
        <div className="f5">{title}</div>
        {subheadingPaths?.map((path, idx) => (
          <div key={idx} className="f6">
            {entity.get(path)}
          </div>
        ))}
      </components.Option>
    )
  }

  private renderEditors() {
    const { sourceEntity, cloneText } = this.state
    const sourceJson = sourceEntity ? JSON.stringify(sourceEntity.content, null, 2) : undefined

    return (
      <div className="row">
        <div className="col-xs-6">
          <Section title="Source">
            <CodeMirrorInput
              maxHeight='600px' // TODO: remove, and add resize capability
              ref={this.sourceEditor}
              value={sourceJson}
              extensions={[json(), hoverTooltip(this.handleHover)]}
            />
          </Section>
        </div>
        <div className="col-xs-6">
          <Section title="Clone" headerControls={this.renderCloneSectionControls()}>
            <CodeMirrorInput
              maxHeight='600px' // TODO: remove, and add resize capability
              ref={this.destinationEditor}
              value={cloneText}
              extensions={[
                json(),
                linter(jsonParseLinter()),
                hoverTooltip(this.handleHover, { hoverTime: 200 }),
              ]}
              onChange={(text) => {
                this.context.onChange(STORAGE_KEY_CLONE_JSON, text)
                this.setState({
                  cloneText: text,
                  errors: undefined,
                })
              }}
            />
          </Section>
        </div>
      </div>
    )
  }

  private renderCloneSectionControls(): React.ReactElement {
    return (
      <BlueprintTooltip content="Open in new tab" position={Position.BOTTOM}>
        <Button
          className={Classes.MINIMAL}
          size="sm"
          onClick={this.handleNavigateToCloneByUUID}
        >
          <Icon icon={IconNames.SHARE} />
        </Button>
      </BlueprintTooltip>
    )
  }

  private renderTooltipPortal() {
    if (!this.tooltipPortalRoot) {
      return
    }
    return ReactDOM.createPortal(this.renderTooltip(), this.tooltipPortalRoot)
  }

  private renderTooltip() {
    const { hoverInfo } = this.state
    const { path } = hoverInfo

    const isArrayItem = /\d+/.test(_.last(path))

    return (
      <TooltipContainer title={_.join(hoverInfo.path, '.')}>
        <Button onClick={() => this.handleCopy(hoverInfo)} className="ph2">
          <BlueprintTooltip
            content="Copy to clipboard"
            position={Position.TOP}
            hoverOpenDelay={200}
          >
            <Icon title={null} icon={IconNames.DUPLICATE} />
          </BlueprintTooltip>
        </Button>
        <Button onClick={() => this.handleSelect(hoverInfo)} className="ph2">
          <BlueprintTooltip content="Highlight node" position={Position.TOP} hoverOpenDelay={200}>
            <Icon title={null} icon={IconNames.TEXT_HIGHLIGHT} />
          </BlueprintTooltip>
        </Button>
        <Button onClick={() => this.handleLocate(hoverInfo)} className="ph2">
          <BlueprintTooltip content="Locate node" position={Position.TOP} hoverOpenDelay={200}>
            <Icon title={null} icon={IconNames.LOCATE} />
          </BlueprintTooltip>
        </Button>
        <Button onClick={() => this.handleRemovePath(hoverInfo)} className="ph2">
          <BlueprintTooltip
            content="Remove from clone"
            position={Position.TOP}
            hoverOpenDelay={200}
          >
            <Icon title={null} icon={IconNames.TRASH} />
          </BlueprintTooltip>
        </Button>
        <Button onClick={() => this.handleClonePath(hoverInfo)} className="ph2">
          <BlueprintTooltip
            content="Set path in clone"
            position={Position.TOP}
            hoverOpenDelay={200}
          >
            <Icon title={null} icon={IconNames.INSERT} />
          </BlueprintTooltip>
        </Button>
        {isArrayItem && (
          <Button onClick={() => this.handleAppendArrayItem(hoverInfo)} className="ph2">
            <BlueprintTooltip
              content="Append to array"
              position={Position.TOP}
              hoverOpenDelay={200}
            >
              <Icon title={null} icon={IconNames.PLUS} />
            </BlueprintTooltip>
          </Button>
        )}
        {isArrayItem && (
          <Button onClick={() => this.handleCloneSubarray(hoverInfo)} className="ph2">
            <BlueprintTooltip content="Copy subarray" position={Position.TOP} hoverOpenDelay={200}>
              <Icon title={null} icon={IconNames.LIST} />
            </BlueprintTooltip>
          </Button>
        )}
      </TooltipContainer>
    )
  }

  private handleCloneSource = () => {
    const { sourceEntity } = this.state
    const cloneText = JSON.stringify(sourceEntity.clone().content, null, 2)

    this.context.onChange(STORAGE_KEY_CLONE_JSON, cloneText)
    this.setState({
      cloneText,
    })
  }

  private handleResetData = () => {
    this.context.onChange(STORAGE_KEY_SOURCE_UUID, undefined)
    this.context.onChange(STORAGE_KEY_CLONE_JSON, undefined)
    this.setState({
      sourceEntity: undefined,
      cloneText: undefined,
      errors: undefined,
    })
  }

  private handleSelectedSourceEntity = (sourceEntity: Entity) => {
    this.context.onChange(STORAGE_KEY_SOURCE_UUID, sourceEntity.uniqueId)
    this.setState({
      sourceEntity,
    })
  }

  private handleHover = (view: EditorView, pos: number, side: 1 | -1): Promise<Tooltip> => {
    // find word start/end positions, so CM sees the appropriate hover region
    // and makes the tooltip a bit stickier during mouse movement
    const { from, to, text } = view.state.doc.lineAt(pos)
    let start = pos,
      end = pos
    while (start > from && /\w/.test(text[start - from - 1])) start--
    while (end < to && /\w/.test(text[end - from])) end++

    const node = this.resolveSyntaxNode(view.state, pos)
    if (start === end) {
      // if `pos` isn't within a word, then expand to node's boundaries, if possible
      if (node.from === start) {
        // can expand downward, since `node.from` is near the hover position.
        start = node.from
        end = node.to
      } else {
        // cannot expand upward, since `node.from` might cause hover to not
        // render, so just nudge forward a bit so the hover area is not 0.
        end++
      }
    }

    const path = jsonPathAtSyntaxNode(view.state, node)
    if (_.isEmpty(path)) {
      // likely a top-level syntax element, like a comma. Ignore.
      return
    }

    const tooltip: Tooltip = {
      pos: start,
      end,
      above: true,
      strictSide: true,
      create: (view) => {
        this.tooltipPortalRoot = document.createElement('div')
        return {
          dom: this.tooltipPortalRoot,
          mount: () => {
            this.forceUpdate()
          },
        }
      },
    }

    // This function can return a `Tooltip` directly, however calling the
    // `setState` in `mount` caused CM to throwing an error due to dispatching a
    // transaction during an existing view update, e.g.  select all + hover.

    // Somehow, returning a Promise that calls `setState` before resolving the
    // tooltip, and then calling `forceUpdate` during `mount`, does not cause
    // that exception.
    return new Promise((res) => {
      this.setState(
        {
          hoverInfo: {
            path,
            node,
            view,
          },
        },
        () => res(tooltip)
      )
    })
  }

  private handleCopy = (hoverInfo: HoverInfo) => {
    const { path, node, view } = hoverInfo

    const value = view.state.sliceDoc(node.from, node.to)

    navigator.clipboard.writeText(value)

    Toast.show({ message: `Copied ${_.join(path, '.')} to clipboard` })
  }

  private handleSelect = (hoverInfo: HoverInfo) => {
    const { path, node, view } = hoverInfo

    const oppositeView = this.getOppositeView(view)
    const oppositeHoverNode = syntaxNodeAtJsonPath(oppositeView, path)

    this.selectSyntaxNode(view, node)
    this.selectSyntaxNode(oppositeView, oppositeHoverNode)
    this.scrollToSyntaxNode(oppositeView, oppositeHoverNode)
  }

  private handleLocate = (hoverInfo: HoverInfo) => {
    const { path, view } = hoverInfo

    const oppositeView = this.getOppositeView(view)
    const oppositeHoverNode = syntaxNodeAtJsonPath(oppositeView, path)

    if (!oppositeHoverNode) {
      Toast.show({ message: `Could not find path '${_.join(path, '.')}' path in opposite view`, timeout: 2000 })
    }

    this.scrollToSyntaxNode(oppositeView, oppositeHoverNode)
  }

  private handleClonePath = (hoverInfo: HoverInfo) => {
    const { path } = hoverInfo
    const sourceJson = this.getSourceJson()
    const cloneJson = this.getCloneJson()
    if (!cloneJson) {
      Toast.show({ message: 'Clone json is invalid', timeout: 2000 })
      return
    }

    const value = _.cloneDeep(_.get(sourceJson, path))
    _.set(cloneJson, path, value)

    this.setState({
      cloneText: JSON.stringify(cloneJson, null, 2),
    })
  }

  private handleAppendArrayItem = (hoverInfo: HoverInfo) => {
    const { path } = hoverInfo
    const sourceJson = this.getSourceJson()
    const cloneJson = this.getCloneJson()
    if (!cloneJson) {
      Toast.show({ message: 'Clone json is invalid', timeout: 2000 })
      return
    }

    const value = _.cloneDeep(_.get(sourceJson, path))
    const parentPath = _.initial(path)
    if (!_.has(cloneJson, parentPath)) {
      _.set(cloneJson, parentPath, [])
    }
    const parent = _.get(cloneJson, parentPath)
    parent.push(value)

    this.setState({
      cloneText: JSON.stringify(cloneJson, null, 2),
    })
  }

  private handleCloneSubarray = (hoverInfo: HoverInfo) => {
    const { path } = hoverInfo
    const sourceJson = this.getSourceJson()
    const cloneJson = this.getCloneJson()
    if (!cloneJson) {
      Toast.show({ message: 'Clone json is invalid', timeout: 2000 })
      return
    }

    const parentArrayPath = _.initial(path)
    const itemIndex = parseInt(_.last(path))
    if (isNaN(itemIndex)) {
      return
    }
    const parentArray = _.get(sourceJson, parentArrayPath)
    const subArray = _.cloneDeep(_.slice(parentArray, 0, itemIndex + 1))

    _.set(cloneJson, parentArrayPath, subArray)

    this.setState({
      cloneText: JSON.stringify(cloneJson, null, 2),
    })
  }

  private handleRemovePath = (hoverInfo: HoverInfo) => {
    const { path } = hoverInfo
    const cloneJson = this.getCloneJson()
    if (!cloneJson) {
      Toast.show({ message: 'Clone json is invalid', timeout: 2000 })
      return
    }

    const parent = _.size(path) === 1 ? cloneJson : _.get(cloneJson, _.initial(path))
    if (_.isArray(parent)) {
      // remove array item entirely, instead of setting to null
      const index = parseInt(_.last(path))
      parent.splice(index, 1)
    } else if (_.isObject(parent)) {
      _.unset(cloneJson, path)
    }

    this.setState({
      cloneText: JSON.stringify(cloneJson, null, 2),
    })
  }

  private handleSave = () => {
    const cloneJson = this.getCloneJson()

    if (_.isEmpty(cloneJson)) {
      Toast.show({ message: 'Clone json is empty', timeout: 2000 })
      return
    }

    if (!cloneJson) {
      Toast.show({ message: 'Clone json is invalid', timeout: 2000 })
      return
    }

    const entity = new Entity(cloneJson, apis)
    // cause a diff so the save can proceed
    entity.setPrevContent({})

    entity
      .save()
      .then(() => {
        Toast.show({ message: 'Clone saved!' })
        this.setState({ errors: undefined })
      })
      .catch(({ errors }) => {
        this.setState({ errors })
      })
  }

  private handleNavigateToCloneByUUID = () => {
    const cloneJson = this.getCloneJson()
    if (!cloneJson) {
      Toast.show({ message: 'Clone json is invalid', timeout: 2000 })
      return
    }

    const uniqueId = cloneJson.uniqueId
    if (!uniqueId) {
      Toast.show({ message: 'Clone does not contain a uniqueId' })
      return
    }

    window.open(`/entity/${uniqueId}`, '_blank')
  }

  private resolveSyntaxNode(state: EditorState, pos: number): SyntaxNode {
    const resolvedNode = syntaxTree(state).resolve(pos)
    if (resolvedNode.name === 'PropertyName') {
      // advance to the property's value, so we don't resolve the PropertyName
      const cursor = resolvedNode.cursor()
      cursor.next()
      return cursor.node
    }
    return resolvedNode
  }

  private getOppositeView(view: EditorView): EditorView {
    const isSourceEditor = view === this.sourceEditor.current.getEditor().view
    return isSourceEditor
      ? this.destinationEditor.current.getEditor().view
      : this.sourceEditor.current.getEditor().view
  }

  private scrollToSyntaxNode(view: EditorView, node: SyntaxNode | undefined) {
    if (!node) {
      return
    }
    const effect = EditorView.scrollIntoView(node.from, {
      y: 'center',
    })
    view.dispatch({ effects: [effect] })
  }

  private selectSyntaxNode(view: EditorView, node: SyntaxNode) {
    if (!node) {
      return
    }
    const selection = EditorSelection.create([EditorSelection.range(node.from, node.to)])
    view.dispatch({ selection: selection })
  }

  private getSourceJson() {
    const { sourceEntity } = this.state
    return sourceEntity.content
  }

  private getCloneJson() {
    const { cloneText } = this.state
    if (_.isEmpty(cloneText)) {
      return {}
    }
    return Try(() => JSON.parse(cloneText))
  }
}

const JSON_VALUE_SYNTAX_NODE_NAME = /^(?:Null|True|False|Object|Array|String|Number)$/

/* build json path by walking backwards toward the root of the syntax tree */
function jsonPathAtSyntaxNode(state: EditorState, node: SyntaxNode): string[] {
  const parts = []

  const extractName = (parent: SyntaxNode, child?: SyntaxNode) => {
    if (!parent) {
      return
    }
    switch (parent.name) {
      case 'Property': {
        const nameNode = parent.getChild('PropertyName')
        if (nameNode) {
          const name = JSON.parse(state.sliceDoc(nameNode.from, nameNode.to))
          parts.unshift(name)
        }
        break
      }
      case 'Array': {
        if (child && JSON_VALUE_SYNTAX_NODE_NAME.test(child.name)) {
          // unfortunately need to iterate siblings to find index
          let index = 0
          for (let prev = child.prevSibling; prev; prev = prev.prevSibling) {
            if (JSON_VALUE_SYNTAX_NODE_NAME.test(prev.name)) {
              index++
            }
          }
          parts.unshift(index.toString())
        }
        break
      }
    }
  }

  extractName(node)

  while (node && node.parent) {
    extractName(node.parent, node)
    node = node.parent
  }

  return parts
}

/* find a syntax node at the given json path, using TreeCursor's traversal functions as a BFS */
function syntaxNodeAtJsonPath(view: EditorView, path: string[]): SyntaxNode | undefined {
  const tree = syntaxTree(view.state)
  const cursor = tree.cursor()

  const parts = _.clone(path)

  for (;;) {
    if (!cursor.firstChild()) {
      // dequeue the next path part that must be found
      const part = parts.shift()

      let found = false

      if (cursor.node.parent.name === 'Object') {
        // interpret part as a string property name; iterate all sibling
        // properties to find a match.

        // first node will be the opening brace, subsequent nodes will be
        // Property nodes (ending with a closing brace node).
        while (cursor.nextSibling()) {
          const nameNode = cursor.node.getChild('PropertyName')
          if (nameNode) {
            // get name, stripping quotes included with from/to offsets
            const name = view.state.sliceDoc(nameNode.from + 1, nameNode.to - 1)
            if (name === part) {
              cursor.next() // move into Property node
              cursor.next() // move into Property node's value
              found = true

              if (_.isEmpty(parts)) {
                // no more path parts to find
                return cursor.node
              }
              break
            }
          }
        }
      } else if (cursor.node.parent.name === 'Array') {
        // interpret part as a number
        const parsed = Try(() => parseInt(part))
        if (parsed == null) {
          return undefined
        }
        const index = _.clamp(parsed, 0, parsed)

        // advance to that index, moving past the opening bracket node @ 0
        for (let i = 0; i <= index; i++) {
          cursor.nextSibling()
        }

        // if we didn't hit a closing node, we must have found a match
        if (cursor.name !== ']') {
          found = true

          if (_.isEmpty(parts)) {
            // no more path parts to find
            return cursor.node
          }
        }
      }

      if (!found) {
        // could not find required path part
        return undefined
      }
    }
  }
}
