import _ from 'lodash'
import React, { Fragment } from 'react'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import styled from 'styled-components'
import classNames from 'classnames'
import { components } from 'react-select'

import 'browser/app/pages/app/tools/_tools.scss'

import { Query } from 'shared-libs/models/query'
import { Entity } from 'shared-libs/models/entity'
import {
  filterMappings,
  createEntityMappingFormulaContext,
  executeMapping,
  DEFAULT_MAPPINGS,
  IMapping,
  CONDITIONAL_FAILED,
} from 'shared-libs/models/entity-mapping'
import apis from 'browser/app/models/apis'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { SortableListDragHandle } from 'browser/components/atomic-elements/atoms/list/sortable-list'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { EntitySelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/entity-select-field'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { LabelFormGroup } from 'browser/components/atomic-elements/molecules/label-form-group/label-form-group'
import { Textarea } from 'browser/components/atomic-elements/atoms/textarea/textarea'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { TextareaField } from 'browser/components/atomic-elements/molecules/fields/textarea-field'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { objectDiff } from 'shared-libs/helpers/utils'
import { CustomFormulas } from 'shared-libs/helpers/formulas'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import { serializeAsString } from '../utils'
import { Filter } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { Edge } from 'shared-libs/generated/server-types/entity'
import { json } from '@codemirror/lang-json'

type ScratchMapping = IMapping & {
  _result?: any
  _error?: any
  _enabled?: boolean
}

interface IEntityMappingsToolProps extends IBaseProps {
  frames?: any
}

interface IEntityMappingsToolState {
  // edges saved from the EntitySelects
  selectedFirmEdge?: any
  selectedSourceSchemaEdge?: any
  checkedSourceSchemaByFirm?: boolean

  selectedSourceEdge?: any
  checkedSourceByFirm?: boolean

  selectedDestinationSchemaEdge?: any
  checkedDestinationSchemaByFirm?: boolean

  // resolved entities
  selectedFirm?: Entity
  selectedSourceSchema?: Entity
  selectedSource?: Entity
  selectedDestinationSchema?: Entity
  destination?: Entity

  // json
  sourceJson?: string
  destinationJson?: string
  destinationDiff?: string
  showDiff?: boolean

  // original mappings
  originalMappings: any[]
  // editable mappings w/ result metadata
  mappings: ScratchMapping[]

  errors?: any[]
  isLoading?: boolean
}

export class EntityMappingsTool extends React.Component<
  IEntityMappingsToolProps,
  IEntityMappingsToolState
> {
  private DEFAULT_CONTEXT

  constructor(props) {
    super(props)

    this.state = {
      originalMappings: [],
      mappings: [],

      showDiff: true,
    }

    this.DEFAULT_CONTEXT = {
      ...CustomFormulas,
      ...createEntityMappingFormulaContext(apis.getStore()),
      _,
    }
  }

  render() {
    return (
      <div className="grid-block vertical">
        <CardHeader className="c-cardHeader--nonSeparating">
          <CardHeaderItem
            className="c-cardHeader-item--grow c-cardHeader-item--center"
            title="Entity Mappings Sandbox"
          />
        </CardHeader>
        <div className="c-tools grid-block">
          <div className="grid-content">
            {this.renderChoices()}
            {this.renderJson()}
            {this.renderMappings()}
            {this.renderErrors()}
          </div>
        </div>
      </div>
    )
  }

  private renderChoices() {
    return (
      <Section title="Search Mappings:">
        {this.renderFirmSelect()}
        {this.renderSourceSchemaSelect()}
        {this.renderSourceEntitySelect()}
        {this.renderDestinationSchemaSelect()}
      </Section>
    )
  }

  private renderFirmSelect() {
    const { selectedFirmEdge } = this.state
    const orders = [this.getOrderDescending()]
    return (
      <FormTable className="u-bumperTop">
        <EntitySelectField
          label="Firm"
          entityType="/1.0/entities/metadata/firm.json"
          orders={orders}
          isRequired={true}
          showLinkIcon={true}
          onChange={this.handleSelectedFirm}
          value={selectedFirmEdge}
          optionRenderer={this.renderOptionWithId}
        />
      </FormTable>
    )
  }

  private renderSourceSchemaSelect() {
    const {
      selectedSourceSchemaEdge,
      checkedSourceSchemaByFirm,
      selectedFirmEdge,
    } = this.state
    const filters = _.compact([
      checkedSourceSchemaByFirm && this.getOwnerFirmFilter(selectedFirmEdge),
    ])
    const orders = [this.getOrderDescending()]
    return (
      <FormTable className="u-bumperTop">
        <EntitySelectField
          label="Source Schema"
          entityType="/1.0/entities/metadata/entitySchema.json"
          filters={filters}
          orders={orders}
          showLinkIcon={true}
          optionRenderer={this.renderOptionWithId}
          onChange={this.handleSelectedSourceSchema}
          value={selectedSourceSchemaEdge}
        />
        <CheckboxField
          label="Filter by Firm"
          isHorizontalLayout
          onChange={(checkedSourceSchemaByFirm) => this.handleChecked({checkedSourceSchemaByFirm})}
          value={checkedSourceSchemaByFirm}
        />
      </FormTable>
    )
  }

  private renderSourceEntitySelect() {
    const {
      selectedSourceSchemaEdge,
      selectedSourceSchema,
      selectedSourceEdge,
      checkedSourceByFirm,
      selectedFirmEdge,
    } = this.state
    const sourceEntityType = _.get(selectedSourceSchema, 'id', '/1.0/entities/metadata/entity.json')
    const filters = _.compact([
      checkedSourceByFirm && this.getOwnerFirmFilter(selectedFirmEdge),
      this.getActiveMixinFilter(selectedSourceSchemaEdge),
    ])
    const orders = [this.getOrderDescending()]
    return (
      <FormTable className="u-bumperTop">
        <EntitySelectField
          label="Source Entity"
          entityType={sourceEntityType}
          filters={filters}
          orders={orders}
          isRequired={true}
          isCreatable={false}
          showLinkIcon={true}
          optionRenderer={this.renderOptionWithIdAndDate}
          onChange={this.handleSelectedSource}
          value={selectedSourceEdge}
        />
        <CheckboxField
          label="Filter by Firm"
          isHorizontalLayout
          onChange={(checkedSourceByFirm) => this.handleChecked({checkedSourceByFirm})}
          value={checkedSourceByFirm}
        />
      </FormTable>
    )
  }

  private renderDestinationSchemaSelect() {
    const {
      selectedDestinationSchemaEdge,
      checkedDestinationSchemaByFirm,
      selectedFirmEdge,
    } = this.state
    const filters = _.compact([
      checkedDestinationSchemaByFirm && this.getOwnerFirmFilter(selectedFirmEdge),
    ])
    const orders = [this.getOrderDescending()]
    return (
      <FormTable className="u-bumperTop">
        <EntitySelectField
          label="Destination Schema"
          entityType="/1.0/entities/metadata/entitySchema.json"
          filters={filters}
          orders={orders}
          isRequired={true}
          showLinkIcon={true}
          optionRenderer={this.renderOptionWithId}
          onChange={this.handleSelectedDestinationSchema}
          value={selectedDestinationSchemaEdge}
        />
        <CheckboxField
          label="Filter by Firm"
          isHorizontalLayout
          onChange={(checkedDestinationSchemaByFirm) => this.handleChecked({checkedDestinationSchemaByFirm})}
          value={checkedDestinationSchemaByFirm}
        />
      </FormTable>
    )
  }

  private renderOptionWithId = (props: any): JSX.Element => this.renderOption(props, ['uniqueId'])

  private renderOptionWithIdAndDate = (props: any): JSX.Element =>
    this.renderOption(props, ['uniqueId', 'modifiedDate'])

  private renderOption(props: any, subheadingPaths?: string[]): JSX.Element {
    const entity: Entity = props?.data
    if (!entity || !(entity instanceof Entity)) {
      return null
    }
    const title = entity.get('precomputation.displayName') || entity.displayName
    if (!title) {
      return null
    }
    return (
      <components.Option {...props}>
        <div className="f5">{title}</div>
        {subheadingPaths?.map((path, idx) => (
          <div key={idx} className="f6">{entity.get(path)}</div>
        ))}
      </components.Option>
    )
  }

  private renderJson() {
    const { showDiff } = this.state
    return (
      <div className="row">
        <div className="col-xs-6">
          <Section title="Source Entity">{this.renderSourceJson()}</Section>
        </div>
        <div className="col-xs-6">
          <Section
            title={showDiff ? 'Destination Diff' : 'Destination Entity'}
            headerControls={this.renderDestinationControls()}
          >
            {this.renderDestinationJson()}
          </Section>
        </div>
      </div>
    )
  }

  private renderSourceJson() {
    const { sourceJson } = this.state
    if (!sourceJson) {
      return 'Select a source Entity'
    }
    return (
      <CodeMirrorInput
        maxHeight='600px' // TODO: remove, and add resize capability
        value={sourceJson}
        readOnly={true}
        extensions={[json()]}
        onChange={(sourceJson) => {
          this.setState({ sourceJson })
        }}
      />
    )
  }

  private renderDestinationControls() {
    const { showDiff, destinationJson, destinationDiff } = this.state
    if (!destinationJson && !destinationDiff) {
      return null
    }
    return (
      <div className="row">
        <Button size="small" className="mr2" onClick={() => this.setState({ showDiff: !showDiff })}>
          {showDiff ? 'Show Entity' : 'Show Diff'}
        </Button>
      </div>
    )
  }

  private renderDestinationJson() {
    const { showDiff, destinationJson, destinationDiff } = this.state
    if (!destinationJson && !destinationDiff) {
      return 'Select a destination schema'
    }
    const value = showDiff ? destinationDiff : destinationJson
    return (
      <CodeMirrorInput
        maxHeight='600px' // TODO: remove, and add resize capability
        value={value}
        readOnly={true}
        extensions={[json()]}
        onChange={() => {}}
      />
    )
  }

  private renderMappings() {
    const { mappings, selectedFirm, selectedSource, selectedDestinationSchema } = this.state
    if (_.isEmpty(mappings)) {
      const emptyText =
        !selectedFirm || !selectedSource || !selectedDestinationSchema
          ? 'Use the filters above'
          : 'None found'
      return <div className="tc">{emptyText}</div>
    }
    return (
      <Section title="Relevant Mappings" headerControls={this.renderMappingsControls()}>
        <List
          renderListItem={this.renderMapping}
          value={mappings}
          showRemoveButton={false}
          isSortable={true}
          onChange={this.handleSortChange}
        />
      </Section>
    )
  }

  private renderMappingsControls() {
    const { mappings } = this.state
    return (
      <div className="row">
        <Button
          size="small"
          className="mr2"
          onClick={() => this.runAllEntityMappings(mappings).then(() => this.forceUpdate())}
        >
          Run All
        </Button>
        <Button size="small" className="mr2" onClick={() => this.resetAllMappings()}>
          Reset All
        </Button>
      </div>
    )
  }

  private renderMapping = (itemProps: IRenderListItemProps) => {
    const { item, index } = itemProps
    const mapping: ScratchMapping = item
    const checkboxValue = _.defaultTo(mapping._enabled, true)

    return (
      <div className="row bb b--gray pv2" style={{ margin: 0 }}>
        <div style={{ flex: '0 1 0', width: '3.4rem', paddingLeft: '0.2rem', paddingTop: '1rem' }}>
          <Checkbox
            label={`${index}`}
            value={checkboxValue}
            onChange={(checked) => (mapping._enabled = checked)}
          />
        </div>
        <div className="col-xs-8">
          <LabelFormGroup
            className="w-100"
            label={'Source Path'}
            value={mapping.sourcePath}
            onChange={(path) => (mapping.sourcePath = path)}
          >
            <Input />
          </LabelFormGroup>
          <LabelFormGroup
            className="w-100"
            label={'Destination Path'}
            value={mapping.destinationPath}
            onChange={(path) => (mapping.destinationPath = path)}
          >
            <Input />
          </LabelFormGroup>
          <div className={classNames('overflow-auto')} style={{ maxHeight: '10rem' }}>
            <TextareaField
              label={'Formula'}
              value={mapping.expression}
              onChange={(expression) => mapping.expression = expression}
            />
          </div>
        </div>
        <div className="col-xs">
          <Label text="Result:" />
          {this.renderMappingResult(mapping)}
        </div>

        <Fragment>
          <SortableListDragHandle>
            <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} tabIndex={-1} />
          </SortableListDragHandle>
        </Fragment>
      </div>
    )
  }

  private renderMappingResult(mapping: ScratchMapping) {
    if (mapping._error) {
      return (
        <ErrorMessageContainer>
          <ErrorTitle>Formula Error</ErrorTitle>
          <ErrorText>{mapping._error.message}</ErrorText>
        </ErrorMessageContainer>
      )
    }
    return <Textarea isDisabled={true} value={serializeAsString(mapping._result)} />
  }

  private renderErrors() {
    const { errors } = this.state
    if (_.isEmpty(errors)) {
      return null
    }
    const errorItems = _.compact([...(errors || [])])
    return (
      <div className="col-xs-12">
        <Section title="Errors">
          {errorItems.map((error, idx) => this.renderError(error, idx))}
        </Section>
      </div>
    )
  }

  private renderError(error, idx) {
    return (
      <ErrorMessageContainer>
        <ErrorTitle>{`${error.type} error:`}</ErrorTitle>
        <ErrorText>{error.message}</ErrorText>
      </ErrorMessageContainer>
    )
  }

  /* handlers */

  private handleSelectedFirm = (selectedFirmEdge) => {
    if (selectedFirmEdge && selectedFirmEdge === this.state.selectedFirm) {
      return
    }
    this.setState({ selectedFirmEdge })
    this.resolveEdge(selectedFirmEdge).then((selectedFirm) => {
      this.setState({ selectedFirm }, this.handleUpdatedSelections)
    })
  }

  private handleSelectedSourceSchema = (selectedSourceSchemaEdge) => {
    if (selectedSourceSchemaEdge && selectedSourceSchemaEdge === this.state.selectedSourceSchema) {
      return
    }
    this.resolveEdge(selectedSourceSchemaEdge).then((selectedSourceSchema) => {
      this.setState(
        { selectedSourceSchemaEdge, selectedSourceSchema },
        this.handleUpdatedSelections
      )
    })
  }

  private handleSelectedSource = (selectedSourceEdge) => {
    if (selectedSourceEdge && selectedSourceEdge === this.state.selectedSource) {
      return
    }
    this.resolveEdge(selectedSourceEdge).then((selectedSource) => {
      const sourceJson = selectedSourceEdge
        ? JSON.stringify(selectedSource.content, null, 2)
        : undefined
      this.setState(
        { selectedSourceEdge, selectedSource, sourceJson },
        this.handleUpdatedSelections
      )
    })
  }

  private handleChecked = (state: object) => {
    this.setState(state, this.handleUpdatedSelections)
  }

  private handleSelectedDestinationSchema = (selectedDestinationSchemaEdge) => {
    if (
      selectedDestinationSchemaEdge &&
      selectedDestinationSchemaEdge === this.state.selectedDestinationSchema
    ) {
      return
    }
    this.resolveEdge(selectedDestinationSchemaEdge).then((selectedDestinationSchema) => {
      const destination = selectedDestinationSchemaEdge
        ? apis.getStore().createRecord(selectedDestinationSchema)
        : undefined
      const destinationJson = selectedDestinationSchemaEdge
        ? JSON.stringify(destination.content, null, 2)
        : undefined
      this.setState(
        { selectedDestinationSchemaEdge, selectedDestinationSchema, destination, destinationJson },
        this.handleUpdatedSelections
      )
    })
  }

  private handleUpdatedSelections() {
    const { selectedFirm, selectedSource, selectedDestinationSchema, destination } = this.state
    if (!selectedFirm || !selectedSource || !selectedDestinationSchema) {
      this.setState({ mappings: [] })
      return
    }

    console.log('have enough filter criteria to request mappings')

    this.setState({ isLoading: true })

    const collection = new Query(apis)
      .setEntityType('/1.0/entities/metadata/core_entity_mapping.json')
      .setFilters([
        {
          type: 'matchEdge',
          path: 'owner.firm',
          value: {
            entityId: selectedFirm.get('uniqueId'),
          },
        },
      ])
      .getCollection()

    collection
      .find()
      .then((result) => {
        const defaultMappings = _.map(DEFAULT_MAPPINGS, (json) => new Entity(json, apis))
        const allMappings = _.concat(defaultMappings, result)
        const sourceTypeIds = _.map(selectedSource.activeMixins, 'entityId')
        const destinationTypeIds = _.map(destination.activeMixins, 'entityId')
        return filterMappings(
          allMappings,
          selectedSource,
          sourceTypeIds,
          destination,
          destinationTypeIds
        )
      })
      .then((originalMappings) => {
        return this.runAllEntityMappings(originalMappings).then(() => {
          this.setState({ originalMappings })
        })
      })
      .catch((error) => {
        this.setState({ errors: [error] })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  private handleSortChange = (mappings) => {
    this.setState({ mappings })
  }

  /* helper methods */

  private getOwnerFirmFilter(edge: Edge | undefined): Filter | undefined {
    return edge
      ? {
          path: 'owner.firm',
          type: 'matchEdge',
          value: { entityId: edge.entityId },
        }
      : undefined
  }

  private getActiveMixinFilter(edge: Edge | undefined): Filter | undefined {
    return edge
      ? {
          path: 'mixins.active',
          type: 'containsEdge',
          value: { entityId: edge.entityId },
        }
      : undefined
  }

  private getOrderDescending(): any {
    return {
      path: 'modifiedDate',
      type: 'descending',
      valueType: 'date',
    }
  }

  /*
   * Resolve this entity completely so the store is fully populated with its schemas,
   * which enables the mappings lookup to work as intended.
   */
  private resolveEdge(edge): Promise<Entity> {
    if (!edge) {
      return Promise.resolve(undefined)
    }
    return apis
      .getStore()
      .resolveEntitiesById([edge.entityId])
      .then(() => apis.getStore().getRecord(edge.entityId))
  }

  private runAllEntityMappings = async (originalMappings): Promise<ScratchMapping[]> => {
    const { destination } = this.state
    destination.rollback()

    // add the results to the mappings for display
    const mappings = []
    for (const mapping of originalMappings) {
      mapping._error = undefined
      if (_.isNil(mapping._enabled) || mapping._enabled) {
        const result = await executeMapping(mapping, this.DEFAULT_CONTEXT, {
          throwError: true,
        }).catch((err) => {
          console.warn(err)
          mapping._error = err
        })

        if (result !== CONDITIONAL_FAILED) {
          mapping._result = result
          // todo - would we rather add new UI for this situaton?
        }
      }
      mappings.push(mapping)
    }
    const destinationJson = JSON.stringify(destination.content, null, 2)
    const diff = objectDiff(destination.prevContent, destination.content)
    const destinationDiff = JSON.stringify(diff, null, 2)
    this.setState({ mappings, destinationJson, destinationDiff })
    return mappings
  }

  private resetAllMappings = () => {
    const { originalMappings } = this.state
    const mappings = _.map(originalMappings, (mapping) => {
      delete mapping._result
      delete mapping._error
      return mapping
    })
    this.setState({ mappings })
  }
}

const ErrorMessageContainer = styled.div`
  background-color: #ffebee;
  border-radius: 5px;
  justify-content: center;
  margin-top: 5px;
`

const ErrorTitle = styled.div`
  font-weight: bold;
  color: #D45C43;
  padding-right: 5px;
  padding-left: 5px;
`

const ErrorText = styled.div`
  color: #D45C43;
  padding-right: 5px;
  padding-left: 10px;
`
