import React from 'react'
import _ from 'lodash'

import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { QRCodeField } from 'browser/components/atomic-elements/molecules/fields/qrcode-field'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { linter } from '@codemirror/lint'

interface QrBuilderDocumentProps extends IBaseProps {
}

interface QrBuilderDocumentState {
  targetAPI: string
  docJson?: string
}

enum PropertyType {
  BOOL = 'Boolean',
  STR = 'String',
  NUM = 'Number',
}

const ENTITY_SCHEMA_URI = '/1.0/entities/metadata/entity.json'

export class QrBuilderJson extends React.Component<QrBuilderDocumentProps, QrBuilderDocumentState> {
  constructor(props) {
    super(props)

    this.state = {
      targetAPI: '/actions/entity/create?jsonProps=',
    }
  }

  public getDocLinkFromJson(docJson) {
    const { targetAPI } = this.state
    return `${window.location.origin}${targetAPI}${encodeURIComponent(docJson)}`
  }

  public copyToClipboard(link) {
    navigator.clipboard.writeText(link)
    Toast.show({
      message: 'Copied deeplink to clipboard',
      position: Position.BOTTOM_RIGHT,
    })
  }

  public getFlatDocJson(): string {
    try {
      const { docJson } = this.state

      const docObj = JSON.parse(docJson)
      return JSON.stringify(docObj)
    } catch(e) { /* ignore errors. json lint requires a comment here. */ }

    return ''
  }

  public renderQrCodeSection() {
    const flatDocJson = this.getFlatDocJson()
    const creationLink = this.getDocLinkFromJson(flatDocJson)

    if (_.isEmpty(flatDocJson)) {
      return (
        <ErrorBlock
          errorText="JSON object is missing or invalid syntax"
        />
      )
    }

    return <>
      <QRCodeField qrValue={creationLink} qrSize={256} includeMargin={true} />
      {creationLink}
      <br/>
      <Button
        buttonText='Copy to clipboard'
        onClick={() => { this.copyToClipboard(creationLink) }}
        className='u-bumperTop--lg'
      />
    </>
  }

  render() {
    const { targetAPI, docJson } = this.state

    return (
      <>
        <div className='u-bumperTop--lg u-bumperLeft--lg col-xs-4'>
          <InputField
            isHorizontalLayout={true}
            label='Target API'
            onChange={(targetAPI) => { this.setState({ targetAPI }) } }
            value={targetAPI}
          />
          <CodeMirrorInput
            value={docJson}
            extensions={[json(), linter(jsonParseLinter())]}
            onChange={(docJson) => { this.setState({ docJson }) }}
          />
        </div>
        <div className='u-bumperTop--lg u-bumperLeft--lg col-xs-4'>
          {this.renderQrCodeSection()}
        </div>
      </>
    )
  }
}
