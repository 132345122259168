import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import 'browser/app/pages/app/onboarding/_onboarding.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
// tslint:disable-next-line:max-line-length
import { AuthenticationPage } from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { OnboardingFlow } from 'browser/components/atomic-elements/organisms/onboarding-flow/onboarding-flow'
import { browserHistory } from 'browser/history'

// tslint:disable-next-line:no-empty-interface
type IOnboardingProps = IBaseProps

// tslint:disable-next-line:no-empty-interface
interface IOnboardingStates {

}

export class Onboarding extends React.Component<IOnboardingProps, IOnboardingStates> {

  public render() {
    const productName = apis.getProductName()
    const title = `Welcome to ${productName}`
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <AuthenticationPage
            className='mw7-m mw7-l'
            bodyClassName='c-onboarding-body'
          >
            <Head
              title={title}
            />
            <OnboardingFlow
              onClose={this.handleClose}
              settings={settings}
              size='lg'
            />
          </AuthenticationPage>
        )}
      </AppNavigatorContext.Consumer>

    )
  }

  private handleClose = () => {
    browserHistory.push({ pathname: '/redirect' })
  }
}
