import _ from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import 'browser/app/pages/app/settings/_settings.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { CarrierQBOMapping as CarrierSettingsPage } from 'browser/app/pages/app/settings/accounting/carriers'
import { ChargeQBOMapping as ChargeSettingsPage } from 'browser/app/pages/app/settings/accounting/charges'
import { CustomerQBOMapping as CustomerSettingsPage } from 'browser/app/pages/app/settings/accounting/customers'
import { BillingSettings as BillingSettingsPage } from 'browser/app/pages/app/settings/billing'

import { CustomDocumentTypesSetting } from 'browser/app/pages/app/settings/document-types'
import { QuickBooksSettings as QuickBooksSettingsPage } from 'browser/app/pages/app/settings/integrations/quickbooks'
import { EmailTriggerSettings as EmailTriggerSettingsPage } from 'browser/app/pages/app/settings/triggers/email'
import { FtpSettings as FtpSettingsPage } from 'browser/app/pages/app/settings/ftp'
import { ViewsIndex as ViewPage } from 'browser/app/pages/app/views'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { ViewContext } from 'browser/contexts/view-context'

const SentryRoute = Sentry.withSentryRouting(Route);

interface ISettingsProps extends IBaseProps {
  match: any
}

const USER_SETTINGS_VIEW = '8a42aca5-854e-4eca-aa7c-6a2e6bcfd0e7'

export class Settings extends React.Component<ISettingsProps, any> {
  public render() {
    const { match } = this.props
    return (
      <AppNavigatorContext.Consumer>
        {({ settings }) => (
          <div className="grid-block vertical c-appBody">
            <Head title="Settings" />
            <CardHeader className="u-borderBottom">
              <CardHeaderItem className="u-bumperTop u-bumperBottom" superTitle="Account & Settings" />
            </CardHeader>
            <div className="grid-block">
              <div className="grid-block shrink c-settingsSideNavigation">
                <div className="grid-content collapse">
                  <Label isSecondary={true} className="u-bumperTop--lg u-bumperLeft--lg">
                    Account
                  </Label>
                  {this.renderUserSettings(settings)}
                  {(settings.isAdmin || settings.isFirmAdmin) &&
                    (settings.isBrokerTMSApplication() || settings.isCarrierTMSAppliction()
                      ? this.renderTMSFirmAdminSettingsLinks(settings)
                      : this.renderDMSFirmAdminSettingsLinks(settings))}
                </div>
              </div>
              <div className="grid-block">
                <Switch>
                  <SentryRoute exact={true} path={match.url} render={() => this.handleRenderIndexRoute(settings)} />
                  <SentryRoute path={`${match.url}/view/:viewId/custom/:customViewId`} render={this.handleRenderViewPage} />
                  <SentryRoute path={`${match.url}/view/:viewId`} render={this.handleRenderViewPage} />
                  <SentryRoute path={`${match.url}/billing`} component={BillingSettingsPage} />
                  <SentryRoute
                    path={`${match.url}/accounting/charges`}
                    render={() => <ChargeSettingsPage settings={settings} />}
                  />
                  <SentryRoute
                    path={`${match.url}/accounting/carriers`}
                    render={() => <CarrierSettingsPage settings={settings} />}
                  />
                  <SentryRoute
                    path={`${match.url}/accounting/customers`}
                    render={() => <CustomerSettingsPage settings={settings} />}
                  />
                  <SentryRoute
                    path={`${match.url}/integrations/quickbooks`}
                    render={() => <QuickBooksSettingsPage settings={settings} />}
                  />
                  <SentryRoute path={`${match.url}/triggers/email`} component={EmailTriggerSettingsPage} />
                  <SentryRoute
                    path={`${match.url}/document-types`}
                    render={() => <CustomDocumentTypesSetting settings={settings} />}
                  />
                  <SentryRoute path={`${match.url}/ftp`} render={() => <FtpSettingsPage />} />
                </Switch>
              </div>
            </div>
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private handleRenderViewPage = (props) => {
    return (
      <ViewContext.Provider value={{basePath: this.props.match.url}}>
        <ViewPage {...props}>
        </ViewPage>
      </ViewContext.Provider>
    )
  }

  private handleRenderIndexRoute = (settings) => {
    const { match } = this.props
    const user = settings.getUser()
    return (
      <Redirect
        to={{
          pathname: `${match.url}/view/${USER_SETTINGS_VIEW}`,
          search: `?entityId=${user.uniqueId}`
        }}
      />
    )
  }

  private renderEntityViewNavLink(viewId, entityId, label) {
    const { match } = this.props
    return (
      <NavLink
        activeClassName="is-active"
        className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
        to={{
          pathname: `${match.url}/view/${viewId}`,
          search: `?entityId=${entityId}`
        }}
      >
        <div className="c-sideNavigationBarItem-label">{label}</div>
      </NavLink>
    )
  }

  private renderDMSFirmAdminSettingsLinks(settings) {
    return (
      <div>
        <Label isSecondary={true} className="u-bumperTop u-innerBumperTop u-bumperLeft--lg">
          Company
        </Label>
        {this.renderFirmSettings(settings)}
        {this.renderPreferences(settings)}
        {this.renderDepartmentSettings(settings)}
        {this.renderPositionSettings(settings)}
        {this.renderFtpSettings()}
        {this.renderImports(settings)}
        {this.renderDocumentTypes(settings)}
        {this.renderEmailTriggerSettings()}
        {this.renderDocumentImagingSettings(settings)}
      </div>
    )
  }

  private renderTMSFirmAdminSettingsLinks(settings) {
    return (
      <div>
        <Label isSecondary={true} className="u-bumperTop u-innerBumperTop u-bumperLeft--lg u-borderTop">
          Company
        </Label>
        {this.renderFirmSettings(settings)}
        {this.renderPreferences(settings)}
        {this.renderDepartmentSettings(settings)}
        {this.renderPositionSettings(settings)}
        {this.renderInvoicing(settings)}
        {this.renderTMSSettings(settings)}
        {this.renderFtpSettings()}
        {this.renderImports(settings)}
        {this.renderDocumentImagingSettings(settings)}
        {this.renderQuickbooksAccountingSettings(settings)}
        {this.renderDocumentTypes(settings)}
        {this.renderEmailTriggerSettings()}
        {this.renderDATSettings(settings)}
        {this.renderLegal()}
      </div>
    )
  }

  private renderFirmSettings(settings) {
    const firm = settings.getFirm()
    const viewId = '59722964-a05b-403e-a268-fed26595a788'
    return this.renderEntityViewNavLink(viewId, firm.uniqueId, 'General')
  }

  private renderPreferences(settings) {
    const generalSettings = settings.getSettingsByNamespace('generalSettings')
    const viewId = '53f7d39a-a351-4eff-a92a-c4ebc52bdeb0'
    if (!_.isNil(generalSettings)) {
      return this.renderEntityViewNavLink(viewId, generalSettings.uniqueId, 'Preferences')
    }
  }

  private renderInvoicing(settings) {
    const invoiceSettings = settings.getSettingsByNamespace('core_accounting_accountsReceivable_invoiceSettings')
    const viewId = 'bc4bf26d-d49c-4d1d-83ee-2f96aa9fd321'
    if (!_.isNil(invoiceSettings)) {
      return <div>{this.renderEntityViewNavLink(viewId, invoiceSettings.uniqueId, 'Invoicing')}</div>
    }
  }

  private renderTMSSettings(settings) {
    const tmsSettings = settings.getSettingsByNamespace('tmsSettings')
    const viewId = '4525d366-f407-41b9-a0ef-6232db2c8773'
    if (!_.isNil(tmsSettings)) {
      return <div>{this.renderEntityViewNavLink(viewId, tmsSettings.uniqueId, 'TMS')}</div>
    }
  }

  private renderUserSettings(settings) {
    const user = settings.getUser()
    return this.renderEntityViewNavLink(USER_SETTINGS_VIEW, user.uniqueId, 'Personal Settings')
  }

  private renderEmailTriggerSettings() {
    return (
      <div>
        <Label isSecondary={true} className="u-bumperTop--lg u-bumperLeft--lg">
          Triggers
        </Label>
        <NavLink
          className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
          activeClassName="is-active"
          to="/settings/triggers/email"
        >
          <div className="c-sideNavigationBarItem-label">Document Triggers</div>
        </NavLink>
      </div>
    )
  }

  private renderFtpSettings() {
    return (
      <NavLink
        className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
        activeClassName="is-active"
        to="/settings/ftp"
      >
        <div className="c-sideNavigationBarItem-label">FTP</div>
      </NavLink>
    )
  }

  private renderDocumentTypes(settings) {
    const isEnabled = _.get(settings.getGeneralSettings(), 'generalSettings.allowDoctypeCustomization')

    if (!isEnabled) {
      return
    }

    return (
      <div>
        <NavLink
          className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
          activeClassName="is-active"
          to="/settings/document-types/index.tsx"
        >
          <div className="c-sideNavigationBarItem-label">Document Types</div>
        </NavLink>
      </div>
    )
  }

  private renderDepartmentSettings(settings) {
    const firm = settings.getFirm()
    const viewId = '35af4fda-abdc-423d-a973-947659b8a5b0'
    return this.renderEntityViewNavLink(viewId, firm.uniqueId, 'Departments')
  }

  private renderPositionSettings(settings) {
    const firm = settings.getFirm()
    const viewId = 'c8a4b7d2-320a-40e7-ad40-5293a06faba0'
    return this.renderEntityViewNavLink(viewId, firm.uniqueId, 'Positions')
  }

  private renderImports(settings) {
    const viewId = '81e06f29-9bc6-4743-bbd5-b09465f6c298'
    const { match } = this.props

    return (
      <NavLink
        activeClassName="is-active"
        className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
        to={{
          pathname: `${match.url}/view/${viewId}`,
        }}
      >
        <div className="c-sideNavigationBarItem-label">Imports</div>
      </NavLink>
    )
  }

  private renderDATSettings(settings) {
    const userDat = settings.getUser()
    const firmDat = settings.getDATSettings()

    if (_.get(userDat, 'dat.settings') == null) {
      return
    }
    const userDatViewId = '99d6a64b-b208-42b0-a7e1-4849f4e42d99'
    const firmDatViewId = '0306cc6b-ea97-47b2-89f1-0e1230c34baf'
    return (
      <div>
        {this.renderEntityViewNavLink(userDatViewId, userDat.uniqueId, 'DAT Connexion')}
        {firmDat != null ? this.renderEntityViewNavLink(firmDatViewId, firmDat.uniqueId, 'DAT FTP') : ''}
      </div>
    )
  }

  private renderDocumentImagingSettings(settings) {
    const imagingSettings = settings.getSettingsByNamespace('documentImagingSettings')
    const viewId = '35177b0d-e48d-4e15-b8fa-6b9debc2c173'
    if (settings.isDocumentImagingEnabled() === true && !_.isNil(imagingSettings)) {
      return (
        <div>
          <Label isSecondary={true} className="u-bumperTop u-innerBumperTop u-bumperLeft--lg">
            Imaging
          </Label>
          {this.renderEntityViewNavLink(viewId, imagingSettings.uniqueId, 'Imaging Settings')}
        </div>
      )
    }
  }

  private renderQuickbooksAccountingSettings(settings) {
    const qboSettings = settings.getSettingsByNamespace('quickBooksOnlineSettings')
    if (qboSettings) {
      return (
        <React.Fragment>
          <Label isSecondary={true} className="u-bumperTop--lg u-bumperLeft--lg">
            Accounting
          </Label>
          <NavLink
            className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
            activeClassName="is-active"
            to="/settings/accounting/charges"
          >
            <div className="c-sideNavigationBarItem-label">Accounting Charges</div>
          </NavLink>
          <NavLink
            className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
            activeClassName="is-active"
            to="/settings/accounting/carriers"
          >
            <div className="c-sideNavigationBarItem-label">Accounting Carriers</div>
          </NavLink>
          <NavLink
            className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
            activeClassName="is-active"
            to="/settings/accounting/customers"
          >
            <div className="c-sideNavigationBarItem-label">Accounting Customers</div>
          </NavLink>

          <Label isSecondary={true} className="u-bumperTop--lg u-bumperLeft--lg">
            Integrations
          </Label>
          <NavLink
            className="c-sideNavigationBarItem c-sideNavigationBarItem--white"
            activeClassName="is-active"
            to="/settings/integrations/quickbooks"
          >
            <div className="c-sideNavigationBarItem-label">Intuit QuickBooks</div>
          </NavLink>
        </React.Fragment>
      )
    }
  }

  private renderLegal() {
    return (
      <div>
        <Label isSecondary={true} className="u-bumperTop--lg u-bumperLeft--lg">
          Legal
        </Label>
        <div className="c-sideNavigationBarItem-labelu-bumperTop u-innerBumperTop u-bumperLeft--lg">
          <a href="https://www.withvector.com/privacy">Privacy Policy </a>
        </div>
        <div className="c-sideNavigationBarItem-labelu-bumperTop u-innerBumperTop u-bumperLeft--lg">
          <a href="https://www.withvector.com/terms-of-service">Terms of Service </a>
        </div>
      </div>
    )
  }
}
