import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'

const DEFAULT_ORDERS = [
  {
    path: 'precomputation.displayName',
    type: 'ascending',
  },
]

const CUSTOMER_TYPE = '/1.0/entities/metadata/customer.json'
const INTEGRATION_ENTITY_TYPE = '/1.0/entities/metadata/integrationEntities.json'
const QB_SYNC_TYPE = '/1.0/entities/metadata/quickBooksSync.json'

interface ICustomerQBOMappingProps extends IBaseProps {
  settings: Settings
}

export class CustomerQBOMapping extends React.Component<ICustomerQBOMappingProps, any> {

  private customerDataSet: EntityDataSource
  private integrationDataSet: EntityDataSource
  private customerSchema: any
  private qbSyncSchema: any

  constructor(props) {
    super(props)
    const store = apis.getStore()

    this.customerSchema = store.getRecord(CUSTOMER_TYPE)
    this.qbSyncSchema = store.getRecord(QB_SYNC_TYPE)

    this.customerDataSet = new EntityDataSource({
      entityType: CUSTOMER_TYPE,
      orders: DEFAULT_ORDERS,
    }).setOnChange(() => this.forceUpdate())

    this.integrationDataSet = new EntityDataSource({
      entityType: INTEGRATION_ENTITY_TYPE,
      orders: DEFAULT_ORDERS,
    }).setOnChange(this.handleIntegrationDataChange)

    this.state = {
      isTableScrollEnabled: true,
      options: [],
    }
  }

  public componentDidMount() {
    this.customerDataSet.find()
    this.integrationDataSet.find()
  }

  public componentWillUnmount() {
    this.customerDataSet.dispose()
    this.integrationDataSet.dispose()
  }

  public render() {
    return (
      <div className='grid-block vertical'>
        <CardHeader className='c-cardHeader--nonSeparating'>
          <CardHeaderItem
            className='c-cardHeader-item--grow c-cardHeader-item--center'
            title='Customer Mappings'
          />
          {/* {this.renderAutoMapButton()} */}
        </CardHeader>
        <div className='grid-block'>
          {this.renderMappingTable()}
        </div>
      </div>
    )
  }

  public renderAutoMapButton() {
    return (
      <CardHeaderItem>
        <Button
          className='c-button--secondary'
          onClick={this.handleSuggestedMappings}
        >
          Auto-Map
        </Button>
      </CardHeaderItem>
    )
  }

  private handleIntegrationDataChange = () => {
    const { entities } = this.integrationDataSet
    if (_.isEmpty(entities)) {
      return
    }
    _.head(entities).reload().then((entity) => {
      const options = _.filter(entity.content.integrationEntities.entities, (a) => {
        return a.entityType === 'Customer'
      })
      this.setState({
        options,
      })
    })
  }

  private handleSuggestedMappings = () => {
    const { integrationDataSet } = this
    // TODO(david)
    // const uniqueId = _.get(integrationDataSet.entities[0], 'uniqueId', '')
    // apis.quickbooksAutoMapping(
    //   settings.getFirm().uniqueId,
    //   'Customer',
    //   uniqueId,
    //   true,
    // )
  }

  private renderMappingTable() {
    const { customerDataSet, customerSchema } = this
    if (!this.isQBOInstalled()) {
      return this.renderConnectToQuickbooks()
    }

    let content
    if (this.integrationDataSet.isLoading || this.customerDataSet.isLoading) {
      content = (
        <LoadingSpinner />
      )
    } else {
      content = (
        <EntityTable
          className='c-entityTable--notSelectable'
          columns={this.renderTableColumns()}
          entitySchema={customerSchema}
          emptyState={this.renderEmptyState()}
          onEndReached={customerDataSet.handleLoadNextPage}
          onVerticalScroll={this.handleOnVerticalScroll}
          isLoading={customerDataSet.isLoading}
          rows={customerDataSet.content}
        />
      )
    }

    return (
      <Section
        className='grid-block vertical u-bumperLeft--lg u-bumperRight--lg u-bumperBottom--lg c-section--collapse'
        headerClassName='grid-block shrink'
        bodyClassName='grid-block'
      >
        <AutofillBlock className='u-border'>
          {content}
        </AutofillBlock>
      </Section>
    )
  }

  private handleOnVerticalScroll = () => {
    const { isTableScrollEnabled } = this.state
    return isTableScrollEnabled
  }

  private renderTableColumns() {
    const { options } = this.state
    return [
      {
        fixed: true,
        flexGrow: 1,
        isSortable: true,
        label: 'Customer Name',
        path: 'displayName',
        width: 300,
      },
      {
        cellComponent: {
          onChange: this.handleQBOEntitySelected,
          onSelectMenuChange: this.handleSelectMenuChange,
          optionLabelPath: 'name',
          optionValuePath: 'identifier',
          options,
          placeholder: 'QBO Customers',
          size: 'sm',
          type: 'ui:table:select',
        },
        flexGrow: 1,
        label: 'QBO Customer Mapping',
        path: 'displayName',
        width: 400,
      },
      {
        cellComponent: {
          businessType: 'Customer',
          className: 'u-flexGrow tr',
          dataSet: this.integrationDataSet,
          size: 'xs',
          type: 'ui:table:accountingMappingCell',
        },
        flexGrow: 1,
        label: 'Mapping Options',
        path: '.',
        width: 300,
      },
    ]
  }

  private handleSelectMenuChange = () => {
    const { isTableScrollEnabled } = this.state
    this.setState({
      isTableScrollEnabled: !isTableScrollEnabled,
    })
  }

  private handleQBOEntitySelected = (value, option, entity) => {
    this.addQuickBooksSync(entity, option)
  }

  private addQuickBooksSync(entity, qbs) {
    const activeMixins = entity.mixins.active
    if (!_.find(activeMixins, (mixin) => mixin.entityId === '164fd38f-5ea0-41f5-bf35-5aae09ddcb7d')) {
      entity.addMixin(this.qbSyncSchema)
    }
    entity.set('quickBooksSync.identifier', qbs.identifier)
    entity.set('quickBooksSync.version', qbs.version)
    entity.save()
  }

  private renderEmptyState() {
    return (
      <div className='grid-block align-verticalCenter u-justifyContentCenter'>
        <HelpBlock>
          No Customers Found
        </HelpBlock>
      </div>
    )
  }

  private renderConnectToQuickbooks() {
    return (
      <div className='grid-block align-verticalCenter u-justifyContentCenter'>
        <HelpBlock>
          Connect to Quickbooks to map customers.
        </HelpBlock>
      </div>
    )
  }

  private isQBOInstalled() {
    const { settings } = this.props
    const qboSettings = settings.getQuickBooksOnlineSettings()
    return qboSettings.get('settings.isInstalled', false)
  }
}
