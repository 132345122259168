import Cookie from 'js-cookie'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import 'browser/app/pages/app/integrations/_integrations.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
// tslint:disable-next-line:max-line-length
import { DatConfigurationModal } from 'browser/components/atomic-elements/organisms/dat-configuration-modal/dat-configuration-modal'

const MacroPointLogo = require('images/integrations/MacroPoint-logo.jpg')
const QuickBooksLogo = require('images/integrations/QuickBooks-logo.svg')
const XeroLogo = require('images/integrations/Xero-logo.svg')
const DatLogo = require('images/integrations/DAT-logo.svg')

interface IIntegrationsProps extends IBaseProps {
  settings: Settings
}
interface IIntegrationsState {}

export class Integrations extends React.Component<IIntegrationsProps, IIntegrationsState> {

  private qboWindow: any
  private timerId: any

  public componentWillUnmount() {
    clearInterval(this.timerId)
  }

  public render() {
    return (
      <div className='grid-block vertical c-appBody'>
        <Head
          title='Integrations'
        />
        <CardHeader className='u-borderBottom'>
          <CardHeaderItem
            className='u-bumperTop--xs u-bumperBottom--xs'
            superTitle='Integrations'
          />
        </CardHeader>
        <div className='grid-block'>
          {this.renderPageSideBar()}
          <div className='grid-block'>
            {this.renderList()}
          </div>
        </div>
      </div>
    )
  }

  private renderPageSideBar() {
    return (
      <div className='grid-block shrink c-settingsSideNavigation'>
        <div className='grid-content collapse'>
          {/* <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white u-bumperTop--lg'>
            <div className='c-sideNavigationBarItem-label'>
              Active (3)
            </div>
          </a> */}

          <label className='c-label u-bumperTop--lg u-bumperLeft--lg c-label--secondary'>
            Categories
          </label>
          <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
            <div className='c-sideNavigationBarItem-label'>
              Accounting
            </div>
          </a>
          {/* <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
            <div className='c-sideNavigationBarItem-label'>
              Tracking
            </div>
          </a> */}
          <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
            <div className='c-sideNavigationBarItem-label'>
              Load Boards
            </div>
          </a>
        </div>
      </div>
    )
  }

  private renderList() {
    return (
      <div className='grid-block'>
        <div className='grid-content'>
          <div className='row'>
            <div className='col-xs-6'>
              <Section
                className='u-bumperTop'
                title='Accounting'
              >
                <p className='mb3'>Get hours back every week by automatically importing Vector orders into your accounting software. By eliminating manual entries, you’ll always have an accurate, up-to-date picture of your business’s finances.</p>
                <div className='u-innerBumper--xl u-border u-flex'>
                  <img
                    className='c-vendorLogo'
                    src={QuickBooksLogo}
                  />
                  <div className='u-bumperLeft'>
                    <h5 className='c-f5 mb2'>Intuit QuickBooks</h5>
                    <p className='mb3'>Run your entire business with Intuit QuickBooks Online. Track expenses, send invoices, pay employees, and more.</p>
                    <Button
                      className='c-button--secondary'
                      onClick={this.handleConnectQuickBooks}
                    >
                      Connect QuickBooks
                    </Button>
                  </div>
                </div>
              </Section>
            </div>
            <div className='col-xs-6'>
              <Section
                className='u-bumperTop'
                title='Load Boards'
              >
                <p className='mb3'>Load board integrations enable TMS users to post freight rate data to load boards within the TMS environment.</p>
                <div className='u-innerBumper--xl u-border u-flex u-bumperTop--lg'>
                  <img
                    className='c-vendorLogo'
                    src={DatLogo}
                  />
                  <div className='u-bumperLeft'>
                    <h5 className='f5 mb2'>DAT</h5>
                    <p className='mb3'>The DAT load board is trucking{"'"}s super-database for freight and truckload capacity.</p>
                    <Button
                      className='c-button--secondary'
                      onClick={this.handleConnectDat}
                    >
                      Connect DAT
                    </Button>
                  </div>
                </div>
              </Section>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private handleConnectDat = () => {
    DatConfigurationModal.open({})
  }

  private handleConnectQuickBooks = () => {
    const authToken = Cookie.get('Authorization').replace('Bearer ', '')
    // tslint:disable-next-line:max-line-length
    const url = apis.getAPIHost() + `/1.0/entities/actions/accounting/quickbooks/auth/requestToken?access_token=${authToken}`
    const t = (screen.height - 738) / 4
    const l = (screen.width - 848) / 2
    const windowFeatures = 'height=738,width=848,top=' + t + ',left=' + l
    // tslint:disable-next-line:max-line-lengt  h
    this.qboWindow = window.open(url,
      '_blank',
      windowFeatures)
    this.timerId = setInterval(this.checkIfQBOEnabled, 1000)
  }

  private checkIfQBOEnabled = () => {
    const { settings } = this.props
    if (!this.qboWindow.closed) {
      return
    }
    // TODO(david/peter): blows up
    settings.fetchSettings().then(() => {
      const qboSettings = settings.getQuickBooksOnlineSettings()
      const isInstalled = qboSettings.get('settings.isInstalled', false)
      this.setState({
        showZeroState: !isInstalled,
      })
      this.forceUpdate()
    })
    clearInterval(this.timerId)
  }
}
