import React from 'react'
import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Clipboard } from '@blueprintjs/table'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Position, Toast } from '../../atoms/toast/toast'

interface ICopyToClipboardProps {
  value: any
  buttonTitle?: string
  confirmationMessage?: string
}

export const CopyToClipboard = (props: ICopyToClipboardProps) => {
  const { value, buttonTitle, confirmationMessage } = props

  const onClick = (event) => {
    Clipboard.copyString(value)
    if (confirmationMessage) {
      Toast.show({
        message: confirmationMessage,
        position: Position.BOTTOM_RIGHT,
        timeout: 3000,
      })
    }
    event.stopPropagation();
  }

  return (
    <Button onClick={onClick} data-debug-id="follow-button">
      <Icon icon={IconNames.DUPLICATE} title={buttonTitle || 'Copy value'} />
    </Button>
  )
}
