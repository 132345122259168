import React, { useMemo } from 'react'
import { Entity } from 'shared-libs/models/entity'
import _ from 'lodash'
import { InsightsCard } from './insights-card'
import { getAndroidCrashylticsLinkForUserId, getIosCrashylticsLinkForUserId } from './utils'
import { SematextLink, InsightsLink, KibanaLink, AmplitudeLink } from './insights-link'

import './_insights-panel.scss'

interface IInsightsPanelProps {
  entity: Entity
  entities: Entity[]
  users: Entity[]
  selectedEntity: Entity
  selectEntity: (entity: Entity) => void
}

export const InsightsPanel = (props: IInsightsPanelProps) => {
  const { entity, entities, users, selectEntity, selectedEntity } = props
  const selectedEntityId = selectedEntity ? selectedEntity.get('uniqueId') : null
  const creatorId = entity.get('createdBy')?.entityId

  const associations = useMemo(() => {
    return _.sortBy(entities, (association) =>
      _.last(association.activeMixins)?.displayName
    ) as Entity[]
  }, [entities])

  return (
    <div className="insights-panel">
      <h2>Insights</h2>

      <h3 className="u-bumperTop--lg">Core Entity</h3>
      <InsightsCard
        entity={entity}
        onSelect={() => selectEntity(entity)}
        isSelected={entity.get('uniqueId') === selectedEntityId}
        alwaysOpen
      >
        <KibanaLink entity={entity} />
      </InsightsCard>

      <h3 className="u-bumperTop--lg">Related Users</h3>
      {_.map(users, (user) => {
        const userId = user.get('uniqueId')
        return (
          <InsightsCard
            key={userId}
            entity={user}
            onSelect={() => selectEntity(user)}
            isSelected={userId === selectedEntityId}
            badgeText={userId === creatorId ? 'Creator' : undefined}
          >
            <KibanaLink entity={user} />
            <AmplitudeLink userId={userId} />
            <SematextLink user={user} entity={entity} />
            <InsightsLink href={getIosCrashylticsLinkForUserId(userId)}>iOS crashes</InsightsLink>
            <InsightsLink href={getAndroidCrashylticsLinkForUserId(userId)}>
              Android crashes
            </InsightsLink>
          </InsightsCard>
        )
      })}

      <h3 className="u-bumperTop--lg">Related Entities</h3>
      {_.map(associations, (association) => {
        const uniqueId = association.get('uniqueId')
        return (
          <InsightsCard
            key={uniqueId}
            entity={association}
            onSelect={() => selectEntity(association)}
            isSelected={uniqueId === selectedEntityId}
          >
            <KibanaLink entity={association} />
          </InsightsCard>
        )
      })}
    </div>
  )
}
