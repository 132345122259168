import apis from 'browser/app/models/apis'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

interface IClearQBOMappingsModalProps extends IBaseProps {
  settings: any
  onChange: any
  onClose: () => void
}

interface IClearQBOMappingsModalState {
  isDisconnectButtonLoading: boolean
  isDisconnectButtonDisabled: boolean
  isClearDisconnectButtonLoading: boolean
  isClearDisconnectButtonDisabled: boolean
  isCancelButtonDisabled: boolean
}

const FIRM_ADMIN_SCHEMA_ID = '11111111-0000-0000-0000-000000000015'

export class ClearQBOMappingsModal
  extends React.Component<IClearQBOMappingsModalProps, any> {

  public static open(props) {
    return OverlayManager.openOverlayElement(
      <Modal>
        <ClearQBOMappingsModal {...props} />
      </Modal>,
    )
  }

  public store: any

  constructor(props) {
    super(props)
    this.state = {
      isCancelButtonDisabled: false,
      isClearDisconnectButtonDisabled: false,
      isClearDisconnectButtonLoading: false,
      isDisconnectButtonDisabled: false,
      isDisconnectButtonLoading: false,
    }
  }

  public render() {
    const { onClose } = this.props
    const { isCancelButtonDisabled } = this.state
    return (
      <div>
        <div
          className='c-modalHeader'
          data-debug-id='member-configuration'
        >
          <h4 className='c-modal-title'>Disconnect from Quickbooks</h4>
        </div>
        <div className='c-modalBody'>
          <div className='mb3'>
            If you plan on connecting back to the same account, <b>Disconnect</b>. <br/>
            If you plan on connecting to a different account, <b>Clear and Disconnect</b>.
          </div>
        </div>
        <Footer
          isVisible={true}
          onCancelButtonClick={onClose}
          isCancelButtonDisabled={isCancelButtonDisabled}
          primaryButtonGroupElement={this.renderPrimaryButtonGroupElement()}
          isPrimaryButtonDisabled={true}
        />
      </div>
    )
  }

  private renderPrimaryButtonGroupElement = () => {
    const { isCancelButtonDisabled,
      isClearDisconnectButtonDisabled,
      isClearDisconnectButtonLoading,
      isDisconnectButtonDisabled,
      isDisconnectButtonLoading,
    } = this.state

    return (
      <div className='flex'>
        <Button
          className={classNames('mr2', Classes.INTENT_DANGER)}
          onClick={this.handleDisconnectPressed}
          isDisabled={isDisconnectButtonDisabled}
          isLoading={isDisconnectButtonLoading}
        >
          Disconnect
        </Button>
        <div />
        <Button
          className={Classes.INTENT_DANGER}
          onClick={this.handleClearAndDisconnectPressed}
          isDisabled={isClearDisconnectButtonDisabled}
          isLoading={isClearDisconnectButtonLoading}
        >
          Clear Mappings and Disconnect
        </Button>
      </div>
    )
  }

  private handleDisconnectPressed = () => {
    this.setState({
      isCancelButtonDisabled: true,
      isClearDisconnectButtonDisabled: true,
      isDisconnectButtonLoading: true,
    })
    this.handleDisconnect(false)
  }

  private handleClearAndDisconnectPressed = () => {
    this.setState({
      isCancelButtonDisabled: true,
      isClearDisconnectButtonLoading: true,
      isDisconnectButtonDisabled: true,
    })
    this.handleDisconnect(true)
  }

  private handleDisconnect(purgeQBSync) {
    const { onClose, settings, onChange } = this.props
    apis.disconnectFromQuickBooksOnline(purgeQBSync)
      .then(() => settings.fetchSettings())
      .then(() => {
        onChange()
        onClose()
      })
  }

}
