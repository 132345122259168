import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'

const DEFAULT_ORDERS = [
  {
    path: 'precomputation.displayName',
    type: 'ascending',
  },
]

const LINE_ITEM_TYPE = '/1.0/entities/metadata/financialAccountItemType.json'
const INTEGRATION_ENTITY_TYPE = '/1.0/entities/metadata/integrationEntities.json'
const QB_SYNC_TYPE = '/1.0/entities/metadata/quickBooksSync.json'

interface IChargeQBOMappingProps extends IBaseProps {
  settings: Settings
}

export class ChargeQBOMapping extends React.Component<IChargeQBOMappingProps, any> {

  private lineItemDataSet: EntityDataSource
  private lineItemSchema: any
  private integrationDataSet: EntityDataSource
  private qbSyncSchema: any

  constructor(props) {
    super(props)
    const store = apis.getStore()

    this.lineItemSchema = store.getRecord(LINE_ITEM_TYPE)
    this.qbSyncSchema = store.getRecord(QB_SYNC_TYPE)

    this.lineItemDataSet = new EntityDataSource({
      entityType: LINE_ITEM_TYPE,
      orders: DEFAULT_ORDERS,
    }).setOnChange(() => this.forceUpdate())

    this.integrationDataSet = new EntityDataSource({
      entityType: INTEGRATION_ENTITY_TYPE,
      orderes: DEFAULT_ORDERS,
    }).setOnChange(this.handleIntegrationDataChange)

    this.state = {
      isTableScrollEnabled: true,
      options: [],
    }
  }

  public componentDidMount() {
    this.lineItemDataSet.find()
    this.integrationDataSet.find()
  }

  public componentWillUnmount() {
    this.lineItemDataSet.dispose()
    this.integrationDataSet.dispose()
  }

  public render() {
    return (
      <div className='grid-block vertical'>
        <CardHeader className='c-cardHeader--nonSeparating'>
          <CardHeaderItem
            className='c-cardHeader-item--grow c-cardHeader-item--center'
            title='Charge Mappings'
          />
          {this.renderSectionHeader()}
        </CardHeader>
        <div className='grid-block'>
          {this.renderMappingTable()}
        </div>
      </div>
    )
  }

  private renderMappingTable() {
    const { lineItemDataSet, lineItemSchema } = this
    if (!this.isQBOInstalled()) {
      return this.renderConnectToQuickbooks()
    }

    return (
      <Section
        className='grid-block vertical u-bumperLeft--lg u-bumperRight--lg u-bumperBottom--lg c-section--collapse'
        headerClassName='grid-block shrink'
        bodyClassName='grid-block'
      >
        <AutofillBlock className='u-border'>
          <EntityTable
            className='c-entityTable--notSelectable'
            columns={this.renderTableColumns()}
            entitySchema={lineItemSchema}
            emptyState={this.renderEmptyState()}
            onEndReached={lineItemDataSet.handleLoadNextPage}
            onVerticalScroll={this.handleOnVerticalScroll}
            isLoading={lineItemDataSet.isLoading}
            rows={lineItemDataSet.content}
          />
        </AutofillBlock>
      </Section>
    )
  }

  private handleIntegrationDataChange = () => {
    const { entities } = this.integrationDataSet
    if (_.isEmpty(entities)) {
      return
    }
    _.head(entities).reload().then((entity) => {
      const options = _.filter(entity.content.integrationEntities.entities, (a) => {
        return a.entityType === 'Item'
      })
      this.setState({
        options,
      })
    })
  }

  private handleOnVerticalScroll = () => {
    const { isTableScrollEnabled } = this.state
    return isTableScrollEnabled
  }

  private renderTableColumns() {
    const { options } = this.state
    return [
      {
        fixed: true,
        flexGrow: 1,
        isSortable: true,
        label: 'Line Item Name',
        path: 'displayName',
        width: 170,
      },
      {
        cellComponent: {
          onChange: this.handleQBOEntitySelected,
          onSelectMenuChange: this.handleSelectMenuChange,
          optionLabelPath: 'name',
          optionValuePath: 'identifier',
          options,
          placeholder: 'QBO Line Items',
          size: 'sm',
          type: 'ui:table:select',
        },
        label: 'QBO Line Items Mapping',
        path: 'displayName',
        width: 400,
      },
    ]
  }

  private handleSelectMenuChange = () => {
    const { isTableScrollEnabled } = this.state
    this.setState({
      isTableScrollEnabled: !isTableScrollEnabled,
    })
  }

  private handleQBOEntitySelected = (value, option, entity) => {
    const activeMixins = entity.mixins.active
    if (!_.find(activeMixins, (mixin) => mixin.entityId === '164fd38f-5ea0-41f5-bf35-5aae09ddcb7d')) {
      entity.addMixin(this.qbSyncSchema)
    }
    entity.set('quickBooksSync.identifier', option.identifier)
    entity.set('quickBooksSync.version', option.version)
    entity.save()
  }

  private renderSectionHeader() {
    return (
      <CardHeaderItem>
        <Button
          className='c-button--secondary'
          onClick={this.handleShowCreationModal}
        >
          Add Charge Type
        </Button>
      </CardHeaderItem>
    )
  }

  private renderEmptyState() {
    return (
      <div className='grid-block align-verticalCenter u-justifyContentCenter'>
        <HelpBlock>
          No charges found.
        </HelpBlock>
      </div>
    )
  }

  private renderConnectToQuickbooks() {
    return (
      <div className='grid-block align-verticalCenter u-justifyContentCenter'>
        <HelpBlock>
          Connect to Quickbooks to map charges.
        </HelpBlock>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleShowCreationModal = () => {
    const { lineItemSchema } = this
    const hasAccountingIntegration = this.isQBOInstalled()
    const contextProps = {
      density: 'collapse',
      isHorizontalLayout: true,
    }
    EntityFormPanel.open({
      schema: lineItemSchema,
      state: { hasAccountingIntegration },
      uiContext: contextProps,
      uiSchemaPath: 'uiSchema.web.entityCreationModal',
    })
  }

  private isQBOInstalled() {
    const { settings } = this.props
    const qboSettings = settings.getQuickBooksOnlineSettings()
    return qboSettings.get('settings.isInstalled', false)
  }
}
