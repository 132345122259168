import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { IInputProps, Input } from 'browser/components/atomic-elements/atoms/input/input'
import { ITimeInputProps, TimeInput } from 'browser/components/atomic-elements/atoms/input/time-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import 'browser/components/atomic-elements/molecules/fields/hours-of-operation-field/_hours-of-operation-input.scss'
import {
  HoursOfOperationRow,
} from 'browser/components/atomic-elements/molecules/fields/hours-of-operation-field/hours-of-operation-row'
import {
  InputGroupField,
} from 'browser/components/atomic-elements/molecules/fields/input-group-field/input-group-field'
import { Formatter } from 'shared-libs/helpers/formatter'

/**
 * @uiComponent
 */
export interface IHoursOfOperationInputProps extends IInputProps {
  showEditableFields?: boolean
  errorsMap?: any
}

interface IHoursOfOperationInputtState {
  hoursOfOperationByDay: any
}

export class HoursOfOperationInput
  extends React.Component<IHoursOfOperationInputProps, IHoursOfOperationInputtState> {

  constructor(props: IHoursOfOperationInputProps) {
    super(props)
    this.state = this.getNextStateForProps(props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { showEditableFields, value } = nextProps
    if (showEditableFields && value && this.props.value !== value) {
      this.setState(this.getNextStateForProps(nextProps))
    }
  }

  public render() {
    const {
      showEditableFields,
      value,
    } = this.props
    const content = showEditableFields ? this.renderContent(value) : this.renderStaticContent()

    return (
      <div className='c-hoursOfOperation'>
        {content}
      </div>
    )
  }

  private getNextStateForProps(props) {
    const { value } = props
    const hoursByDay = {
      Sunday: [],
      // tslint:disable-next-line:object-literal-sort-keys
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    }
    const open24Hours = [{ start: moment().startOf('day'), end: moment().endOf('day') }]
    _.forEach(value, ({ isOpen24Hours, daysOfWeek, hours }) => {
      _.forEach(daysOfWeek, (dayOfWeek) => {
        if (isOpen24Hours || hoursByDay[dayOfWeek] === open24Hours) {
          hoursByDay[dayOfWeek] = open24Hours
        } else {
          hoursByDay[dayOfWeek] = hoursByDay[dayOfWeek].concat(hours || [])
        }
      })
    })
    _.forEach(hoursByDay, (hours, dayOfWeek) => {
      // TODO(peter/david/louis): need to distinguish between no data and closed
      // If no data then show
      // "None listed" - https://www.dropbox.com/s/6ruoqaptiepmwoe/Screenshot%202017-10-04%2013.40.50.png?dl=0
      if (hours === open24Hours) {
        hoursByDay[dayOfWeek] = 'Open 24 hours'
      } else if (_.isEmpty(hours)) {
        hoursByDay[dayOfWeek] = '-'
        // hoursByDay[dayOfWeek] = 'Closed'
      } else {
        hours = hours.sort((left: any, right: any) => {
          return moment(left.start).isBefore(moment(right.start)) ? -1 : 1
        })
        hoursByDay[dayOfWeek] = _.map(hours, (interval) => Formatter.formatTimeInterval(interval, false)).join(', ')
      }
    })
    return {
      hoursOfOperationByDay: hoursByDay,
    }
  }

  private renderStaticContent() {
    const { size } = this.props
    const { hoursOfOperationByDay } = this.state
    const sizeClass = _.isEmpty(size) ? 'c-fakeInputContainer' : `c-fakeInputContainer--${size}`
    return (
      <div className={classNames(sizeClass)}>
        <table className='c-table c-table--noBorders c-hoursOfOperationTable'>
          <tbody className='c-table-body'>
            <tr>
              <td className='u-innerBumperRight--xs'>Sunday</td>
              <td>{hoursOfOperationByDay.Sunday}</td>
            </tr>
            <tr>
              <td className='u-innerBumperRight--xs'>Monday</td>
              <td>{hoursOfOperationByDay.Monday}</td>
            </tr>
            <tr>
              <td className='u-innerBumperRight--xs'>Tuesday</td>
              <td>{hoursOfOperationByDay.Tuesday}</td>
            </tr>
            <tr>
              <td className='u-innerBumperRight--xs'>Wednesday</td>
              <td>{hoursOfOperationByDay.Wednesday}</td>
            </tr>
            <tr>
              <td className='u-innerBumperRight--xs'>Thursday</td>
              <td>{hoursOfOperationByDay.Thursday}</td>
            </tr>
            <tr>
              <td className='u-innerBumperRight--xs'>Friday</td>
              <td>{hoursOfOperationByDay.Friday}</td>
            </tr>
            <tr>
              <td className='u-innerBumperRight--xs'>Saturday</td>
              <td>{hoursOfOperationByDay.Saturday}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  private renderContent(value) {
    const { onChange, size } = this.props
    return (
      <List
        addButtonText='Add another set of days'
        onChange={onChange}
        renderListItem={this.renderSetOfDays}
        showPlaceholderItem={true}
        showAddButton={false}
        size={size}
        value={value}
      />
    )
  }

  private renderSetOfDays = (props: IRenderListItemProps) => {
    const { item, index } = props
    const { errorsMap } = this.props
    const handleChange = (value) => this.handleItemChange(item, index)
    return (
      <HoursOfOperationRow
        errorsMap={errorsMap ? errorsMap[index] : null}
        onChange={handleChange}
        value={item}
      />
    )
  }

  private handleItemChange = (item, index) => {
    const { onChange } = this.props
    const value = this.props.value || []
    value[index] = item
    onChange(value)
  }

}
