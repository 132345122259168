import { Classes, Icon, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import React from 'react'
import classNames from 'classnames'
import { Entity } from 'shared-libs/models/entity'
import { getSematextLinkForUserId, getKibanaUrlForEntity, getAmplitudeLinkForUserId } from './utils'
import './_insights-link.scss'

interface IInsightsLinkProps {
  href: string
  children: React.ReactNode
}

export const AmplitudeLink = ({ userId }: { userId: string }) => (
  <InsightsLink href={getAmplitudeLinkForUserId(userId)}>
    Amplitude data
    <Tooltip
      content="For data before Q3 2022, you may need to switch Amplitude projects. For data after Q2 2023 use the Sematext link."
      position={Position.BOTTOM}
    >
       <Icon className="insights-panel__icon" icon={IconNames.INFO_SIGN} />
    </Tooltip>
  </InsightsLink>
)

export const SematextLink = ({ user, entity }: { user: Entity; entity?: Entity }) => (
  <InsightsLink href={getSematextLinkForUserId(user, entity)}>
    Sematext client data
  </InsightsLink>
)

export const KibanaLink = ({ entity }: { entity: Entity }) => (
  <InsightsLink href={getKibanaUrlForEntity(entity)}>Kibana logs</InsightsLink>
)

export const InsightsLink = (props: IInsightsLinkProps) => {
  const { children, href } = props
  return (
    <a
      className={classNames('insights-link', Classes.BUTTON)}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
