import _ from 'lodash'
import React from 'react'
import queryString from 'query-string'
import styled from 'styled-components'
import { SandboxClient } from './sandbox-client'
import { WarningSign } from '@blueprintjs/icons'
import { Entity } from 'shared-libs/models/entity'
import DesktopComponentsMap from 'browser/components/index'
import { Toast } from 'browser/mobile/components/toast/toast'
import MobileComponentsMap from 'browser/mobile/components/index'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { QRCodeView } from 'browser/components/atomic-elements/atoms/qrcode-view/qrcode-view'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

export type ConnectionConfig = {
  /** sandbox server url override */
  serverUrl?: string
  /** sandbox server port override */
  port?: string
}

interface ISafeRendererProps {
  selectedPlatform: string
  entity: Entity
  schema: Entity
  /** hook for Entity updates in a standard EntityRenderer */
  onChange: (entity: Entity) => void
  /** hook for entity json content updates from sandbox client */
  onChangeContent: (content: any) => void
  onCopyMobileDeeplinkClick: () => void
  onConfigUpdate: (config: ConnectionConfig) => void
}

export class SandboxRenderer extends React.Component<ISafeRendererProps> {
  private mobileRendererRef = React.createRef<MobileRenderer>()

  public render() {
    const {
      schema,
      selectedPlatform,
      entity,
      onChange,
      onChangeContent,
      onCopyMobileDeeplinkClick: onCopyMobileDeeplinkClick,
      onConfigUpdate,
    } = this.props

    if (selectedPlatform === 'mobile') {
      return (
        <MobileRenderer
          ref={this.mobileRendererRef}
          schema={schema}
          entity={entity}
          onChangeContent={onChangeContent}
          onRequestCopyMobileDeeplink={onCopyMobileDeeplinkClick}
          onConfigUpdate={onConfigUpdate}
        />
      )
    }

    const ComponentsMap = selectedPlatform === 'web' ? DesktopComponentsMap : MobileComponentsMap

    return (
      <AppNavigatorContext.Consumer>
        {({ settings }) => (
          <EntityRenderer
            value={entity}
            schema={schema}
            state={{ settings }}
            uiSchemaPath="uiSchema"
            componentsMap={ComponentsMap}
            onChangeComplete={onChange}
            translationTable={globalTranslationTable}
          />
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  /* forwards the latest content to the mobile renderer / sandbox client, if active */
  public sendLatestValue = (entityJson: any, schemaJson: any) => {
    this.mobileRendererRef.current?.sendLatestValue(entityJson, schemaJson)
  }
}

interface IMobileRendererProps {
  schema: Entity
  entity: Entity
  onChangeContent: (content: any) => void
  onRequestCopyMobileDeeplink: () => void
  onConfigUpdate: (config: ConnectionConfig) => void
}

interface IMobileRendererState {
  /**
   * The sandbox server's own IP address. A convenience for debug builds running
   * on a physical device.
   */
  serverAddress?: string
  isConnected?: boolean
  error?: any
}

/** shares JSON data with connected mobile RTC data peer */
class MobileRenderer extends React.Component<IMobileRendererProps, IMobileRendererState> {
  private sandboxClient = new SandboxClient()

  constructor(props: IMobileRendererProps) {
    super(props)

    this.state = {}
  }

  public componentDidMount(): void {
    this.connect()
  }

  public componentWillUnmount(): void {
    this.sandboxClient.close()
  }

  public render() {
    const { error } = this.state
    return (
      <div className="pa1">
        <div className="mb2">{error && this.renderError(error)}</div>
        {this.renderReconnect()}
        {this.renderServerUrl()}
      </div>
    )
  }

  private renderReconnect() {
    const { serverUrl, port } = queryString.parse(location.search)
    const { isConnected } = this.state

    if (isConnected) {
      return null
    }

    return (
      <div className="tc">
        <div className="c-sandboxNote mt4 mb2 pre-wrap tl">
          To render this UI schema on a mobile device, the sandbox server must be running. If you
          need to start the sandbox server, see the <strong>scripts/sandbox-server</strong> project.
          Once that is running, you may configure the URL below as needed.
        </div>
        <Section title="Connection Config">
          <InputField
            label="Sandbox Server URL"
            isDisabled={true}
            value={this.getSandboxServerUrl()}
          />
          <InputField
            label="Port Override"
            placeholder="Enter a port to override the default"
            isDisabled={!!serverUrl}
            onChange={this.handleServerPortChange}
            value={!serverUrl ? port : 'ignored'}
          />
          <HelpBlock>
            This overrides the default port of 3000, in case you are running the sandbox server on a
            non-default port.
          </HelpBlock>
          <InputField
            label="URL Override"
            placeholder="Enter a URL to override the default"
            onChange={this.handleServerUrlChange}
            value={serverUrl}
          />
          <HelpBlock className="pre-wrap">
            This should be a WebSocket url pointing to the running sandbox server.
            {'\n'}
            {'\n'}
          </HelpBlock>
        </Section>
        <Button intent="primary" buttonText="Connect" onClick={this.handleConnectClicked} />
        <hr className="mt4 mb4" />
        <div className="c-sandboxNote pre-wrap tl">
          <WarningSign /> <strong>WARNING</strong>
          {'\n'}
          {'\n'}
          The default sandbox server URL {this.getDefaultSandboxServerUrl()} will only work with the{' '}
          <strong>DEBUG</strong> variant of the mobile app, which permits HTTP/cleartext traffic.
          (In this case, the sandbox server sends its IP address back to web, and web offers that as
          a QR code for mobile to connect to over LAN.)
          {'\n'}
          {'\n'}
          If you{"'"}re using the <strong>RELEASE</strong> variant of the mobile app, that will not
          work, as cleartext HTTP is blocked. You will need to use a reverse proxy so that your
          mobile device can connect to the sandbox server over HTTPS. You may use our{' '}
          <a href="https://www.notion.so/Secure-tunnels-with-CloudFlare-e24cc8aeacc447d9abaca605a7cdf2c5">
            CloudFlare tunnel script
          </a>
          , <a href="https://ngrok.com/download">ngrok</a>, etc. Make sure to use{' '}
          <strong>wss://</strong> for the scheme instead of ws://, for example
          wss://sandbox123.local-323200.com/ or wss://0135-174-160-108-120.ngrok-free.app. Once you
          have obtained that URL, enter it below for the URL Override before clicking Connect.
        </div>
      </div>
    )
  }

  private handleServerUrlChange = (serverUrl: string) => {
    const { onConfigUpdate } = this.props
    onConfigUpdate({ serverUrl })
  }

  private handleServerPortChange = (port: string) => {
    const { onConfigUpdate } = this.props
    onConfigUpdate({ port })
  }

  private renderError(error) {
    return (
      <ErrorMessageContainer>
        <ErrorTitle>
          {`${error.type} error:`}
        </ErrorTitle>
        <ErrorText>
          {error.message}
        </ErrorText>
      </ErrorMessageContainer>
    )
  }

  private renderServerUrl() {
    const { onRequestCopyMobileDeeplink } = this.props
    const { isConnected } = this.state
    if (!isConnected) {
      return null
    }

    const localhostWarning = this.getSandboxServerUrl().includes('localhost')
      ? ' (NOTE: if using the mobile release variant, it will not be able to connect to the sandbox server\'s local IP address over HTTP (ws://). If so, disconnect and use a reverse proxy over HTTPS (wss://) instead. Disregard if using the mobile debug variant.)'
      : ''

    return (
      <div className="tc">
        <div className="mb2">
          Connected to the sandbox server at {this.getSandboxServerUrl()}
        </div>
        <Button buttonText="Disconnect" onClick={this.handleDisconnectClicked} />
        <HelpBlock className="pre-wrap">
          {localhostWarning}
        </HelpBlock>

        <div className="mt4">
          <strong className="mb4">
            Mobile app &gt; Menu &gt; Settings &gt; Tap the {'"'}Version{'"'} number 10 times
          </strong>
        </div>
        <div className="mt2">
          and scan this QR code to connect:
        </div>

        <QRCodeView className="mt4" qrValue={this.getMobileSandboxServerUrl()} qrSize={256} />
        <HelpBlock className="pre-wrap">
          Sandbox server{"'"}s local address: {this.getMobileSandboxServerUrl()}
        </HelpBlock>

        <hr className="mt4 mb4" />

        <Button buttonText="Copy deeplink" onClick={onRequestCopyMobileDeeplink} />
        <HelpBlock className="pre-wrap">
          The mobile sandbox deeplink enables opening the sandbox on mobile and rendering its
          jsonProps string independently of the desktop web sandbox page and sandbox-server, useful
          for automated UI testing for example.
        </HelpBlock>
      </div>
    )
  }

  private connect = _.debounce(() => {
    this.sandboxClient.start({
      serverUrl: this.getSandboxServerUrl(),
      onConnected: this.handleSandboxConnected,
      onMessageReceived: this.handleSandboxMessage,
      onError: this.handleSandboxError,
      onDisconnected: this.handleSandboxDisconnected,
    })
  }, 300)

  private getSandboxServerUrl() {
    const { serverUrl } = queryString.parse(location.search)

    if (serverUrl) {
      return serverUrl
    }
    return this.getDefaultSandboxServerUrl()
  }

  private getDefaultSandboxServerUrl() {
    return `ws://localhost:${this.getPort()}`
  }

  /* Provides a sandbox url suitable for the mobile device. */
  private getMobileSandboxServerUrl() {
    const { serverUrl } = queryString.parse(location.search)
    const { serverAddress } = this.state

    if (serverUrl) {
      return serverUrl
    } else {
      return `ws://${serverAddress}`
    }
  }

  private getPort() {
    const { port } = queryString.parse(location.search)
    return port || 3000
  }

  private handleConnectClicked = () => {
    this.setState({ error: undefined }, this.connect)
  }

  private handleDisconnectClicked = () => {
    this.sandboxClient.close()
  }

  private handleSandboxConnected = () => {
    const { schema, entity } = this.props
    console.log('connected to sandbox server')
    Toast.show({ message: 'connected to sandbox server' })
    this.sandboxClient.send({ type: 'register-host' })
    this.sandboxClient.send({
      type: 'latest-value',
      value: {
        entity: entity.content,
        schema: schema.content,
      },
    })
    this.setState({ isConnected: true })
  }

  private handleSandboxMessage = (data: MessageEvent['data']) => {
    const { onChangeContent } = this.props
    console.log('Received message:', data)
    const json = JSON.parse(data)

    if (json.type === 'error-register-host') {
      this.setState({ error: { type: 'connection', message: json.value } }, () =>
        this.sandboxClient.close()
      )
    } else if (json.type === 'registered-host') {
      this.setState({
        serverAddress: json.value.serverAddress,
      })
    } else if (json.type === 'update-entity-content') {
      onChangeContent(json.value.entity)
    }
  }

  private handleSandboxError = (error: any) => {
    console.error('sandbox server error:', error)
    this.setState({
      error: {
        type: 'connection',
        message: `Failed to connect to a sandbox server at ${this.getSandboxServerUrl()}.`,
      },
    })
  }

  private handleSandboxDisconnected = () => {
    const defaultError = { type: 'connection', message: 'Disconnected from the sandbox server' }
    // preserve existing error if handling a disconnect after an error
    const { error = defaultError } = this.state
    console.log('disconnected from the sandbox server')
    Toast.show({ message: 'disconnected from the sandbox server' })
    this.setState({ isConnected: false, error })
  }

  public sendLatestValue = (entityJson: any, schemaJson: any) => {
    this.sandboxClient.send({
      type: 'latest-value',
      value: {
        entity: entityJson,
        schema: schemaJson,
      },
    })
  }
}

const ErrorMessageContainer = styled.div`
  background-color: #ffebee;
  border-radius: 5px;
  justify-content: center;
  margin-top: 5px;
`

const ErrorTitle = styled.div`
  font-weight: bold;
  color: #d45c43;
  padding-right: 5px;
  padding-left: 5px;
`

const ErrorText = styled.div`
  color: #d45c43;
  padding-right: 5px;
  padding-left: 10px;
`
