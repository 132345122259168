import { CopyToClipboard } from 'browser/components/atomic-elements/molecules/copy-to-clipboard/copy-to-clipboard'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Entity } from 'shared-libs/models/entity'

import './_insights-card-header.scss'

interface IInsightsCardHeaderProps {
  entity: Entity
  isSelected: boolean
  onClick: () => void
  badgeText?: string
}

export const InsightsCardHeader = (props: IInsightsCardHeaderProps) => {
  const { badgeText, entity, isSelected, onClick } = props
  return (
    <div
      className={classNames('insights-card-header', {
        'insights-card-header--selected': isSelected,
      })}
      onClick={onClick}
    >
      <div>
        <h4>{entity.displayName}</h4>
        <p className="insights-card-header__subtitle">
          {_.last(entity.activeMixins)?.displayName}
        </p>
      </div>
      {badgeText && <div className="insights-card-header__badge">{badgeText}</div>}
      <CopyToClipboard
        value={entity.get('uniqueId')}
        buttonTitle="Copy ID"
        confirmationMessage="Copied ID to clipboard"
      />
    </div>
  )
}
