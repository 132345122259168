import React from 'react'
import './_insights-card-body.scss'

interface IInsightsCardBodyProps {
  children: React.ReactNode
}

export const InsightsCardBody = (props: IInsightsCardBodyProps) => {
  const { children } = props
  return <div className="insights-card-body">{children}</div>
}
