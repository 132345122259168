import classNames from 'classnames'
import React from 'react'
import SplitPane from 'react-split-pane'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/master-detail/_master-detail.scss'

interface IMasterDetailProps extends IBaseProps {
  detailPanel: React.ReactElement<any>
  detailPanelAllowResize?: boolean
  detailPanelDefaultSize?: number
  detailPanelMaxSize?: number
  detailPanelMinSize?: number
  detailPanelSplit?: 'vertical' | 'horizontal'
  isStackLayout?: boolean
  masterPanel: React.ReactElement<any>
  showDetailPanel: boolean
}

export class MasterDetail extends React.Component<IMasterDetailProps, any> {
  public static defaultProps = {
    detailPanelAllowResize: true,
    detailPanelDefaultSize: 544,
    detailPanelMaxSize: 1500,
    detailPanelMinSize: 200,
    detailPanelSplit: 'vertical',
  }

  public render() {
    const {
      className,
      detailPanel,
      detailPanelAllowResize,
      detailPanelDefaultSize,
      detailPanelMaxSize,
      detailPanelMinSize,
      detailPanelSplit,
      isStackLayout,
      showDetailPanel,
      masterPanel,
    } = this.props

    let minSize = 0
    let maxSize = 0
    let defaultSize = 0
    let allowResize = false

    const hasSecondaryPanel = !isStackLayout && showDetailPanel
    if (hasSecondaryPanel) {
      minSize = detailPanelMinSize
      maxSize = detailPanelMaxSize
      defaultSize = detailPanelDefaultSize
      allowResize = detailPanelAllowResize
    }

    // TODO(louis): use className eventually
    // pane2ClassName='c-masterDetail-detail'
    // When SplitPane releases master branch
    return (
      <div className='grid-block u-positionRelative'>
        <SplitPane
          className={classNames('grid-block c-masterDetail', className, {
            'has-detailPanel': showDetailPanel,
          })}
          primary='second'
          pane2Style={{ borderLeft: '1px solid #edeeee'}}
          allowResize={allowResize}
          defaultSize={defaultSize}
          minSize={minSize}
          maxSize={maxSize}
          split={detailPanelSplit}
        >
          <div className={classNames('grid-block vertical')}>
            {this.renderPrimaryPane()}
          </div>
          {this.renderSecondaryPane()}
        </SplitPane>
      </div>
    )
  }

  private renderPrimaryPane() {
    const { detailPanel, isStackLayout, masterPanel, showDetailPanel } = this.props
    if (isStackLayout) {
      return showDetailPanel ? detailPanel : masterPanel
    }
    return masterPanel
  }

  private renderSecondaryPane() {
    const { detailPanel, isStackLayout, showDetailPanel } = this.props
    return isStackLayout ? <div></div> : detailPanel
  }
}
