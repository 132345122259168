import _ from 'lodash'
import React from 'react'

import 'browser/app/pages/app/tools/_tools.scss'
import { ExpressionEvaluator, EsprimaParser, VegaParser } from 'shared-libs/helpers/evaluation'
import { CustomFormulas } from 'shared-libs/helpers/formulas'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { copyShareableToolsURL, getShareableToolsURL, serializeAsString, unpackToolsURL } from '../utils'
import { ErrorMessageContainer, ErrorText, ErrorTitle } from '../errors'
import { createEntityMappingFormulaContext } from 'shared-libs/models/entity-mapping'
import { javascript } from '@codemirror/lang-javascript'
import apis from 'browser/app/models/apis'

type IEvaluationToolProps = IBaseProps

interface IEvaluationToolState {
  formulaString: string
  contextString: string
  useEsprimaParser: boolean
  program?: string
  result?: any
  resultText?: any
  errors?: any[]
}

const PLACEHOLDER_FORMULA = '5 + prop1 + fn()'
const PLACEHOLDER_CONTEXT = `{
  prop1: 5,
  fn: () => 1+2,
}`

export class EvaluationTool extends React.Component<IEvaluationToolProps, IEvaluationToolState> {

  private DEFAULT_CONTEXT

  constructor(props) {
    super(props)
    const jsonProps = unpackToolsURL()

    this.state = {
      formulaString: jsonProps.formulaString || PLACEHOLDER_FORMULA,
      contextString: jsonProps.contextString || PLACEHOLDER_CONTEXT,
      useEsprimaParser: true,
    }

    this.DEFAULT_CONTEXT = {
      ...CustomFormulas,
      ...createEntityMappingFormulaContext(apis.getStore()),
      _,
    }
  }

  componentDidMount() {
    this.evaluate()
  }

  render() {
    const { useEsprimaParser } = this.state
    return (
      <div className="grid-block vertical">
        <CardHeader className="c-cardHeader--nonSeparating">
          <CardHeaderItem
            className="c-cardHeader-item--grow c-cardHeader-item--center"
            title="Formula Sandbox"
          />
          <CardHeaderItem>
            <Button
              buttonText={useEsprimaParser ? 'Restrict Syntax' : 'Unrestrict syntax'}
              onClick={this.handleToggleRestricted}
            />
          </CardHeaderItem>
          <CardHeaderItem>
            <Button buttonText="Copy Shareable Link" onClick={this.handleShare} />
          </CardHeaderItem>
        </CardHeader>
        {this.renderCard()}
      </div>
    )
  }

  private renderCard() {
    const { formulaString, contextString, resultText, program } = this.state
    return (
      <div className="c-tools grid-block">
        <div className="grid-content">
          <div className="row">
            <div className="col-xs-6">
              <Section title="Formula">
                <CodeMirrorInput
                  maxHeight='600px' // TODO: remove, and add resize capability
                  value={formulaString}
                  extensions={[javascript()]}
                  onChange={(formulaString) => {
                    this.setState({ formulaString }, this.evaluate)
                  }}
                />
              </Section>
            </div>
            <div className="col-xs-6">
              <Section title="Context">
                <CodeMirrorInput
                  maxHeight='600px' // TODO: remove, and add resize capability
                  value={contextString}
                  onChange={(contextString) => {
                    this.setState({ contextString }, this.evaluate)
                  }}
                />
              </Section>
            </div>
            {this.renderErrors()}
            <div className="col-xs-12">
              <Section title="Result">
                <div className="code pa2 br2 ba b--gold bg-light-yellow">
                  <pre>
                  {resultText}
                  </pre>
                </div>
              </Section>
            </div>
            <div className="col-xs-12">
              <Section title="Generated Program">
                <div className="code pa2 br2 ba b--gray bg-near-white">
                  {program}
                </div>
              </Section>
            </div>
            <div className="col-xs-12">
              <Section title="Default Context">
                <div className="code pa2 br2 ba b--gray bg-near-white">
                  {Object.keys(this.DEFAULT_CONTEXT).map((key) => (
                    <div key={key}>{key}</div>
                  ))}
                </div>
              </Section>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderErrors() {
    const { errors } = this.state
    if (_.isEmpty(errors)) {
      return null
    }
    return (
      <div className='col-xs-12'>
        <Section title="Errors">
            {errors.map((error, idx) => this.renderError(error, idx))}
        </Section>
      </div>
    )
  }

  private renderError(error, idx) {
    return (
      <ErrorMessageContainer>
        <ErrorTitle>
          {`${error.type} error:`}
        </ErrorTitle>
        <ErrorText>
          {error.message}
        </ErrorText>
      </ErrorMessageContainer>
    )
  }

  private handleToggleRestricted = () => {
    this.setState({
      useEsprimaParser: !this.state.useEsprimaParser,
    }, this.evaluate)
  }

  private handleShare = () => {
    const { formulaString, contextString } = this.state
    const url = getShareableToolsURL({
      formulaString,
      contextString,
    }).toString()
    copyShareableToolsURL(url)
  }

  private evaluate = _.debounce(() => {
    const { formulaString, contextString, useEsprimaParser } = this.state

    const errors = []

    // build context
    let ctx
    try {
      ctx = Function(`return ${contextString}`)()
    } catch (e) {
      errors.push({
        type: 'context',
        message: e.message || e,
      })
      ctx = {}
    }
    ctx = {
      ...this.DEFAULT_CONTEXT,
      ...ctx,
    }

    // evaluate formula, capture any errors
    const evaluator = ExpressionEvaluator.create()
      .setErrorLogger((e) => errors.push({
        type: 'evaluation',
        message: e.message || e,
      }))
      .setValueGetter((key) => _.get(ctx, key, CustomFormulas[key]))
      .setASTParser(useEsprimaParser ? EsprimaParser : VegaParser)

    const program = evaluator.getProgramString(formulaString)
    const promise = evaluator.evaluate(formulaString)
    Promise.resolve(promise).then((result) => {
      let resultText
      try {
        resultText = serializeAsString(result)
      } catch (e) {
        errors.push({
          type: 'parsing',
          message: e.message || e,
        })
      }
      this.setState({ result, resultText, program, errors })
    }).catch((error) => {
      errors.push(error)
      this.setState({ result: undefined, resultText: undefined, program, errors })
    })
  }, 350)

}
