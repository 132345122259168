import _ from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { ValidationTool as ValidationToolPage } from 'browser/app/pages/app/tools/validation'
import { EvaluationTool as EvaluationToolPage } from 'browser/app/pages/app/tools/evaluation'
import { RenderingTool as RenderingToolPage } from 'browser/app/pages/app/tools/rendering'
import { EntityMappingsTool as EntityMappingsToolPage } from 'browser/app/pages/app/tools/mappings'
import { CloningTool as CloningToolPage } from 'browser/app/pages/app/tools/cloner'
import { WorkflowTool as WorkflowToolPage } from 'browser/app/pages/app/tools/workflow'
import { QrBuilderDocument as QrBuilderDocumentPage } from 'browser/app/pages/app/tools/qr-builder-document'
import { QrBuilderJson as QrBuilderJsonPage } from 'browser/app/pages/app/tools/qr-builder-json'
import { RemoteConfig as RemoteConfigPage } from './remote-config'

const SentryRoute = Sentry.withSentryRouting(Route);

interface IToolsProps extends IBaseProps {
  match: any
}

export class Tools extends React.Component<IToolsProps, any> {

  public render() {
    const { match } = this.props
    return (
      <AppNavigatorContext.Consumer>
        {({ settings }) => settings.isAdmin && (
          <div className="grid-block vertical c-appBody">
            <Head title="Admin Tools" />
            <CardHeader className="u-borderBottom">
              <CardHeaderItem className="u-bumperTop u-bumperBottom" superTitle="Admin Tools" />
            </CardHeader>
            <div className="grid-block">
              <div className="grid-block shrink c-settingsSideNavigation">
                <div className="grid-content collapse">
                  <Label isSecondary={true} className="u-bumperTop--lg u-bumperLeft--lg">
                    Tools
                  </Label>
                  {this.renderToolLinks()}
                </div>
              </div>
              <div className="grid-block">
                <Switch>
                  <SentryRoute
                    path={`${match.url}/validation`}
                    component={() => <ValidationToolPage />}
                  />
                  <SentryRoute
                    path={`${match.url}/evaluation`}
                    render={() => <EvaluationToolPage />}
                  />
                  <SentryRoute
                    path={`${match.url}/rendering`}
                    render={() => <RenderingToolPage/>}
                  />
                  <SentryRoute
                    path={`${match.url}/mappings`}
                    render={() => <EntityMappingsToolPage/>}
                  />
                  <SentryRoute
                    path={`${match.url}/cloner`}
                    render={() => <CloningToolPage />}
                  />
                  <SentryRoute
                    path={`${match.url}/workflow`}
                    render={() => <WorkflowToolPage />}
                  />
                  <SentryRoute
                    path={`${match.url}/qr-builder-document`}
                    render={() => <QrBuilderDocumentPage />}
                  />
                  <SentryRoute
                    path={`${match.url}/qr-builder-json`}
                    render={() => <QrBuilderJsonPage />}
                  />
                  <SentryRoute
                    path={`${match.url}/remote-config`}
                    render={() => <RemoteConfigPage
                      settings={settings}
                    />}
                  />
                </Switch>
              </div>
            </div>
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderToolLinks() {
    return (
      <div>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/validation'
        >
          <div className='c-sideNavigationBarItem-label'>
            Validator Sandbox
          </div>
        </NavLink>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/evaluation'
        >
          <div className='c-sideNavigationBarItem-label'>
            Formula Sandbox
          </div>
        </NavLink>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/rendering'
        >
          <div className='c-sideNavigationBarItem-label'>
            Rendering Sandbox
          </div>
        </NavLink>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/mappings'
        >
          <div className='c-sideNavigationBarItem-label'>
            Entity Mappings Sandbox
          </div>
        </NavLink>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/cloner'
        >
          <div className='c-sideNavigationBarItem-label'>
            Entity Cloner
          </div>
        </NavLink>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/workflow'
        >
          <div className='c-sideNavigationBarItem-label'>
            Workflow Inspector
          </div>
        </NavLink>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/qr-builder-document'
        >
          <div className='c-sideNavigationBarItem-label'>
            QR Code Builder (Document)
          </div>
        </NavLink>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/qr-builder-json'
        >
          <div className='c-sideNavigationBarItem-label'>
            QR Code Builder (JSON)
          </div>
        </NavLink>
        <NavLink
          className='c-sideNavigationBarItem c-sideNavigationBarItem--white'
          activeClassName='is-active'
          to='/tools/remote-config'
        >
          <div className='c-sideNavigationBarItem-label'>
            Remote Config
          </div>
        </NavLink>
      </div>
    )
  }

}
