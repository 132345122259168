import { useEffect, useState } from 'react'

import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'

export const useFetchEntities = (entityQuery) => {
  const [result, setResult] = useState([])
  const ordersDataSource = new EntityDataSource(entityQuery).setOnChange((res) => {
    setResult(res)
  })

  useEffect(() => {
    ordersDataSource.find()
    return () => ordersDataSource.dispose()
  }, [])

  return [result]
}
