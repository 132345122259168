import apis from 'browser/app/models/apis'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import _ from 'lodash'
import React from 'react'
import { withRouter } from 'react-router-dom'
import SplitPane from 'react-split-pane'
import { Entity } from 'shared-libs/models/entity'
import { EntityPage } from '../entity/entity-page'
import { InsightsPanel } from './insights-panel'
import { fetchRelatedEntities } from './utils'

interface IInsightsRoutingProps {
  match: any
  history: any
  location: any
}

type IInsightsProps = Partial<IInsightsRoutingProps> & {}

interface IInsightsState {
  isLoading: boolean
  entity: Entity
  selectedEntity: Entity
  users: Entity[]
  entities: Entity[]
}

@withRouter
export class Insights extends React.Component<IInsightsProps, IInsightsState> {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      selectedEntity: null,
      users: [],
      entities: [],
      entity: null,
    }
  }

  public componentDidMount() {
    const entityId = this.entityIdFromProps(this.props)
    if (entityId) {
      this.initializeForEntityId(entityId)
    }
  }

  public componentDidUpdate(prevProps: IInsightsProps) {
    const entityId = this.entityIdFromProps(this.props)
    if (this.entityIdFromProps(prevProps) !== entityId) {
      this.initializeForEntityId(entityId)
    }
  }

  public render() {
    const { isLoading, entity, users, entities, selectedEntity } = this.state
    if (isLoading) {
      return <LoadingSpinner label="Loading insights" />
    }
    return (
      <SplitPane defaultSize="50%">
        <InsightsPanel
          entities={entities}
          users={users}
          entity={entity}
          selectedEntity={selectedEntity}
          selectEntity={this.selectEntity}
        />
        {this.renderEntityDetails()}
      </SplitPane>
    )
  }

  private renderEntityDetails = () => {
    const { selectedEntity } = this.state
    const { history, location, match } = this.props
    if (!selectedEntity) {
      return <p>Select an entity</p>
    }
    return (
      <EntityPage
        isFullScreen={false}
        entity={selectedEntity}
        history={history}
        location={location}
        match={match}
        onClose={this.clearSelectedEntity}
      />
    )
  }

  private initializeForEntityId = async (entityId: string) => {
    this.setState({
      isLoading: true,
    })

    const store = apis.getStore()
    const entity: Entity = await store.findRecord(entityId)
    const { entities, users } = await fetchRelatedEntities(store, entity)

    this.setState({
      isLoading: false,
      users,
      entities,
      entity,
      selectedEntity: entity,
    })
  }

  private selectEntity = (entity: Entity) => {
    this.setState({
      selectedEntity: entity,
    })
  }

  private clearSelectedEntity = () => {
    this.setState({
      selectedEntity: null,
    })
  }

  private entityIdFromProps(props: IInsightsProps) {
    return props.match?.params?.entityId
  }
}
