import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import SplitPane from 'react-split-pane'

import { StoryboardPlanEntity } from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { WorkflowStoryPanel } from './workflow-story-panel'
import { WorkflowScenePanel } from './workflow-scene-panel'
import { WorkflowTaskPanel } from './workflow-task-panel'

const ROOT = '__ROOT__'

interface IWorkflowDetails {
  plan: StoryboardPlanEntity
}

export const WorkflowDetails = (props: IWorkflowDetails) => {
  const { plan } = props

  const { storiesById, scenesById, tasksById } = useMemo(() => {
    return {
      storiesById: _.keyBy(plan.core_storyboard_plan.stories, 'id'),
      scenesById: _.keyBy(plan.core_storyboard_plan.scenes, 'id'),
      tasksById: _.keyBy(plan.core_storyboard_plan.tasks, 'id'),
    }
  }, [plan])

  const [selectedStoryId, setSelectedStoryId] = useState(
    () => _.first(plan.core_storyboard_plan.stories).id
  )
  const [selectedSceneId, setSelectedSceneId] = useState(
    () => _.first(plan.core_storyboard_plan.scenes).id
  )
  const [selectedTaskId, setSelectedTaskId] = useState(
    () => _.first(plan.core_storyboard_plan.tasks).id
  )

  const { sceneRoutes, initialSceneId } = useMemo(() => {
    const sceneRoutes = []
    let initialSceneId = null
    const story = _.find(plan.core_storyboard_plan.stories, { id: selectedStoryId })
    if (!story) {
      return
    }
    _.forEach(story.sceneRoutes, (route) => {
      _.forEach(route.destinations, (destination) => {
        if (route.sourceId === ROOT && _.isNil(destination.entryPredicates)) {
          initialSceneId = destination.pathId
        }
        sceneRoutes.push({
          source: route.sourceId,
          target: destination.pathId,
          entryPredicates: destination.entryPredicates,
        })
      })
    })
    return { sceneRoutes, initialSceneId }
  }, [plan, selectedStoryId])

  useEffect(() => {
    setSelectedSceneId(initialSceneId)
  }, [initialSceneId])

  // simplify selection, try to find the right node regardless of type
  const onSelect = (id: string) => {
    if (storiesById[id] !== undefined) {
      setSelectedStoryId(id)
    }
    if (scenesById[id] !== undefined) {
      setSelectedSceneId(id)
    }
    if (tasksById[id] !== undefined) {
      setSelectedTaskId(id)
    }
  }

  return (
    <div>
      <FormTable className="u-bumperTop u-bumperBottom">
        <SelectField
          label="Story"
          isNative={true}
          onChange={(val) => setSelectedStoryId(val)}
          optionLabelPath="name"
          optionValuePath="id"
          value={selectedStoryId}
          options={plan.core_storyboard_plan.stories}
        />
        <SelectField
          label="Scene"
          isNative={true}
          onChange={(val) => setSelectedSceneId(val)}
          optionLabelPath="name"
          optionValuePath="id"
          value={selectedSceneId}
          options={plan.core_storyboard_plan.scenes}
        />
        <SelectField
          label="Task"
          isNative={true}
          onChange={(val) => setSelectedTaskId(val)}
          optionLabelPath="name"
          optionValuePath="id"
          value={selectedTaskId}
          options={plan.core_storyboard_plan.tasks}
        />
      </FormTable>
      <SplitPane defaultSize="66%">
        <SplitPane defaultSize="40%">
          <WorkflowStoryPanel plan={plan} selectedStoryId={selectedStoryId} onSelect={onSelect} />
          <WorkflowScenePanel
            plan={plan}
            selectedSceneId={selectedSceneId}
            onSelect={onSelect}
            sceneRoutes={sceneRoutes}
          />
        </SplitPane>
        <WorkflowTaskPanel plan={plan} selectedTaskId={selectedTaskId} />
      </SplitPane>
    </div>
  )
}
