import classNames from 'classnames'
import React from 'react'
import _, { mixin } from 'lodash'

import { Query } from 'shared-libs/models/query'
import { getDocumentSchemas } from 'shared-libs/models/utils'
import { SchemaIds } from 'shared-libs/models/schema'

import apis from 'browser/app/models/apis'

import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { QRCodeField } from 'browser/components/atomic-elements/molecules/fields/qrcode-field'
import { EntitySelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/entity-select-field'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'

import {
  JSONSchemaResolver,
} from 'shared-libs/resolvers/json-schema-resolver'

import {
  getOptionsFromSchema,
} from 'shared-libs/models/utils'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { linter } from '@codemirror/lint'

interface QrBuilderDocumentProps extends IBaseProps {

}

interface QrBuilderDocumentState {
  firm?: any
  bundle?: any
  docType?: any
  docTypeOptions?: any
  docTypeFieldOptions?: any
  propertyValues: any
  additionalValues: any
  targetAPI: string
}

enum PropertyType {
  BOOL = 'Boolean',
  STR = 'String',
  NUM = 'Number',
}

const ENTITY_SCHEMA_URI = '/1.0/entities/metadata/entity.json'

export class QrBuilderDocument extends React.Component<QrBuilderDocumentProps, QrBuilderDocumentState> {
  private resolver
  private firmPromise

  constructor(props) {
    super(props)

    this.state = {
      propertyValues: [],
      additionalValues: [],
      docTypeFieldOptions: [],
      targetAPI: '/actions/entity/create?jsonProps=',
    }

    this.resolver = new JSONSchemaResolver(apis)
  }

  public componentWillUnmount() {
    this.firmPromise?.cancel()
  }

  public setFirm = (firm) => {
    this.setState({ firm })
    this.updateBundleForFirm(firm)
  }

  public updateBundleForFirm = (firm) => {
    const bundleJson = '/1.0/entities/metadata/applicationBundle.json'

    const collection = new Query(apis)
      .setEntityType(bundleJson)
      .setFilters([
        {
          path: 'applicationBundle.firm',
          type: 'matchEdge',
          value: firm
        }
      ])
      .setSize(1)
      .getCollection()

    this.firmPromise?.cancel()
    this.firmPromise = collection.find().then(() => {
      const bundle = collection.result.children[0]
      this.setState({ bundle })
      this.updateDocTypesForBundle(bundle)
    })
  }

  public updateDocTypesForBundle = (bundle) => {
    const store = apis.getStore()
    const schemaIds = _.map(bundle.applicationBundle.schemas, (schema) => schema.entityId)
    store.getOrFetchRecords(schemaIds).then(() => {
      const options = getDocumentSchemas(store, bundle.applicationBundle.schemas, null).map((option) => {
        const firmName = option.owner?.firm?.displayName
        const firmLabel = firmName ? ` - ${firmName}`: undefined

        const label = `${option.displayName}${firmLabel} - ${option.uniqueId}`
        return new Proxy(option, {
          get(target, property) {
            if (property === '__label') {
              return label
            }

            return target[property]
          }
        })
      })
      this.setState({ docTypeOptions: options })
    })
  }

  private renderPropertyValueInput = (item) => {
    const { property, value } = item
    let  propertyType = property?.propertyType
    const onChange = (value) => { item.value = value; this.forceUpdate() }

    if (_.startsWith(propertyType, 'array-')) {
      propertyType = propertyType.split('-')[1]
    }
    if (propertyType === 'boolean') {
      return (
        <CheckboxField
          isHorizontalLayout={true}
          label='Field Value'
          onChange={onChange}
          value={value}
        />
      )
    } else if (propertyType === 'number' || propertyType === 'integer') {
      return (
        <div>
          <InputField
            isHorizontalLayout={true}
            label='Field Value'
            onChange={onChange}
            value={value}
          />
        </div>
      )
    } else if (propertyType === 'edge') {
      return (
        <EntitySelectField
          entityType={property.entityType}
          isCreatable={false}
          label={'Field Value'}
          onChange={onChange}
          showLinkIcon={false}
          value={value}
        />
      )
    } else if (propertyType === 'string') {
      // Check to see if there are enums for the string
      if (!_.isEmpty(property.enum)) {
        return (
          <SelectField
            isHorizontalLayout={true}
            label='Field Values'
            options={property.enum}
            onChange={onChange}
            value={value}
          />
        )
      }
    }
    return (
      <InputField
        label='Field Values'
        onChange={onChange}
        value={value}
      />
    )
  }

  public handleRenderProperty = (props) => {
    const { item } = props
    const { docTypeFieldOptions } = this.state

    return <div className="c-formTable">
      <SelectField
        label='Field'
        onChange={this.setProp(item, 'property', () => { item.value = undefined} ) }
        options={docTypeFieldOptions}
        optionLabelPath='label'
        optionValuePath=''
        value={item.property}
      />
      { this.renderPropertyValueInput(item) }
    </div>
  }

  public setProp = (obj: any, path: string, additionalWork?: () => void) => {
    return (value) => {
      _.set(obj, path, value)
      additionalWork && additionalWork()
      this.forceUpdate()
    }
  }

  public handleRenderBasicTypedProperty = (props) => {
    // TODO - this function is very similar to renderPropertyValueInput which itself is similar to the email trigger code
    // there are awkward differences that make it annoying to refactor right away, but there's enough copy-paste to be worth improving in the future
    const { item } = props

    let inputField = (
      <InputField
        isDisabled={true}
        isHorizontalLayout={true}
        label='Field Value'
        placeholder='Please select a property type'
      />
    )
    if (item.type === PropertyType.BOOL) {
      inputField = (
        <CheckboxField
          isHorizontalLayout={true}
          label='Field Value'
          onChange={this.setProp(item, 'value') }
          value={item.value}
        />
      )
    } else if (item.type === PropertyType.STR) {
      inputField = (
        <InputField
          isHorizontalLayout={true}
          label='Field Value'
          onChange={this.setProp(item, 'value') }
          value={item.value}
        />
      )
    } else if (item.type === PropertyType.NUM) {
      inputField = (
        <InputField
          isHorizontalLayout={true}
          label='Field Value'
          type='number'
          onChange={this.setProp(item, 'value') }
          value={item.value}
        />
      )
    }

    return <div className="c-formTable">
      <InputField
        isHorizontalLayout={true}
        label='Field Path'
        onChange={this.setProp(item, 'path') }
        value={item.path}
      />
      <SelectField
        isHorizontalLayout={true}
        label='Field Type'
        options={Object.keys(PropertyType).map((x) => ({ val: PropertyType[x] }))}
        onChange={this.setProp(item, 'type', () => { item.value = undefined} ) }
        value={item.type}
        optionValuePath='val'
        optionLabelPath='val'
      />
      {inputField}
    </div>
  }

  public handleChangePropertyValues = (propertyValues) => {
    this.setState({ propertyValues })
  }

  public handleChangeAdditionalValues = (additionalValues) => {
    this.setState({ additionalValues })
  }

  public getObjFromPropValues(propValues) {
    const ret = {}

    propValues.forEach((propVal) => {
      const { property, value, path } = propVal

      if (!path && _.isEmpty(property)) {
        return
      }

      _.set(ret, path || property.path, value)
    })

    return ret
  }

  public getDocLinkFromJson(docJson) {
    const { targetAPI } = this.state
    return `${window.location.origin}${targetAPI}${encodeURIComponent(docJson)}`
  }

  public getDocType() {
    const { docType } = this.state

    if (docType) {
      return docType
    }

    return apis.getStore().getRecord(SchemaIds.DOCUMENT)
  }

  public handleDocTypeChange = async (docType) => {
    this.setState({ docType })

    let docTypeFieldOptions = []
    try {
      const schemas = await this.resolver.resolveSchema(docType)
      _.forEach(schemas, (schema, key) => {
        if (key !== ENTITY_SCHEMA_URI) {
          docTypeFieldOptions = _.concat(docTypeFieldOptions, getOptionsFromSchema(schema, apis, this.resolver))
        }
      })
    } catch (err) {
      // stick to core properties
    }
    docTypeFieldOptions = _.concat(docTypeFieldOptions, getOptionsFromSchema(docType, apis, this.resolver))

    this.setState({ docTypeFieldOptions })
  }

  public getDocJsonFromState(jsonArgs) {
    const { propertyValues, additionalValues } = this.state
    const docType = this.getDocType()

    const docCreationJson = {
      docTypeIds: [docType.uniqueId],
      preFilledValues: {},
      ...this.getObjFromPropValues(additionalValues)
    }
    docCreationJson.preFilledValues[docType.uniqueId] = this.getObjFromPropValues(propertyValues)

    return JSON.stringify(docCreationJson, ...jsonArgs)
  }

  public copyToClipboard(link) {
    navigator.clipboard.writeText(link)
    Toast.show({
      message: 'Copied deeplink to clipboard',
      position: Position.BOTTOM_RIGHT,
    })
  }

  render() {
    const { firm, docType, docTypeOptions, propertyValues, additionalValues, targetAPI } = this.state
    const createJson = this.getDocJsonFromState([null, 2])
    const createLink = this.getDocLinkFromJson(this.getDocJsonFromState([]))

    return (
      <>
        <div className='u-bumperTop--lg u-bumperLeft--lg col-xs-3'>
          <InputField
            isHorizontalLayout={true}
            label='Target API'
            onChange={(targetAPI) => { this.setState({ targetAPI }) } }
            value={targetAPI}
          />
          <EntitySelectField
            entityType='/1.0/entities/metadata/firm.json'
            label='Firm'
            onChange={this.setFirm}
            value={firm}
          />
          <SelectField
            label='Document Type'
            options={docTypeOptions}
            onChange={this.handleDocTypeChange}
            value={docType}
            optionValuePath=''
            optionLabelPath='__label'
          />
          <Section title="Deeplink Properties">
            <List
              onChange={this.handleChangeAdditionalValues}
              renderListItem={this.handleRenderBasicTypedProperty}
              showAddButton={true}
              value={additionalValues}
            />
          </Section>
          <Section title="Document Properties">
            <List
              onChange={this.handleChangePropertyValues}
              renderListItem={this.handleRenderProperty}
              showAddButton={true}
              value={propertyValues}
            />
          </Section>
        </div>
        <div className='u-bumperTop--lg u-bumperLeft--lg col-xs-3'>
          <QRCodeField qrValue={createLink} qrSize={256} includeMargin={true} />
          {createLink}
          <br/>
          <Button
            buttonText='Copy to clipboard'
            onClick={() => { this.copyToClipboard(createLink) }}
            className='u-bumperTop--lg'
          />
        </div>
        <div className='u-bumperTop--lg u-bumperLeft--lg col-xs-6'>
          <CodeMirrorInput
            value={createJson}
            extensions={[json()]}
            onChange={() => {}}
            readOnly={true}
            />
        </div>
      </>
    )
  }
}
