import zlib from 'zlib'
import queryString from 'query-string'
import { Clipboard } from '@blueprintjs/table'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'

export function getShareableToolsURL(
  payload: any,
  additionalQueryParams: Record<string, string> = {}
): URL {
  const url = new URL(window.location.origin + window.location.pathname)
  for (const key in additionalQueryParams) {
    const value = additionalQueryParams[key]
    if (value != null) {
      url.searchParams.set(key, additionalQueryParams[key])
    }
  }
  const payloadJson = JSON.stringify(payload)
  const payloadZippedAndBase64Encoded = zlib.deflateSync(payloadJson).toString('base64')
  url.searchParams.set('jsonProps', payloadZippedAndBase64Encoded)
  return url
}

export function unpackToolsURL() {
  const { jsonProps, ...rest } = queryString.parse(window.location.search)
  if (jsonProps) {
    const payloadUnzipped = zlib.inflateSync(Buffer.from(jsonProps, 'base64')).toString()
    const json = JSON.parse(payloadUnzipped)
    return { ...json, ...rest }
  }
  return rest
}

export function copyShareableToolsURL(url: string, message?: string) {
  Clipboard.copyString(url)
  Toast.show({
    message: message || `Url copied to clipboard`,
    position: Position.BOTTOM_RIGHT,
    timeout: 5000,
  })
}

/**
 * @returns A string representation of any JS value, useful for displaying
 * results of formula eval that react would otherwise render poorly.
 */
export function serializeAsString(result: any): string {
  if (result === undefined) {
    return 'undefined'
  } else if (typeof result === 'function') {
    return 'function'
  } else if (typeof result === 'object') {
    return JSON.stringify(result, null, 2)
  }
  return JSON.stringify(result)
}
