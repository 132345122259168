"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.registerFirebaseServiceWorker = void 0;
var WORKER_PATH = "/firebase-messaging-sw.js?version=".concat(__GITHASH__);
var registerFirebaseServiceWorker = exports.registerFirebaseServiceWorker = function registerFirebaseServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(WORKER_PATH).then(function (registration) {
      console.log('✅ Registration success: ', registration.scope);
      return registration.scope;
    })["catch"](function (err) {
      console.log('🚫 Registration failed: ', err);
      return err;
    });
  }
};