import React from 'react'
import _ from 'lodash'

import { NavigationRoute } from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { WorkflowNode } from './workflow-node'

import 'browser/app/pages/app/tools/workflow/_workflow-nav-route.scss'

interface IWorkflowNavRouteProps {
  route: NavigationRoute
  onSelect: (id: string) => void
}

export const WorkflowNavRoute = (props: IWorkflowNavRouteProps) => {
  const { route, onSelect } = props
  return (
    <WorkflowNode onClick={route.pathId ? () => onSelect(route.pathId) : undefined}>
      <h5>{route.name}</h5>
      <i>{route.pathId}</i>
      {!_.isEmpty(route.entryPredicates) && (
        <>
          <p>Entry predicates:</p>
          <ul className="workflow-nav-route__list">
            {_.map(route.entryPredicates, (pred) => (
              <li>
                [{pred.name}] = {pred.expression}
              </li>
            ))}
          </ul>
        </>
      )}
      {!_.isEmpty(route.exitPredicates) && (
        <>
          <p>Exit predicates:</p>
          <ul className="workflow-nav-route__list">
            {_.map(route.exitPredicates, (pred) => (
              <li>
                [{pred.name}] = {pred.expression}
              </li>
            ))}
          </ul>
        </>
      )}
    </WorkflowNode>
  )
}
