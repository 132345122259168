
import apis from 'browser/app/models/apis'
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'


interface IMotiveRegisterProps extends IBaseProps {
  location: any
}

interface IMotiveRegisterState {
  isLoading: boolean
}

export class MotiveRegister extends React.Component<IMotiveRegisterProps, IMotiveRegisterState> {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  public render() {
    const { location } = this.props

    const code = new URLSearchParams(location.search).get('code');

    if (code) {
      console.log('registering motive')
      apis.registerMotive(code)
      console.log('registered motive')
      return (
        <div>
          <h1>motive registration complete!</h1>
        </div>
      );
    } else {
      return
      <div>
        <h1>no code passed!</h1>
      </div>
    }
  }



};

export default MotiveRegister;