import _ from 'lodash'
import React from 'react'

import 'browser/app/pages/app/tools/_tools.scss'
import apis from 'browser/app/models/apis'
import { AJVSchemaValidator } from 'shared-libs/components/entity/ajv-validator'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { JSONSchemaResolver } from 'shared-libs/resolvers/json-schema-resolver'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { copyShareableToolsURL, getShareableToolsURL, unpackToolsURL } from '../utils'
import { ErrorMessageContainer, ErrorText, ErrorTitle } from '../errors'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { linter } from '@codemirror/lint'

type IValidationToolProps = IBaseProps

interface IValidationToolState {
  schemaString: string
  documentString: string
  errors?: any[]
}

const PLACEHOLDER_SCHEMA = {
  type: 'object',
  properties: {
    str: {
      type: 'string',
      errorMessage: 'uh oh',
    },
    dt: {
      $ref: '/1.0/entities/metadata/entity.json#/definitions/dateTimeWithTimezone',
    },
  },
}

const PLACEHOLDER_DOCUMENT = {
  str: 5,
  dt: {
    dateTime: '',
  },
}

export class ValidationTool extends React.Component<IValidationToolProps, IValidationToolState> {

  private validator = new AJVSchemaValidator(new JSONSchemaResolver(apis))

  constructor(props) {
    super(props)
    const jsonProps = unpackToolsURL()

    this.state = {
      schemaString: jsonProps.schemaString || JSON.stringify(PLACEHOLDER_SCHEMA, null, 2),
      documentString: jsonProps.documentString || JSON.stringify(PLACEHOLDER_DOCUMENT, null, 2),
    }
  }

  componentDidMount() {
    this.validateDebounced()
  }

  render() {
    return (
      <div className='grid-block vertical'>
        <CardHeader className='c-cardHeader--nonSeparating'>
          <CardHeaderItem
            className='c-cardHeader-item--grow c-cardHeader-item--center'
            title='Validator Sandbox'
          />
          <Button buttonText="Copy Shareable Link" onClick={this.handleShare}/>
        </CardHeader>
        {this.renderCard()}
      </div>
    )
  }

  private renderCard() {
    const { schemaString, documentString } = this.state
    return (
      <div className="c-tools grid-block">
        <div className="grid-content">
          <div className="row">
            <div className="col-xs-6">
              <Section title="Schema JSON">
                <CodeMirrorInput
                  maxHeight='600px' // TODO: remove, and add resize capability
                  value={schemaString}
                  extensions={[json(), linter(jsonParseLinter())]}
                  onChange={(schemaString) => {
                    this.setState({ schemaString }, this.validateDebounced)
                  }}
                />
              </Section>
            </div>
            <div className="col-xs-6">
              <Section title="Document JSON">
                <CodeMirrorInput
                  maxHeight='600px' // TODO: remove, and add resize capability
                  value={documentString}
                  extensions={[json(), linter(jsonParseLinter())]}
                  onChange={(documentString) => {
                    this.setState({ documentString }, this.validateDebounced)
                  }}
                />
              </Section>
            </div>
          </div>
          {this.renderErrors()}
        </div>
      </div>
    )
  }

  private renderErrors() {
    const { errors } = this.state
    if (_.isEmpty(errors)) {
      return null
    }
    return (
      <div className='row'>
        <div className='col-xs-12'>
            {errors.map((error, idx) => this.renderError(error, idx))}
        </div>
      </div>
    )
  }

  private renderError(error, idx) {
    switch (error.type) {
      case 'validation':
        return (
          <ErrorMessageContainer key={idx}>
            <ErrorTitle>
              {`at ${error.path || '<top-level>'}`}
            </ErrorTitle>
            {_.map(error.errors, (e, idx) => (
              <ErrorText key={idx} className="pl2">
                {e}
              </ErrorText>
            ))}
          </ErrorMessageContainer>
        )
      default:
        return (
          <ErrorMessageContainer>
            <ErrorTitle>
              {`${error.type} error:`}
            </ErrorTitle>
            <ErrorText>
              {error.message}
            </ErrorText>
          </ErrorMessageContainer>
        )
    }
  }

  private handleShare = () => {
    const { schemaString, documentString } = this.state
    const url = getShareableToolsURL({
      schemaString,
      documentString,
    }).toString()
    copyShareableToolsURL(url)
  }

  private validate = async () => {
    const { schemaString, documentString } = this.state

    let schema, document
    const errors = []
    try {
      schema = JSON.parse(schemaString)
    } catch (e) {
      errors.push({
        type: 'schema',
        message: e.message || e,
      })
    }
    try {
      document = JSON.parse(documentString)
    } catch (e) {
      errors.push({
        type: 'document',
        message: e.message || e,
      })
    }
    if (_.isObject(schema)) {
      let validationErrors
      try {
        validationErrors = await this.validator.validate(schema, document, {
          synchronous: true,
        })
      } catch (e) {
        errors.push({
          type: 'schema',
          message: e.message || e,
        })
      }
      validationErrors = _.map(validationErrors, (error) => ({
        type: 'validation',
        path: _.join(error.path, '.'),
        errors: error.errors,
      }))
      errors.push(...validationErrors)
    }
    this.setState({ errors })
  }

  private validateDebounced = _.debounce(this.validate.bind(this), 350)

}
