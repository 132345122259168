import React from 'react'
import { Entity } from 'shared-libs/models/entity'
import { InsightsCardBody } from './insights-card-body'
import { InsightsCardHeader } from './insights-card-header'
import './_insights-card.scss'

interface IInsightsCardProps {
  entity: Entity
  children?: React.ReactNode
  onSelect: () => void
  isSelected: boolean
  alwaysOpen?: boolean
  badgeText?: string
}

export const InsightsCard = (props: IInsightsCardProps) => {
  const { badgeText, children, entity, onSelect, isSelected, alwaysOpen } = props
  const showCardBody = children && (isSelected || alwaysOpen)
  return (
    <div className="insights-card">
      <InsightsCardHeader
        entity={entity}
        isSelected={isSelected}
        onClick={onSelect}
        badgeText={badgeText}
      />
      {showCardBody && <InsightsCardBody>{children}</InsightsCardBody>}
    </div>
  )
}
