import React from 'react'
import _ from 'lodash'
import { StoryboardPlanEntity } from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { WorkflowNavEntry } from './workflow-nav-entry'
import { WorkflowPanel } from './workflow-panel'

interface IWorkflowStoryPanelProps {
  plan: StoryboardPlanEntity
  selectedStoryId?: string
  onSelect: (id: string) => void
}

export const WorkflowStoryPanel = (props: IWorkflowStoryPanelProps) => {
  const { onSelect, plan, selectedStoryId } = props
  const story = _.find(plan.core_storyboard_plan.stories, { id: selectedStoryId })

  if (!story) {
    return <p>No story selected</p>
  }

  return (
    <WorkflowPanel>
      <h3>{story.name}</h3>
      <i>{story.id}</i>
      {/* permissions / settings / other top level story data would go here */}
      {_.map(story.sceneRoutes, (route) => (
        <WorkflowNavEntry entry={route} onSelect={onSelect} />
      ))}
    </WorkflowPanel>
  )
}
