import Cookie from 'js-cookie'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
// tslint:disable-next-line:max-line-length
import { ClearQBOMappingsModal } from 'browser/components/atomic-elements/organisms/clear-qbo-mappings-modal/clear-qbo-mappings-modal'

// tslint:disable-next-line:no-var-requires
const QuickBooksLogo = require('images/integrations/QuickBooks-logo.svg')

interface IQuickBooksSettingsProps extends IBaseProps {
  settings: Settings
}

interface IQuickBooksSettingsState {
  showZeroState: boolean
}

export class QuickBooksSettings extends React.Component<IQuickBooksSettingsProps, IQuickBooksSettingsState> {

  private timerId: any
  private qboWindow: any

  constructor(props) {
    super(props)
    this.state = {
      showZeroState: true,
    }
  }

  public componentDidMount() {
    const { settings } = this.props
    const qboSettings = settings.getQuickBooksOnlineSettings()
    if (!qboSettings) {
      return
    }
    const isInstalled = qboSettings.get('settings.isInstalled', false)
    this.setState({
      showZeroState: !isInstalled,
    })
  }

  public componentWillUnmount() {
    clearInterval(this.timerId)
  }

  public render() {
    // TODO(louis): Fix the bumpers in the second column when we have a real
    // set of forms ready.
    return (
      <div className='grid-block vertical'>
        <CardHeader className='c-cardHeader--nonSeparating u-borderBottom'>
          <CardHeaderItem
            className='c-cardHeader-item--grow c-cardHeader-item--center'
            title='Intuit QuickBooks'
          />
        </CardHeader>
        {this.renderQuickbooksCard()}
      </div>
    )
  }

  private renderQuickbooksCard = () => {
    const { showZeroState } = this.state
    return (
      <div className='grid-block'>
        <div className='grid-content'>
          <Section title='Accounting'>
            <div className='row'>
              <div className='col-xs-6'>
                <p className='mb3'>Get hours back every week by automatically
                importing Vector orders into your accounting software.
                By eliminating manual entries, you’ll always have an
                accurate, up-to-date picture of your business’s finances.</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-6'>
                <div className='u-innerBumper--xl u-border u-flex'>
                  <img
                    className='c-vendorLogo'
                    src={QuickBooksLogo}
                  />
                  <div className='u-bumperLeft'>
                    <h5 className='f5 mb2'>Intuit QuickBooks</h5>
                    <p className='mb3'>Run your entire business with Intuit
                    QuickBooks Online. Track expenses, send invoices, pay
                    employees, and more.</p>
                    <div className='u-bumperTop'>
                      {showZeroState && this.renderConnectButtons()}
                      {!showZeroState && this.renderDisconnectButtons()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </div>
    )
  }

  private renderConnectButtons() {
    return (
      <Button
        className='c-button--secondary'
        onClick={this.handleOnConnectClicked}
      >
        Connect QuickBooks
      </Button>
    )
  }

  private renderDisconnectButtons() {
    return (
      <div>
        <Button
          className={Classes.INTENT_DANGER}
          onClick={this.handleOnDisconnectClicked}
        >
          Disconnect From Quickbooks
        </Button>
      </div>
    )
  }

  private handleOnConnectClicked = () => {
    const authToken = Cookie.get('Authorization').replace('Bearer ', '')
    // tslint:disable-next-line:max-line-length
    const url = apis.getAPIHost() + `/1.0/entities/actions/accounting/quickbooks/auth/requestToken?access_token=${authToken}`
    const t = (screen.height - 738) / 4
    const l = (screen.width - 848) / 2
    const windowFeatures = 'height=738,width=848,top=' + t + ',left=' + l
    // tslint:disable-next-line:max-line-lengt  h
    this.qboWindow = window.open(url, '_blank', windowFeatures)
    this.timerId = setInterval(this.checkIfQBOEnabled, 1000)
  }

  private handleOnDisconnectClicked = () => {
    const { settings } = this.props
    ClearQBOMappingsModal.open({settings, onChange: this.changeToZeroState})
  }

  private changeToZeroState = () => {
    this.setState({ showZeroState: true })
  }

  private handleClearMappings = () => {
    apis.clearQuickBooksOnlineSync()
  }

  private checkIfQBOEnabled = () => {
    const { settings } = this.props
    if (!this.qboWindow.closed) {
      return
    }
    settings.fetchSettings().then(() => {
      const qboSettings = settings.getQuickBooksOnlineSettings()
      const isInstalled = qboSettings.get('settings.isInstalled', false)
      this.setState({
        showZeroState: !isInstalled,
      })
      this.forceUpdate()
    })
    clearInterval(this.timerId)
  }
}
