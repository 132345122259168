import apis from 'browser/app/models/apis'
import { browserHistory } from 'browser/history'
import _ from 'lodash'
import React from 'react'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

interface IDatConfigurationModalProps extends IBaseProps {
  onUpdate: () => void
  onClose: () => void
}

interface IDatConfigurationModalStates {
  isConnecting: boolean
  errorText: string
  password: string
  username: string
}

export class DatConfigurationModal
  extends React.Component<IDatConfigurationModalProps, IDatConfigurationModalStates> {

  public static open(props) {
    return OverlayManager.openOverlayElement(
      <Modal>
        <DatConfigurationModal {...props} />
      </Modal>,
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      errorText: null,
      isConnecting: false,
      password: '',
      username: '',
    }
  }

  public render() {
    const { onClose } = this.props
    const { isConnecting } = this.state

    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <div>
            <div
              className='c-modalHeader'
              data-debug-id='member-configuration'
            >
              <h4 className='c-modal-title'>Connect to DAT</h4>
            </div>

            <div className='c-modalBody inviteModalBody'>
              <InputField
                autoFocus={true}
                isHorizontalLayout={true}

                label='DAT Username'
                onChange={(value) => this.handleTextChange('username', value)}
                value={this.state.username}
              />
              <InputField
                autoFocus={true}
                isHorizontalLayout={true}

                label='DAT Password'
                onChange={(value) => this.handleTextChange('password', value)}
                type='password'
                value={this.state.password}
              />
              {this.renderErrors()}
            </div>
            <Footer
              isVisible={true}
              cancelButtonText='Cancel'
              isCancelButtonDisabled={isConnecting}
              isPrimaryButtonLoading={isConnecting}
              primaryButtonText={'Connect'}
              onCancelButtonClick={onClose}
              onPrimaryButtonClick={() => this.handleConfirm(settings)}
            />
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private handleConfirm = (settings) => {
    const { onClose } = this.props
    const { username, password } = this.state

    this.setState({ isConnecting: true, errorText: null })
    apis.datCredentialConnect({
      password,
      username,
    }).then(() => {
      return apis.datCapabilitiesLookup()
    }).then(() => {
      // reload settings so that we have updated settings
      return settings.fetchSettings()
    }).then(() => {
      const user = settings.getUser()
      browserHistory.push({
        pathname: '/settings/view/99d6a64b-b208-42b0-a7e1-4849f4e42d99',
        search: `?entityId=${user.uniqueId}`,
      })
      onClose()
    }).catch(() => {
      this.setState({ errorText: 'Invalid username or password.' })
    }).finally(() => this.setState({ isConnecting: false }))
  }

  private handleTextChange = (property, textValue) => {
    const changedProperty = {}
    changedProperty[property] = textValue
    this.setState(changedProperty)
  }

  private renderErrors() {
    if (!_.isEmpty(this.state.errorText)) {
      return (
        <ErrorBlock
          errorText={this.state.errorText}
        />
      )
    }
  }

}
