import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Formatter } from 'shared-libs/helpers/formatter'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { CommentsList } from './comments-list'
import { SharesList } from './shares-list'

interface ICardTemplateProps extends IBaseProps {
  authorName: string
  authorEmail: string
  activity: object
  hasFooter: boolean
  renderCardHeaderContent: () => void
  renderCardBodyContent: () => void
  onMouseEnter?: (value: any) => void
  onMouseLeave?: (value: any) => void
}

export class CardTemplate extends React.Component<ICardTemplateProps, any> {

  public static defaultProps: Partial<ICardTemplateProps> = {
    activity: {},
    authorEmail: '',
    authorName: '',
    hasFooter: false,
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    renderCardBodyContent: () => {},
    renderCardHeaderContent: () => {},
  }

  constructor(props) {
    super(props)
    const activity = props.activity
    this.state = {
      activity,
      showCommentList: activity.comments && activity.comments.length > 0,
      showShareToList: false,
    }
    this.handleShowCommentList = this.handleShowCommentList.bind(this)
    this.handleShowShareToList = this.handleShowShareToList.bind(this)
    this.handleNewComment = this.handleNewComment.bind(this)
    this.handleNewSharedWith = this.handleNewSharedWith.bind(this)
  }

  public render() {
    const cardFooter = this.props.hasFooter ? this.renderCardFooter() : null
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <div
            className='c-card paper paper--zDepth-1 activityCard'
            data-debug-id='activity-card'
            onMouseEnter={() => this.props.onMouseEnter(this.props.activity)}
            onMouseLeave={() => this.props.onMouseLeave(this.props.activity)}
          >
            {this.renderCardHeader(settings)}
            {this.props.renderCardBodyContent()}
            {cardFooter}
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private handleNewComment() {
    // NOTE: we want to force a re-render so that num comment is right
    this.forceUpdate()
  }

  private handleNewSharedWith() {
    // NOTE: we want to force a re-render so that num shared with is right
    this.forceUpdate()
  }

  private handleShowCommentList() {
    this.setState({
      showCommentList: true,
      showShareToList: false,
    })
  }

  private handleShowShareToList() {
    this.setState({
      showCommentList: false,
      showShareToList: true,
    })
  }

  private renderPostedTimeAndLocation(settings) {
    // TODO(Peter): make a model for activity
    const showGpsAsDegrees = settings.getSettingsProperty('generalSettings', 'showGpsAsDegrees')

    const activity = this.state.activity
    const entity = activity.document
    const postedTime = (entity && entity.document)
      ? Formatter.formatDateTime(entity.document.uploadDate)
      : Formatter.formatDateTime(activity.posted_time)
    const address = _.get(entity, 'document.address')

    if (!address) {
      return (
        <HelpBlock>
          {postedTime}
        </HelpBlock>
      )
    }
    const geolocation = _.get(address, 'geolocation')
    return (
      <HelpBlock>
        {postedTime}
        <span className='b'> &middot; </span>
        <a
          className='cardHeader-location'
          href={Formatter.formatGeolocationAsGoogleMapUrl(geolocation)}
          target='_blank' rel='noopener noreferrer'
        >
          <Icon icon={IconNames.MAP_MARKER} /> {Formatter.formatAddress(address, showGpsAsDegrees)}
        </a>
      </HelpBlock>
    )
  }

  private renderCardHeader(settings) {
    const { authorName, authorEmail, renderCardHeaderContent } = this.props
    return (
      <div className='grid-block c-cardHeader c-cardHeader--paddedVertically c-cardHeader--alignTop'>
        <div className='grid-block shrink c-cardHeader-item c-cardHeader-item--smallMargin'>
          <Avatar
            name={authorName}
            email={authorEmail}
          />
        </div>
        <div className='grid-block c-cardHeader-item c-cardHeader-item--grow c-cardHeader-item--smallMargin'>
          {renderCardHeaderContent()}
          {this.renderPostedTimeAndLocation(settings)}
        </div>
      </div>
    )
  }

  private renderCardFooter() {
    const { activity } = this.state
    const entity = activity.document
    return (
      <div className='grid-block vertical c-cardFooter c-cardFooter--collapse'>
        <div className='grid-block shrink'>
          <div className='grid-block'>
            <div className='grid-content collapse'>
              <a
                className={classNames(
                  'cardLink activityCard-commentsLink', {
                    'is-active': this.state.showCommentList,
                    'is-inactive': !this.state.showCommentList && this.state.showShareToList,
                  })}
                data-debug-id='activity-card-comments-tab'
                onClick={this.handleShowCommentList}
              >
                Comment ({activity.comments.length})
              </a>
            </div>
          </div>
          <div className='grid-block'>
            <div className='grid-content collapse'>
              <a
                className={classNames(
                  'cardLink cardLink--last activityCard-shareLink', {
                    'is-active': this.state.showShareToList,
                    'is-inactive': !this.state.showShareToList && this.state.showCommentList,
                  })}
                data-debug-id='activity-card-share-tab'
                onClick={this.handleShowShareToList}
              >
                Recipients ({activity.shared_with.length})
              </a>
            </div>
          </div>
        </div>
        <div className={classNames(
          'grid-block vertical shrink activityCard-commentPanel', {
            'is-visible': this.state.showCommentList,
          })}
        >
          <CommentsList
            activity={activity}
            entity={entity}
            onNewComment={this.handleNewComment}
          />
        </div>
        <div className={classNames(
          'grid-block vertical shrink activityCard-sharePanel', {
            'is-visible': this.state.showShareToList,
          })}
        >
          <SharesList
            activity={activity}
            entity={entity}
            onNewSharedWith={this.handleNewSharedWith}
          />
        </div>
      </div>
    )
  }
}
