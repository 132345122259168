import React from 'react'
import _ from 'lodash'
import { StoryboardPlanEntity } from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { JSONEditor } from 'browser/components/atomic-elements/organisms/internal-tools/json-editor'
import { WorkflowPanel } from './workflow-panel'

interface IWorkflowTaskPanelProps {
  plan: StoryboardPlanEntity
  selectedTaskId?: string
}

export const WorkflowTaskPanel = (props: IWorkflowTaskPanelProps) => {
  const { plan, selectedTaskId } = props
  const task = _.find(plan.core_storyboard_plan.tasks, { id: selectedTaskId })

  if (!task) {
    return <p>No task selected</p>
  }

  return (
    <WorkflowPanel>
      <h3>{task.name || '<the task with no name>'}</h3>
      <i>
        {task.id} ({task.actionType})
      </i>
      {task.uiView && (
        <JSONEditor
          entity={{ content: task.uiView, setContent: () => null } as any}
          onChange={() => null}
        />
      )}
    </WorkflowPanel>
  )
}
