declare let window: any

import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import { findDOMNode } from 'react-dom'
import ReactDOM from 'react-dom'
import { Classes } from '@blueprintjs/core'

import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { ensureNotOutOfBounds, getPositionX, getPositionY, getScrollParents, isElementCoveringTarget } from './utils'

const KEYCODES = {
  ESCAPE: 27,
}

export interface IPortalProps {
  automaticAdjustOffset?: boolean
  container?: string
  className?: string
  closeOnEsc?: boolean
  closeOnOutsideClick?: boolean
  closeOnPortalClick?: boolean
  closeOnTetherTargetClick?: boolean
  isEnabled?: boolean
  onClose?: () => void
  onAdjustOffset?: (offset: any) => void
  onOpen?: () => void
  openOnClick?: boolean
  openOnFocus?: boolean
  openOnHover?: boolean
  style?: object
  tetherOptions?: any
  tetherTarget?: any
}

interface IPortalState {
  isOpened: boolean
}

export class Portal extends React.Component<IPortalProps, IPortalState> {
  public static defaultProps: IPortalProps = {
    closeOnOutsideClick: true,
    closeOnPortalClick: false,
    closeOnTetherTargetClick: true,
    container: '[data-reactroot]',
    isEnabled: true,
    onAdjustOffset: () => false,
    onClose: _.noop,
    onOpen: _.noop,
    openOnClick: true,
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom left',
    },
  }

  private overlay: Element
  private scrollParents: any[]
  private timerId: any

  constructor(props) {
    super(props)
    this.overlay = null
    this.state = {
      isOpened: false,
    }
  }

  public componentDidMount() {
    const $container = $(this.props.container)
    $container.resize(this.handleUpdateTetherPosition)

    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeydown)
    }
    if (this.props.closeOnPortalClick) {
      document.addEventListener('click', this.handleOnPortalClick)
    }
    if (this.props.closeOnOutsideClick) {
      document.addEventListener('click', this.handleOutsideMouseClick)
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps) {
    // portal handles its own 'is open' state
    if (!this.state.isOpened) {
      return
    }
    if (newProps.isEnabled) {
      // call render to update portal with new props
      this.renderPortal(newProps)
    } else {
      this.closePortal()
    }
  }

  public componentWillUnmount() {
    const $container = $(this.props.container)
    $container.unbind('resize')

    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeydown)
    }
    if (this.props.closeOnPortalClick) {
      document.removeEventListener('click', this.handleOnPortalClick)
    }
    if (this.props.closeOnOutsideClick) {
      document.removeEventListener('click', this.handleOutsideMouseClick)
    }
    this.closePortal()
    clearTimeout(this.timerId)
  }

  public render() {
    const { openOnClick, openOnFocus, openOnHover, tetherTarget } = this.props
    if (!tetherTarget) {
      return null
    }
    const className = classNames(tetherTarget.props.className, {
      [Classes.ACTIVE]: this.state.isOpened,
    })
    return React.cloneElement(tetherTarget, {
      className,
      onBlur: openOnFocus ? this.handleBlur : undefined,
      onClick: openOnClick ? this.handleClick : undefined,
      onFocus: openOnFocus ? this.handleOpenPortal : undefined,
      onMouseEnter: openOnHover ? this.handleOpenPortal : undefined,
      onMouseLeave: openOnHover ? this.handleClosePortal : undefined,
    })
  }

  /****************************************************************************/
  // Public Functions
  /****************************************************************************/

  public isOpened() {
    return this.state.isOpened
  }

  public openPortal(props = this.props) {
    if (this.state.isOpened || !this.props.isEnabled) {
      return
    }
    this.setState({ isOpened: true }, () => {
      this.renderPortal(props)
      this.props.onOpen()
    })
  }

  public closePortal() {
    if (!this.state.isOpened) {
      return
    }
    OverlayManager.closeOverlay(this.overlay)
    // remove scroll listeners
    _.forEach(this.scrollParents, (parent) => {
      parent.removeEventListener('scroll', this.handleUpdateTetherPosition)
    })
    // reset variables and also notify callback
    this.setState({ isOpened: false }, () => {
      this.overlay = null
      this.props.onClose()
    })
  }

  public updateTetherPosition() {
    this.handleUpdateTetherPosition()
  }

  /****************************************************************************/
  // Private API
  /****************************************************************************/

  private handleBlur = (event) => {
    if (!this.state.isOpened) {
      return
    }
    // if click is within portal don't blur. RelatedTarget tells us what caused
    // the blur. https://www.w3schools.com/jsref/event_focus_relatedtarget.asp
    const root = findDOMNode(this.overlay)
    const isClickWithinPortal = $.contains(root, event.relatedTarget)
    if (!isClickWithinPortal) {
      this.closePortal()
    }
  }

  private handleClick = () => {
    if (this.state.isOpened) {
      if (this.props.closeOnTetherTargetClick) {
        this.closePortal()
      }
    } else {
      // We want to run in the next run loop to give the other popover a chance
      // to detect outside mouse click and close. Otherwise they will fail the
      // isTopOfStack check if we open right away
      this.timerId = setTimeout(() => this.openPortal(), 0)
    }
  }

  private handleKeydown = (e) => {
    if (e.keyCode === KEYCODES.ESCAPE && this.state.isOpened) {
      this.closePortal()
    }
  }

  private handleOpenPortal = (event) => {
    if (!this.state.isOpened) {
      this.openPortal()
    }
  }

  private handleClosePortal = (event) => {
    if (this.state.isOpened) {
      this.closePortal()
    }
  }

  private handleOnPortalClick = (event) => {
    // Ignore the click if the popover is not open
    if (!this.state.isOpened) {
      return
    }
    const root = findDOMNode(this.overlay)
    const isClickWithinPortal = root.contains(event.target)
    if (isClickWithinPortal) {
      this.closePortal()
    }
  }

  private handleOutsideMouseClick = (event) => {
    // NOTE: we have to use $.contains here because IE11 does not support
    // node.contains
    const { closeOnPortalClick, tetherTarget } = this.props
    // Ignore the click if the popover is not open
    if (!this.state.isOpened) {
      return
    }
    // Ignore the click if popover is not isTopOfStack
    const isTopOfStack = OverlayManager.isTopOfStack(this.overlay)
    if (!isTopOfStack) {
      return
    }
    // If target does not exists e.g. someone click on the options of a select
    // then ignore this
    const targetExists = $.contains(document, event.target)
    if (!targetExists) {
      return
    }
    // Ignore body click if this comes from tether target
    if (tetherTarget) {
      const node = ReactDOM.findDOMNode(this)
      if ($.contains(node, event.target)) {
        return
      }
    }
    // Ignore the click if it is within the portal
    const root = findDOMNode(this.overlay)
    const isClickWithinPortal = $.contains(root, event.target)
    if (isClickWithinPortal && !closeOnPortalClick) {
      return
    }
    event.stopPropagation()
    this.closePortal()
  }

  private handleUpdateTetherPosition = () => {
    if (!this.state.isOpened) {
      return
    }
    const { attachment, targetAttachment } = this.props.tetherOptions
    let { offset } = this.props.tetherOptions
    offset = offset || { top: 0, left: 0 }

    const $container = $(this.props.container)
    const $overlay = $(this.overlay)
    const $target = $(ReactDOM.findDOMNode(this))

    const overlayOffset = $overlay.offset() || { left: 0, top: 0 }
    const overlayHeight = $overlay.outerHeight()
    const overlayWidth = $overlay.outerWidth()
    const overlayTopY = 0
    const overlayBottomY = -overlayHeight
    const overlayLeftX = 0
    const overlayRightX = -overlayWidth

    const targetOffset = $target.offset() || { left: 0, top: 0 }
    // This is necessary if target is zoomed or has scale transform
    const boundingRect = $target.get(0).getBoundingClientRect()
    const targetHeight = boundingRect.height
    const targetWidth = boundingRect.width
    const targetTopY = targetOffset.top
    const targetBottomY = targetOffset.top + targetHeight
    const targetLeftX = targetOffset.left
    const targetRightX = targetOffset.left + targetWidth

    const overlayPositions = attachment.split(/\s+/)
    const targetPositions = targetAttachment.split(/\s+/)
    let targetY = getPositionY(targetPositions[0], targetTopY, targetBottomY)
    const targetX = getPositionX(targetPositions[1], targetLeftX, targetRightX)
    let overlayY = getPositionY(overlayPositions[0], overlayTopY, overlayBottomY)
    const overlayX = getPositionX(overlayPositions[1], overlayLeftX, overlayRightX)

    let pos = {
      left: targetX + overlayX + offset.left,
      top: targetY + overlayY + offset.top,
    }

    // Not that the container is sometime height: 100% and height: auto on the
    // activity feed tool. Things might appear messed up when the height is
    // height: auto and there's nothing to fill the space.
    let result: any = ensureNotOutOfBounds($container, $overlay, pos, 5)
    pos = { top: pos.top + result.offsetY, left: pos.left + result.offsetX }

    // if overlay is covering target, we are going to flip the Y axis
    // TODO(Peter): make this more flexible
    if (isElementCoveringTarget($target, $overlay, pos)) {
      targetY = getPositionY(targetPositions[0], targetTopY, targetBottomY, true)
      overlayY = getPositionY(overlayPositions[0], overlayTopY, overlayBottomY, true)
      pos = { top: targetY + overlayY, left: targetX + overlayX }
      result = ensureNotOutOfBounds($container, $overlay, pos, 5)
      pos = { top: pos.top + result.offsetY, left: pos.left + result.offsetX }
    }

    $overlay.offset(pos)
  }

  private renderPortal(props) {
    // if we are updating.
    if (this.overlay) {
      OverlayManager.updateOverlayElementWithParent(this, this.overlay, props.children,
        this.handleUpdateTetherPosition)
      return
    }

    this.overlay = OverlayManager.openOverlayElementWithParent(this, props.children,
      this.handleUpdateTetherPosition)
    const targetNode = ReactDOM.findDOMNode(this)
    $(this.overlay).css('position', 'fixed')
    // attach scroll listeners
    this.scrollParents = getScrollParents(targetNode)
    _.forEach(this.scrollParents, (parent) => {
      if (parent !== targetNode.ownerDocument) {
        parent.addEventListener('scroll', this.handleUpdateTetherPosition)
      }
    })
  }
}
