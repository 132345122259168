import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { useFetchEntities } from 'browser/components/hooks/useFetchEntities'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { WorkflowDetails } from './workflow-details'

const PLAN_ID = '0b770bb1-501c-4408-a477-fe409e9f5f6b'
const ENTITY_TYPE = '/1.0/entities/metadata/entity.json'

const PLAN_QUERY = {
  entityType: ENTITY_TYPE,
  filters: [
    {
      path: 'mixins.active',
      type: 'containsEdge',
      values: [{ entityId: PLAN_ID }],
    },
  ],
}

export const WorkflowTool = () => {
  const [results] = useFetchEntities(PLAN_QUERY)
  const entities = _.map(results, 'data')
  const [schemaId, setSchemaId] = useState(null)

  useEffect(() => {
    if (entities && entities.length >= 1 && !schemaId) {
      setSchemaId(entities[0].uniqueId)
    }
  })

  if (!entities || entities.length < 1) {
    return <LoadingSpinner />
  }

  const schema = schemaId && _.find(entities, { uniqueId: schemaId })

  return (
    <div className="workflow-tools">
      <FormTable>
        <SelectField
          label="Storyboard Plan"
          isNative={true}
          onChange={(val) => setSchemaId(val)}
          optionLabelPath="core_storyboard_plan.name"
          optionValuePath="uniqueId"
          value={schemaId}
          options={entities}
        />
      </FormTable>
      {schema && <WorkflowDetails key={schemaId} plan={schema} />}
    </div>
  )
}
