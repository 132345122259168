import React from 'react'

import 'browser/app/pages/app/tools/workflow/_workflow-panel.scss'

interface WorkflowPanelProps {
  children: React.ReactNode
}

export const WorkflowPanel = (props: WorkflowPanelProps) => {
  return <div className="workflow-panel">{props.children}</div>
}
