import apis from 'browser/app/models/apis'
import _ from 'lodash'
import React from 'react'
import { AppStatusContext, DEFAULT_APP_STATUS, IAppStatusContext, MaintenanceStatus } from './AppStatusContext'

export class PollingAppStatus extends React.Component<{}, IAppStatusContext> {
  private readonly POLL_TIME_MS = 60 * 1000

  private interval: ReturnType<typeof setInterval>

  constructor(props) {
    super(props)
    this.state = DEFAULT_APP_STATUS
  }

  public componentDidMount() {
    this.fetchAppStatus()
    this.interval = setInterval(this.fetchAppStatus, this.POLL_TIME_MS)
  }

  public componentWillUnmount() {
    clearInterval(this.interval)
  }

  public render() {
    return (
      <AppStatusContext.Provider value={this.state}>
        {this.props.children}
      </AppStatusContext.Provider>
    )
  }

  private fetchAppStatus = async () => {
    try {
      const data = await apis.getApplicationStatus()
      if (!data || !data.maintenances || data.maintenances.length === 0) {
        return this.setState({
          raw: data,
          computed: null
        })      
      }
      
      const lastEvent = _.last(data.maintenances)
      if (lastEvent.status !== MaintenanceStatus.INPROGRESS) {
        return this.setState({
          raw: data,
          computed: null
        })      
      }
      
      const lastUpdate = _.last(lastEvent.updates)
      this.setState({
        computed: {
          message: _.get(lastUpdate, 'message', ''),
          title: _.get(lastEvent, 'name', ''),
        },
        raw: data
      })
    } catch (err) {
      this.setState({
        raw: null,
        computed: null
      })
      // fail silently
    }
  }
}
