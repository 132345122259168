import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { RecipientsField } from 'browser/components/atomic-elements/molecules/fields/recipients-field'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'

const EMAIL_TRIGGER_TYPE = '/1.0/entities/metadata/emailTrigger.json'

export class EmailTriggerSettings extends React.Component<any, any> {

  private dataSet: EntityDataSource
  private entitySchema: any

  constructor(props) {
    super(props)
    const store = apis.getStore()
    this.entitySchema = store.getRecord(EMAIL_TRIGGER_TYPE)
    this.dataSet = new EntityDataSource({
      entityType: EMAIL_TRIGGER_TYPE,
      metadata: {
        maxSizePerGroup: 10,
        offset: 0,
        shouldIncludeLeafEntities: true,
        size: 250,
      },
      debug: {
        context: 'view--EmailTriggerSettings'
      },
      orders: [
        {
          label: 'Posted date',
          path: 'creationDate',
          type: 'descending',
        },
      ],
    }).setOnChange(() => this.forceUpdate())
  }

  public componentDidMount() {
    this.dataSet.find()
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public render() {
    if (this.dataSet.isLoading) {
      return <LoadingSpinner />
    }
    const { entities } = this.dataSet
    return (
      <div className='grid-block vertical'>
        <CardHeader className='c-cardHeader--nonSeparating u-borderBottom'>
          <CardHeaderItem
            className='c-cardHeader-item--grow c-cardHeader-item--center'
            title='Document Triggers'
          />
          <CardHeaderItem
            className='"c-cardHeader-item--noMargin"'
          >
            <Button
              onClick={this.handleOpenTemplateCreationSheet}
            >
              Create Trigger
            </Button>
          </CardHeaderItem>
        </CardHeader>
        <div className='grid-block flex-wrap pa2 shrink'>
          {_.map(entities, (entity) => this.renderEmailTriggers(entity))}
        </div>
      </div>
    )
  }

  private renderEmailTriggers(entity) {
    const { emailTrigger } = entity
    return (
      <div className='ma3 ba pa3 br3 u-border h-auto w-30 shadow-4'>
        <div onClick={() => this.handleEditTrigger(entity)}>
          <h2 className='pr2 u-borderBottom mb3'>
            {emailTrigger.name}
            <div className='c-helpBlock mb1'>
              {emailTrigger.description}
            </div>
          </h2>
          <div className='c-formTable c-density--collapse u-bumperBottom'>
            <InputField
              className='overflow-hidden'
              isDisabled={true}
              label='Document Type'
              value={emailTrigger.documentType.displayName}
            />
            <RecipientsField
              className='overflow-hidden'
              isDisabled={true}
              label='Recipients'
              value={emailTrigger.recipients}
            />
          </div>
        </div>
        <Button
          buttonText='Delete'
          onClick={() => this.handleDeleteTrigger(entity)}
        />
      </div>
    )
  }

  private handleDeleteTrigger = (entity) => {
    entity.waitUntilDeleted().then(() => {
      this.dataSet.find()
    })
  }

  private handleOpenTemplateCreationSheet = () => {
    const contextProps = {
      density: 'collapse',
      isHorizontalLayout: true,
    }
    EntityFormPanel.open({
      schema: this.entitySchema,
      uiContext: contextProps,
      uiSchemaPath: 'uiSchema.web.entityCreationModal',
    })
  }

  private handleEditTrigger(entity) {
    const contextProps = {
      density: 'collapse',
      isHorizontalLayout: true,
      isUpdatingEntity: true,
    }

    const onClose = () => {
      entity.rollback()
    }

    EntityFormPanel.open({
      entity,
      onClose,
      schema: this.entitySchema,
      uiContext: contextProps,
      uiSchemaPath: 'uiSchema.web.entityCreationModal',
    })
  }
}
