import React from 'react'
import { Redirect } from 'react-router-dom'
import { isMobilePlatform, handleMobileDeferredDeepLink } from 'browser/app/utils/utils'

interface IDeferredDeepLinkProps {
  location: any
}

/**
 * A simple route to redirect to deferred deeplink
 */
export const DeferredDeepLink: React.FC<IDeferredDeepLinkProps> = (props) => {
  const { location } = props

  if (isMobilePlatform() && handleMobileDeferredDeepLink(location)) {
    return <div/>
  }
  return <Redirect to='/redirect'/>
}