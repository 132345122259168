import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import 'browser/app/pages/app/activity/_activity.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { EntityMap } from 'browser/components/atomic-elements/atoms/mapbox/entity-map'
import { ActivityCard } from 'browser/components/atomic-elements/organisms/activity-card'
import { Entity } from 'shared-libs/models/entity'

const POLL_TIMEOUT = 60000

interface IActivityProps extends IBaseProps {
  location: any
  settings: Settings
}

export class Activity extends React.Component<IActivityProps, any> {
  private timer: any

  constructor(props) {
    super(props)
    this.state = {
      activities: [],
      isLoading: true,
      isLoadingNextPage: false,
      isScrolled: false,
      newActivities: [],
      nextUrl: null,
      selectedDocument: null,
    }
    this.handleOnMouseEnterActivityCard = this.handleOnMouseEnterActivityCard.bind(this)
    this.handleOnMouseLeaveActivityCard = this.handleOnMouseLeaveActivityCard.bind(this)
    this.handlePoll = this.handlePoll.bind(this)
    this.handleShowNewActivities = this.handleShowNewActivities.bind(this)
    this.handleScrollToTop = this.handleScrollToTop.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  public UNSAFE_componentWillMount() {
    this.fetchFeed().then((json) => {
      this.setState({
        activities: json.results,
        activityDocuments: this.getActivityDocuments(json.results),
        isLoading: false,
        nextUrl: json.next_url,
      })
    })
  }

  public componentDidMount() {
    // Use js class because we need to change styling on a parent item. We use
    // height 100 on the parent such that the children can properly span
    // the entire window. The activity screen needs the body to scroll.
    // TODO(louis): Remove this once we move away from the activity feed.
    $('.js-pagesIndex').addClass('u-heightAuto')

    $(window).on('scroll', this.handleScroll)
    this.timer = setInterval(this.handlePoll, POLL_TIMEOUT)
  }

  public componentWillUnmount() {
    $('.js-pagesIndex').removeClass('u-heightAuto')
    $(window).off('scroll')
    this.stopPolling()
  }

  public render() {
    if (this.state.isLoading) {
      return (
        <div className="grid-block c-appBody">
          <LoadingSpinner />
        </div>
      )
    }
    const { settings } = this.props
    const firm = settings.getFirm()
    const feedTitle = firm.displayName + ' Feed'
    return (
      <div className="activityContainer">
        <Head title="Activity" />
        <div className="activityMapContainer">
          <EntityMap
            entities={this.state.activityDocuments}
            selectedEntity={this.state.selectedDocument}
            markersPaddingLeft={500}
            padding={{ left: 500, right: 5, top: 30, bottom: 30 }}
          />
        </div>

        <div
          className={classNames('paper paper--zDepth-1 banner', {
            'is-active': this.state.newActivities.length,
            'is-hidden': !this.state.newActivities.length,
          })}
          onClick={this.handleShowNewActivities}
        >
          <a className="u-displayBlock banner-link">{this.state.newActivities.length} new posts</a>
        </div>

        <div className="activityFeed-background" />

        <div className="activityFeed js-activityFeed">
          <div className="activityFeedControls-contrainer">
            <div
              className={classNames('activityFeedControls paper paper--zDepth-1', {
                'is-scrolled': this.state.isScrolled,
              })}
            >
              <h6>{feedTitle}</h6>
              <a
                className={classNames('activityFeedControls-backTop', {
                  'is-visible': this.state.isScrolled,
                })}
                onClick={this.handleScrollToTop}
              >
                Back to top
              </a>
            </div>
          </div>
          {this.renderActivities()}
          <div
            className={classNames('activity-loader', {
              'is-visible': this.state.nextUrl,
            })}
          >
            <div className="c-loadingSpinner" />
          </div>
        </div>
      </div>
    )
  }

  private renderActivities() {
    const { settings } = this.props
    // Avoid showing multiple cards for the same document
    const uniqueActivities = _.uniqBy(this.state.activities, (activity: any) => {
      return activity.document ? activity.document.uniqueId : activity.information
    })
    return uniqueActivities.map((activity: any) => {
      return (
        <ActivityCard
          key={activity.id}
          activity={activity}
          onMouseEnterActivityCard={this.handleOnMouseEnterActivityCard}
          onMouseLeaveActivityCard={this.handleOnMouseLeaveActivityCard}
          settings={settings}
        />
      )
    })
  }

  private fetchFeed(url?: string) {
    return apis.getActivityFeed(url).then((json: any) => {
      const documents = []
      _.forEach(json.results, (activity) => {
        if (!activity.document) {
          return
        }
        activity.document = new Entity(activity.document, apis)
        documents.push(activity.document)
      })
      return apis
        .getStore()
        .resolveMixins(documents)
        .then(() => json)
    })
  }

  private getActivityDocuments(activities) {
    const documents = []
    _.forEach(activities, (activity) => {
      const doc = activity.document
      if (doc) {
        documents.push(doc)
      }
    })
    return documents
  }

  private handleScroll() {
    const height = $(window).height()
    const scrollHeight = $('.js-activityFeed').height()
    const scrollTop = $(window).scrollTop()
    const isScrolled = scrollTop > 0
    if (scrollTop > scrollHeight - 2 * height) {
      this.requestNextPage()
    }
    // don't update state unless isScrolled change, for performance reason
    if (this.state.isScrolled !== isScrolled) {
      this.setState({ isScrolled: scrollTop > 0 })
    }
  }

  private handleScrollToTop() {
    // http://stackoverflow.com/questions/13397533/scrolltop-doesnt-work-on-firefox-and-ie
    $('body, html').animate({ scrollTop: 0 })
  }

  private handlePoll() {
    this.fetchFeed().then((json) => this.updateActivities(json))
  }

  private stopPolling() {
    clearInterval(this.timer)
  }

  private requestNextPage() {
    if (this.state.isLoadingNextPage || !this.state.nextUrl) {
      return
    }
    this.setState({ isLoadingNextPage: true })
    this.fetchFeed(`${this.state.nextUrl}`).then((json) => {
      const activities = this.state.activities
      activities.push.apply(activities, json.results)
      this.setState({
        activities,
        activityDocuments: this.getActivityDocuments(activities),
        isLoadingNextPage: false,
        nextUrl: json.next_url,
      })
    })
  }

  private handleShowNewActivities() {
    const { activities, newActivities } = this.state
    activities.unshift.apply(activities, newActivities)
    this.setState({
      activities,
      activityDocuments: this.getActivityDocuments(activities),
      newActivities: [],
    })
    // http://stackoverflow.com/questions/13397533/scrolltop-doesnt-work-on-firefox-and-ie
    $('body, html').animate({ scrollTop: 0 })
  }

  private updateActivities(json) {
    const activities = this.state.activities
    if (_.isEmpty(activities)) {
      this.setState({
        activities: json.results,
        activityDocuments: this.getActivityDocuments(json.results),
      })
      return
    }
    const newActivities = []
    const firstActivity = activities[0]
    for (let i = 0; i < json.results.length; ++i) {
      const newActivity = json.results[i]
      if (firstActivity.id === newActivity.id) {
        break
      }
      newActivities.push(newActivity)
    }

    // if user is scrolled, show banner 'click to show new activities'
    // otherwise just push new activities to feed directly
    if (this.state.isScrolled) {
      this.setState({ newActivities })
    } else {
      activities.unshift.apply(activities, newActivities)
      this.setState({
        activities,
        activityDocuments: this.getActivityDocuments(activities),
      })
    }
  }

  private handleOnMouseEnterActivityCard(activity) {
    const entity = activity.document
    if (entity && entity.get('document.address.geolocation')) {
      this.setState({ selectedDocument: entity })
    }
  }

  private handleOnMouseLeaveActivityCard(activity) {
    this.setState({ selectedDocument: null })
  }
}
