import { Tab, Tabs } from '@blueprintjs/core'
import { browserHistory } from 'browser/history'
import classNames from 'classnames'
import _ from 'lodash'
import pluralize from 'pluralize'
import queryString from 'query-string'
import React from 'react'
import { Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import apis from 'browser/app/models/apis'
import { EntityIndex as EntityPage } from 'browser/app/pages/app/entity'
import 'browser/app/pages/app/search/_search.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { MasterDetail } from 'browser/components/atomic-elements/atoms/master-detail/master-detail'
import { Entity } from 'shared-libs/models/entity'
import { Query } from 'shared-libs/models/query'
import { AllResultsMasterPanel } from './all-results-master-panel'
import { EntityResultsMasterPanel } from './entity-results-master-panel'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'

const SentryRoute = Sentry.withSentryRouting(Route);

const ALL_RESULTS_TAB_ID = 0
const INITIAL_NUM_RESULTS_PER_GROUP = 10

// Note(JoCo) - The ordering of this list is also used to inform the UI ordering of per-schema grouped results (tabPriorities)
const GROUPED_BY_METADATA = [
  '/1.0/entities/metadata/core_storyboard_execution.json',
  '/1.0/entities/metadata/core_calendar_appointment.json',
  '/1.0/entities/metadata/document.json',
  '/1.0/entities/metadata/core_yms_shipment.json',
  '/1.0/entities/metadata/core_fulfilment_trip.json',
  '/1.0/entities/metadata/core_yms_trailer.json',
  '/1.0/entities/metadata/core_yms_spot.json',
  '/1.0/entities/metadata/contact.json',
  '/1.0/entities/metadata/carrier.json',
  '/1.0/entities/metadata/customer.json',
  '/1.0/entities/metadata/location.json',
  '/1.0/entities/metadata/brokerOrder.json',
  '/1.0/entities/metadata/rbInvoice.json',
  '/1.0/entities/metadata/core_fulfilment_salesOrder.json',
  '/1.0/entities/metadata/core_accounting_accountsReceivable_salesInvoice.json',
]

export const getFacilityFilters = () => {
  const user = apis.getSettings().getUser()
  const userFacilities = _.compact([].concat(user.user.facility).concat(user.user.facilitiesList))

  if (_.isEmpty(userFacilities)) {
    return [] 
  }

  return [
    {
      type: "or",
      orFilters: [
        [
          {
            path: 'core_yms_execution.facility',
            type: 'matchEdge',
            values: userFacilities,
          }
        ],
        [
          {
            path: 'core_yms_yardMove.facility',
            type: 'matchEdge',
            values: userFacilities,
          }
        ],
        [
          {
            path: 'core_yms_carrierAppointment.facility',
            type: 'matchEdge',
            values: userFacilities,
          }
        ],
        [
          {
            path: 'core_yms_trailer.facility',
            type: 'matchEdge',
            values: userFacilities,
          }
        ],
        [
          {
            path: 'core_yms_spot.facility',
            type: 'matchEdge',
            values: userFacilities,
          }
        ],
        [
          {
            path: 'core_yms_execution.facility',
            type: 'notExists',
          },
          {
            path: 'core_yms_yardMove.facility',
            type: 'notExists',
          },
          {
            path: 'core_yms_carrierAppointment.facility',
            type: 'notExists',
          },
          {
            path: 'core_yms_trailer.facility',
            type: 'notExists',
          },
          {
            path: 'core_yms_spot.facility',
            type: 'notExists',
          },
        ],
      ]
    }
  ]
}

function getGroupSearchQuery(searchQuery, metadataIds, numResultsPerGroup) {
  const store = apis.getStore()
  const metadataUniqueIds = []
  _.forEach(metadataIds, (uri) => {
    const metadata = store.getRecord(uri)
    if (metadata) {
      metadataUniqueIds.push({
        entityId: metadata.uniqueId,
      })
    }
  })

  const groups = [
    {
      groupByValues: true,
      path: 'mixins.active',
      type: 'edge',
      values: metadataUniqueIds,
    },
  ]
  const orders = [
    {
      label: 'Creation date',
      path: 'creationDate',
      type: 'descending',
    },
  ]

  // Note(JoCo) - Ordering can only be done on properties common to all models
  //              otherwise entity types without the sort path will disappear from results
  return new Query(apis)
    .setFilters(getFacilityFilters())
    .setGroups(groups)
    .setOrders(orders)
    .setHighlightingEnabled(true)
    .setMaxSizePerGroup(numResultsPerGroup)
    .setSize(metadataIds.length)
    .setDebug({ context: 'view--SearchResults' })
    .setQuery(searchQuery.trim())
}

interface ISearchIndexProps extends IBaseProps {
  location: any
  match: any
}

interface ISearchIndexState {
  isLoading: boolean
  searchQuery: string
  searchResults: any[]
  selectedTabId: number
  selection: any[]
  totalResults: number
}

export class SearchIndex extends React.Component<ISearchIndexProps, ISearchIndexState> {

  private store: any
  private dataSet: any
  private tabPriorities: any

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      searchQuery: '',
      searchResults: [],
      selectedTabId: ALL_RESULTS_TAB_ID,
      selection: [],
      totalResults: 0,
    }
    this.store = apis.getStore()

    this.tabPriorities = _.reduce(GROUPED_BY_METADATA, (result, jsonId, index) => {
      const metadata = this.store.getRecord(jsonId);
      if (metadata) {
        result[metadata.uniqueId] = index;
      }
      return result;
    }, {});
  }

  public UNSAFE_componentWillMount() {
    const { location } = this.props
    const { query } = queryString.parse(location.search)
    this.setState({ searchQuery: _.isEmpty(query) ? '' : query })
    if (!_.isEmpty(query)) {
      this.queryEntities(query)
    }
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props
    if (location.search !== nextProps.location.search) {
      const { query } = queryString.parse(nextProps.location.search)
      this.setState({ searchQuery: _.isEmpty(query) ? '' : query })
      if (!_.isEmpty(query)) {
        this.queryEntities(query)
      }
    }
  }

  // TODO(louis): highlighting, general visual design
  public render() {
    const { isLoading, selectedTabId } = this.state
    if (isLoading) {
      return <LoadingSpinner />
    }
    return (
      <Tabs
        className='grid-block vertical c-tabs c-appBody'
        id='searchResultsTab'
        onChange={this.handleTabSelected}
        renderActiveTabPanelOnly={true}
        selectedTabId={selectedTabId}
      >
        {this.renderAllResultsTabPanel()}
        {this.renderEntityTabPanels()}
        <Tabs.Expander />
      </Tabs>
    )
  }

  private handleEntitySelected = (selection, selectedRow?) => {
    const { location, match } = this.props
    const showDetailPanel = selection.length === 1
    // TODO(Peter): don't hardcode this
    const uniqueId = _.get(selectedRow, 'data.uniqueId')
    const pathname = showDetailPanel ? `${match.url}/entity/${uniqueId}` : match.url
    if (location.path !== pathname) {
      // We have to set state before changing path otherwise, we run into a race
      // condition with setting selection
      this.setState({ selection }, () => {
        browserHistory.push({ pathname, search: location.search })
      })
    }
  }

  private handleCloseDetailPanel = () => {
    this.handleEntitySelected([])
  }

  private handleTabSelected = (tabId: number) => {
    this.setState({ selectedTabId: tabId })
    this.handleEntitySelected([])
  }

  private queryEntities(searchQuery) {
    const query = getGroupSearchQuery(searchQuery,
      GROUPED_BY_METADATA, INITIAL_NUM_RESULTS_PER_GROUP)

    this.dataSet?.dispose()
    this.dataSet = new EntityDataSource(null, query)

    this.setState({
      isLoading: true,
      selectedTabId: ALL_RESULTS_TAB_ID,
    })

    this.dataSet.setOnChange((result) => {
      this.setState({
        isLoading: false,
        searchResults: _.sortBy(result, x => x.data.displayName),
        selection: [],
        totalResults: this.dataSet.collection.result.metadata.totalEntityMatchCount,
      })
    })
    this.dataSet.find()
  }

  private getSearchResultSchema(schemaId) {
    const schema = this.store.getRecord(schemaId)
    if (!schema) {
      return this.getSearchResultSchema('/1.0/entities/metadata/entity.json')
    }
    return schema
  }

  private renderAllResultsTabPanel() {
    const { searchQuery, searchResults, selection, totalResults } = this.state
    const title = `All (${totalResults})`
    const masterPanel = (
      <AllResultsMasterPanel
        onSelectEntity={this.handleEntitySelected}
        onSelectTab={this.handleTabSelected}
        searchQuery={searchQuery}
        searchResults={searchResults}
        selection={selection}
        dataSet={this.dataSet}
        sectionPriorities={this.tabPriorities}
      />
    )
    return this.renderMasterDetailPanelTab(ALL_RESULTS_TAB_ID, title, masterPanel)
  }

  private renderEntityTabPanels() {
    const { searchQuery, searchResults, selection } = this.state

    const orderedResults = _.sortBy(searchResults, (group, _) => this.tabPriorities[group.data.entityId])

    return _.map(orderedResults, (group, index) => {
      const entitySchema = this.getSearchResultSchema(group.data.entityId)
      const rawTitle = group.data.entityId === '54809ba0-e1c6-46ec-af52-a392803a36b0' ?
        'Workflow' : entitySchema.title // hack - should we rename the whole entity type?

      const entityType = pluralize.plural(rawTitle)
      const title = `${entityType} (${group.metadata.totalEntityMatchCount})`
      const masterPanel = (
        <EntityResultsMasterPanel
          entitySchema={entitySchema}
          onSelectEntity={this.handleEntitySelected}
          searchQuery={searchQuery}
          selection={selection}
        />
      )
      return this.renderMasterDetailPanelTab(index + 1, title, masterPanel)
    })
  }

  private renderMasterDetailPanelTab(tabId, title, masterPanel) {
    const { match } = this.props
    const { searchResults } = this.state
    const showDetailPanel = !match.isExact
    const detailPath = `${match.url}/entity/:entityId`
    const detailPanelRoute = (
      <SentryRoute path={detailPath} render={this.handleRenderDetailsRoute} />
    )
    const masterDetailPanel = (
      <MasterDetail
        className={classNames('c-searchResults', {
          'c-searchResults--singleEntitySearch': searchResults.length === 1,
        })}
        showDetailPanel={showDetailPanel}
        masterPanel={masterPanel}
        detailPanel={detailPanelRoute}
      />
    )
    return (
      <Tab
        className='grid-block'
        id={tabId}
        key={tabId}
        title={title}
        panel={masterDetailPanel}
      />
    )
  }

  private handleRenderDetailsRoute = (props) => {
    const { history, location, match } = props
    const firstSelection = _.first(this.state.selection)
    const entity = firstSelection instanceof Entity ? firstSelection : null
    return (
      <div className='c-searchResultsDetailPanel'>
        <EntityPage
          onClose={this.handleCloseDetailPanel}
          entity={entity}
          history={history}
          location={location}
          match={match}
          uiSchemaPath='uiSchema.web.searchResultDetailCard'
        />
      </div>
    )
  }
}
