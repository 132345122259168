export const globalTranslationTable = {
  en: {
    Error: 'Error',
    Retry: 'Retry',
    Processing: 'Processing',
    Uploading: 'Uploading',
    Loading: 'Loading',
    Loading_Up: 'Loading up',
    Page_Not_Found: 'Page not found',
    Page_Not_Found_Message: 'Sorry about that! Try navigating using the link you received over email or SMS.',
    Feed_Conversation: 'Conversation',
    Feed_No_Comments_Yet: 'There are no comments yet.',
    Feed_Type_A_Message: 'Type a message...',
    Confirm_Log_Out_Msg: 'Are you sure you want to sign out?',
    Barcode_Field_Error_No_Camera_Permission: 'The QR scanner could not start without permission to use the camera.',
    Barcode_Field_Error_No_Camera_Permission_Remedy: 'To fix this error, open your browser settings and enable camera permissions for {host} to scan a QR code.',
    Carrier_Search_Field_Placeholder: 'Search by DOT number',
    CRM_Search_Field_Placeholder: 'Search by DOT number',
    Select_Workflow: 'Select a workflow',
    Workflow_Empty_State_Tagline: 'Moving What The World Needs, Together',
  },
  es: {
    Error: 'Error',
    Retry: 'Reintentar',
    Processing: 'Procesando',
    Uploading: 'Enviando',
    Loading: 'Cargando',
    Loading_Up: 'Cargando',
    Page_Not_Found: 'Página no encontrada',
    Page_Not_Found_Message: '¡Lo sentimos! Intente navegar usando el hipervínculo que recibió por correo electrónico o SMS.',
    Feed_Conversation: 'Conversación',
    Feed_No_Comments_Yet: 'Todavía no hay comentarios.',
    Feed_Type_A_Message: 'Escribe un mensaje...',
    Confirm_Log_Out_Msg: '¿Estás seguro de que quieres cerrar sesión?',
    Barcode_Field_Error_No_Camera_Permission: 'El escáner QR no pudo iniciarse sin permiso para usar la cámara.',
    Barcode_Field_Error_No_Camera_Permission_Remedy: 'Para corregir este error, abra la configuración de su navegador y habilite los permisos de la cámara para que {host} escanear un código QR.',
    Carrier_Search_Field_Placeholder: 'Buscar por número DOT',
    CRM_Search_Field_Placeholder: 'Buscar por número DOT',
    Select_Workflow: 'Seleccione un flujo de trabajo',
    Workflow_Empty_State_Tagline: 'Moviendo lo que el mundo necesita, juntos',
  },
}
