import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/organisms/activity-card/_share-item.scss'

const formatDate = (date) => {
  return moment(date).format('ddd, MMM D, YYYY, h:mm A')
}

interface ISharesListProps extends IBaseProps {
  activity: any
  entity: any
  onNewSharedWith: (value: any) => void
}

export class SharesList extends React.Component<ISharesListProps, any> {
  public static defaultProps: Partial<ISharesListProps> = {
    activity: {},
    onNewSharedWith: _.noop,
  }

  constructor(props) {
    super(props)

    const activity = this.props.activity
    this.state = {
      sharedWith: activity.shared_with,
    }

    this.handleOnInputKeyPress = this.handleOnInputKeyPress.bind(this)
  }

  public handleOnInputKeyPress(event) {
    // if it is not enter, ignore
    if (event.key !== 'Enter') { return }
    const $input = $(event.target)
    const sharedWithEmail = $input.val()
    const sharedWith = this.state.sharedWith
    const { entity } = this.props
    // clear the input
    $input.val('')
    entity.addShare({ email: sharedWithEmail }).then((data) => {
      sharedWith.push({
        shared_by_email: data.share.sharedBy.displayName,
        shared_time: data.creationDate,
        shared_with_email: data.share.sharedTo.email,
      })
      this.setState({ sharedWith })
      this.props.onNewSharedWith(sharedWithEmail)
    })
  }

  public render() {
    let content = this.state.sharedWith.map((sharedWith, index) => {
      return (
        <div
          className='c-shareItem'
          data-debug-id='activity-card-share-item'
          key={index}
        >
          <Avatar
            size='sm'
            email={sharedWith.shared_by_email}
          />
          <div className='c-shareItem-body'>
            <div className='c-shareItem-content'>
              <span className='u-fontWeightSemibold'>
                {sharedWith.shared_by_email}
              </span> shared document with <span className='u-fontWeightSemibold'>
                {sharedWith.shared_with_email}
              </span>
            </div>
            <div>
              <span className='c-label c-label--secondary'>
                {formatDate(sharedWith.shared_time)}
              </span>
            </div>
          </div>
        </div>
      )
    })

    if (content.length === 0) {
      content = (
        <div className='shareForm-empty'>
          This document is not shared
        </div>
      )
    }

    return (
      <div className='c-activityCardBody'>
        {content}
        <input
          className='c-input u-bumperTop'
          data-debug-id='activity-card-share-input'
          onKeyPress={this.handleOnInputKeyPress}
          placeholder='Enter email address and press enter...'
          type='email'
        />
      </div>
    )
  }
}
