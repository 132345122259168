import React from 'react'
import { Link } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import 'browser/app/pages/app/404/_404.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'

type IApp404Props = IBaseProps

export class App404 extends React.Component<IApp404Props, any> {

  public render() {
    return (
      <div className='c-404Page'>
        <div>
          <Head
            title='404'
          />
          <h1 className='c-h1'>404</h1>
          <p className='c-lead'>Page not found.</p>
          <Link
            className={classNames('c-button', Classes.MINIMAL)}
            to='/'
          >
            Home
          </Link>
        </div>
      </div>
    )
  }
}
