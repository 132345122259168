import _ from 'lodash'
import React, { useContext, useState } from 'react'

import apis from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { EntityFormSheet } from 'browser/components/atomic-elements/organisms/entity/entity-form-sheet'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { Entity } from 'shared-libs/models/entity'

export const FtpSettingsDetails: React.FC = (props) => {
  const { settings } = useContext(AppNavigatorContext)
  const { openOverlay } = useContext(SheetContext)
  const [forceUpdate, setForceUpdate] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const ftpSettings: Entity = settings.getFtpSettings()
  const hasCustomFTPTrigger = settings.hasCustomFTPTrigger

  const store = apis.getStore()
  const entitySchema = store.getRecord('/1.0/entities/metadata/core_integrations_ftp_settings.json')
  const handleFtpCreate = () => {
    openOverlay(
      <EntityFormSheet
        entitySchema={entitySchema}
        onCreate={_.noop}
      />
    )
  }

  const handleFtpDelete = () => {
    const deleteEntity = () => {
      ftpSettings.delete().
      then(() => {
        settings.removeFtpSettings()
        handleOnChange()
      })
    }
    ConfirmationModal.open({
      confirmationText: 'Do you want to delete this item?',
      confirmationTitle: 'Confirm Delete',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: deleteEntity,
      primaryButtonText: 'Confirm',
    })
  }

  const renderFtpActionButton = () => {
    if (hasCustomFTPTrigger) {
      return null
    } else if (ftpSettings) {
      return (
        <Button
          buttonText='Delete FTP Settings'
          onClick={handleFtpDelete}
        />
      )
    } else {
      return (
        <Button
          buttonText='Create FTP Settings'
          onClick={handleFtpCreate}
        />
      )
    }
  }

  const renderCustomFTPWarning = () => {
    if (!hasCustomFTPTrigger) {
      return
    }
    return (
      <HelpBlock className="u-textCenter mh4 ph3 pv3">
        You have a custom FTP configuration. If you have any questions, please reach out to{' '}
        <a href="mailto:support@withvector.com">support@withvector.com</a>.
      </HelpBlock>
    )
  }

  const handleRollback = () => {
    ftpSettings.rollback()
    setErrors([])
  }

  const handleSave = () => {
    setIsLoading(true)
    ftpSettings.save().then(() => {
      setIsLoading(false)
    }).catch(({errors}) => {
      setIsLoading(false)
      setErrors(errors)
    })
  }

  const handleOnChange = () => {
    setForceUpdate(forceUpdate + 1)
  }

  let content = (
    <div className='grid-block align-verticalCenter u-justifyContentCenter'>
    </div>
  )

  if (ftpSettings) {
    content = (
      <EntityRenderer
        isEditable={!hasCustomFTPTrigger}
        applyDefaults={true}
        actions={{
          handleRollback,
          handleSave
        }}
        componentsMap={ComponentsMap}
        errors={errors}
        onChangeComplete={handleOnChange}
        state={{ errors, isLoading }}
        uiSchemaPath={'uiSchema.web.entityDetailView'}
        value={ftpSettings}
      />
    )
  }

  return (
    <React.Fragment>
      <CardHeader className="c-cardHeader--nonSeparating u-borderBottom">
        <CardHeaderItem
          className="c-cardHeader-item--grow c-cardHeader-item--left"
          title="FTP Settings"
        />
        <CardHeaderItem>{renderFtpActionButton()}</CardHeaderItem>
      </CardHeader>
      {renderCustomFTPWarning()}
      {content}
    </React.Fragment>
  )
}
