import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { isEmailValid } from 'shared-libs/helpers/utils'

import { getPostedByName } from 'browser/app/utils/utils'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import 'browser/components/atomic-elements/organisms/activity-card/_share-item.scss'
import { CardTemplate } from './card-template'
const DoctypeOtherImage = require('images/doctype-other.svg')

interface IActivityCardProps extends IBaseProps {
  activity: any
  onMouseEnterActivityCard: (arg0: any) => void
  onMouseLeaveActivityCard: (arg0: any) => void
  settings?: any
}

const COYOTE_FIRMS = new Set([
  'b1197fcf-d361-4d9a-ae70-e151739ac4ef', // Coyote UPS Peak - Testing
  '7249353c-92c0-4584-9144-bc15d7de6ce8', // Coyote UPS Peak
])
const UPS_PICKUPS = new Set([
  'ecd4d7f8-d604-4271-96bb-374576f95286', // Coyote UPS Peak Trailer Pickup (Holidays 2019)
])
const UPS_DELIVERIES = new Set([
  '97b5d41e-fe7e-434d-865a-bcd1449d42da', // Coyote UPS Peak Trailer Delivery (Holidays 2019)
])
const UPS_BOBTAILS = new Set([
  '599eb60f-79a0-4d2d-88c5-8f436a320580', // Coyote UPS Peak Bobtail (Holidays 2019)
])
const UPS_BOBTAIL_ENDS = new Set([
  'ff259cb2-6633-44b2-94bd-9e2838efbbc4', // Coyote UPS Peak Bobtail End (Holidays 2019)
])

export class ActivityCard extends React.Component<IActivityCardProps, any> {
  public static defaultProps: Partial<IActivityCardProps> = {
    activity: {},
    onMouseEnterActivityCard: _.noop,
    onMouseLeaveActivityCard: _.noop,
  }

  constructor(props) {
    super(props)
    this.renderNewDocumentHeaderDescription = this.renderNewDocumentHeaderDescription.bind(this)
    this.renderNewFirmUserCardBody = this.renderNewFirmUserCardBody.bind(this)
    this.renderNewCommentHeaderDescription = this.renderNewCommentHeaderDescription.bind(this)
    this.renderNewShareHeaderDescription = this.renderNewShareHeaderDescription.bind(this)
    this.renderDocumentCardBody = this.renderDocumentCardBody.bind(this)
    this.renderWelcomeCardBody = this.renderWelcomeCardBody.bind(this)
    this.renderDefaultHeaderDescription = this.renderDefaultHeaderDescription.bind(this)
  }

  public getAttachmentsPreview(document) {
    const files = _.get(document, 'attachments.files', [])
    const file: any = _.first(files)
    // TODO(Peter): Render the table of the receiving ticket
    if (!file) {
      return false
    }
    if (file.type === 'pdf') {
      return _.get(file, 'preview.0.source.0.uri')
    } else if (file.type === 'image') {
      return _.get(file, 'source.0.uri')
    } else {
      return DoctypeOtherImage
    }
  }

  public getAttachmentsPages(document) {
    const files = _.get(document, 'attachments.files', [])
    return files.length
  }

  public formattedSharees(activity) {
    const information = activity.information
    switch (information.sharee.length) {
      case 0:
        return ''
      case 1:
        return information.sharee[0]
      case 2:
        return `${information.sharee[0]} and 1 other person`
      default:
        return `${information.sharee[0]} and ${information.sharee.length - 1} others`
    }
  }

  public render() {
    const { activity } = this.props
    const { document } = activity

    // TODO(DAVID): This is a hack for UPS, eventually we should get rid of this...
    const firmId = _.get(document, 'owner.firm.entityId', '')
    if (COYOTE_FIRMS.has(firmId) && activity.information.action === 'upload_doc') {
      return (
        <CardTemplate
          activity={this.props.activity}
          authorEmail={activity.information.creator}
          authorName={activity.information.creator_first_name + ' ' + activity.information.creator_last_name}
          hasFooter={true}
          onMouseEnter={this.props.onMouseEnterActivityCard}
          onMouseLeave={this.props.onMouseLeaveActivityCard}
          renderCardBodyContent={this.renderDocumentCardBody}
          renderCardHeaderContent={this.renderNewUPSDocumentHeaderDescription}
        />
      )
    }

    if (activity.information.action === 'doc_comment') {
      return (
        <CardTemplate
          activity={this.props.activity}
          authorEmail={activity.information.commentor}
          authorName={activity.information.commentor_first_name + ' ' + activity.information.commentor_last_name}
          hasFooter={true}
          onMouseEnter={this.props.onMouseEnterActivityCard}
          onMouseLeave={this.props.onMouseLeaveActivityCard}
          renderCardBodyContent={this.renderDocumentCardBody}
          renderCardHeaderContent={this.renderNewCommentHeaderDescription}
        />
      )
    } else if (activity.information.action === 'share_doc') {
      return (
        <CardTemplate
          activity={this.props.activity}
          authorEmail={activity.information.sharer}
          authorName={activity.information.sharer_first_name + ' ' + activity.information.sharer_last_name}
          hasFooter={true}
          onMouseEnter={this.props.onMouseEnterActivityCard}
          onMouseLeave={this.props.onMouseLeaveActivityCard}
          renderCardBodyContent={this.renderDocumentCardBody}
          renderCardHeaderContent={this.renderNewShareHeaderDescription}
        />
      )
    } else if (activity.information.action === 'upload_doc') {
      return (
        <CardTemplate
          activity={this.props.activity}
          authorEmail={activity.information.creator}
          authorName={activity.information.creator_first_name + ' ' + activity.information.creator_last_name}
          hasFooter={true}
          onMouseEnter={this.props.onMouseEnterActivityCard}
          onMouseLeave={this.props.onMouseLeaveActivityCard}
          renderCardBodyContent={this.renderDocumentCardBody}
          renderCardHeaderContent={this.renderNewDocumentHeaderDescription}
        />
      )
    } else if (activity.information.action === 'welcome') {
      return (
        <CardTemplate
          activity={this.props.activity}
          authorEmail={activity.information.email}
          authorName={activity.information.first_name + ' ' + activity.information.last_name}
          hasFooter={false}
          renderCardBodyContent={this.renderWelcomeCardBody}
          renderCardHeaderContent={this.renderDefaultHeaderDescription}
        />
      )
    } else if (activity.information.action === 'join_firm') {
      return (
        <CardTemplate
          activity={this.props.activity}
          authorEmail={activity.information.email}
          authorName={activity.information.first_name + ' ' + activity.information.last_name}
          hasFooter={false}
          renderCardBodyContent={this.renderNewFirmUserCardBody}
          renderCardHeaderContent={this.renderDefaultHeaderDescription}
        />
      )
    }
    return null
  }

  private renderDocumentDetailLink() {
    const { activity } = this.props
    const pathname = `/entity/${activity.document.uniqueId}`
    return (
      <Link
        to={pathname}
      >
        {activity.information.load_number}
      </Link>
    )
  }

  private renderNewDocumentHeaderDescription() {
    const activity = this.props.activity
    // NOTE: document might not have attachment e.g. receiving ticket
    const entity = activity.document
    const document = entity.document
    const creator = getPostedByName({
      email: activity.information.creator,
      first_name: activity.information.creator_first_name,
      last_name: activity.information.creator_last_name,
    })
    const numPages = this.getAttachmentsPages(document)
    const pages = (numPages === 1) ? 'page' : 'pages'
    const documentType = entity.entityType
    return (
      <div data-debug-id='activity-card-title'>
        <span className='u-fontWeightMedium'>
          {creator}
        </span> uploaded {documentType} ({numPages} {pages})
        to {this.renderDocumentDetailLink()}.
      </div>
    )
  }

  private renderNewCommentHeaderDescription() {
    const activity = this.props.activity
    const name = getPostedByName({
      email: activity.information.commentor,
      first_name: activity.information.commentor_first_name,
      last_name: activity.information.commentor_last_name,
    })
    const documentType = activity.information.doc_type_title || 'document'
    return (
      <div data-debug-id='activity-card-title'>
        <span className='u-fontWeightMedium'>
          {name}
        </span> commented on {this.renderDocumentDetailLink()}: {documentType}.
      </div>
    )
  }

  private renderNewShareHeaderDescription() {
    const activity = this.props.activity
    const entity = activity.document
    const document = entity.document
    const name = getPostedByName({
      email: activity.information.sharer,
      first_name: activity.information.sharer_first_name,
      last_name: activity.information.sharer_last_name,
    })
    const numPages = this.getAttachmentsPages(document)
    const pages = (numPages === 1) ? 'page' : 'pages'
    const documentType = entity.entityType
    return (
      <div data-debug-id='activity-card-title'>
        <span className='u-fontWeightMedium'>{name}</span> shared {this.renderDocumentDetailLink()} ({numPages} {pages}): {documentType} to {this.formattedSharees(activity)}.
      </div>
    )
  }

  private renderDefaultHeaderDescription() {
    const activity = this.props.activity
    return (
      <div data-debug-id='activity-card-title'>
        <span className='u-fontWeightMedium'>
          {activity.information.email}
        </span>
      </div>
    )
  }

  private renderDocumentCardBody() {
    const activity = this.props.activity
    const document = activity.document.document
    const imageUrl = this.getAttachmentsPreview(document)
    if (!imageUrl) {
      return
    }
    const pathname = `/entity/${activity.document.uniqueId}`
    return (
      <div className='c-activityCardBody'>
        <Link
          className='activityCard-photoFrame'
          to={pathname}
        >
          <ImageLoader
            imageClassName='activityCard-photo'
            hasCenteringHelper={false}
            src={imageUrl}
          />
        </Link>
      </div>
    )
  }

  private renderNewFirmUserCardBody() {
    const { activity, settings } = this.props
    let domainDisplayName = 'LoadDocs'
    if (settings) {
      const firm = settings.getFirm()
      const firmDomain = _.get(firm, 'firm.preferences.domain', 'loaddocs.co')
      if (_.includes(firmDomain, 'vector')) {
        domainDisplayName = 'Vector'
      }
    }
    const isEmail = isEmailValid(activity.information.email)
    return (
      <div className='c-activityCardBody'>
        <a href={isEmail ? `mailto:${activity.information.email}` : `tel:${activity.information.email}`}>
          {getPostedByName(activity.information)}
        </a>
        &nbsp;is now a member of {activity.information.firm} on {domainDisplayName}.
        All their documents and activity will be shared with you.
      </div>
    )
  }

  private renderWelcomeCardBody() {
    const { activity, settings } = this.props
    // Default the domain display name to LoadDocs;
    let domainDisplayName = 'LoadDocs'
    if (settings) {
      const firm = settings.getFirm()
      const firmDomain = _.get(firm, 'firm.preferences.domain', 'loaddocs.co')
      if (_.includes(firmDomain, 'vector')) {
        domainDisplayName = 'Vector'
      }
    }
    return (
      <div className='c-activityCardBody'>
        Welcome {getPostedByName(activity.information)} to {domainDisplayName}!
      </div>
    )
  }

  private renderNewUPSDocumentHeaderDescription = () => {
    const { activity } = this.props
    const entity = activity.document
    const creator = getPostedByName({
      email: activity.information.creator,
      first_name: activity.information.creator_first_name,
      last_name: activity.information.creator_last_name,
    })

    let message = ''
    const trailerNumber = _.get(entity, 'coyoteUpsPeakTemplate.trailerNumber', '')
    const doubleTrailerNumber = _.get(entity, 'coyoteUpsPeakTemplate.doubleTrailerNumber', '')
    const departureTerminal = _.get(entity, 'coyoteUpsPeakTemplate.departureTerminal', '')
    const destinationTerminal = _.get(entity, 'coyoteUpsPeakTemplate.destinationTerminal', '')

    if (_.some(entity.activeMixins, (mixin) => UPS_PICKUPS.has(mixin.entityId))) {
      if (doubleTrailerNumber) {
        message = `has picked up trailers ${trailerNumber} and ${doubleTrailerNumber} from ${departureTerminal}, and is heading to ${destinationTerminal}`
      } else {
        message = `has picked up trailer ${trailerNumber} from ${departureTerminal} and is heading to ${destinationTerminal}`
      }
    } else if (_.some(entity.activeMixins, (mixin) => UPS_BOBTAILS.has(mixin.entityId))) {
      message = `is bobtailing from ${departureTerminal} to ${destinationTerminal}`
    } else if (_.some(entity.activeMixins, (mixin) => UPS_DELIVERIES.has(mixin.entityId))) {
      message = doubleTrailerNumber ? `has delivered trailers ${trailerNumber} and ${doubleTrailerNumber} to ${destinationTerminal}`
        : `has delivered trailer ${trailerNumber} to ${destinationTerminal}`
    } else if (_.some(entity.activeMixins, (mixin) => UPS_BOBTAIL_ENDS.has(mixin.entityId))) {
      message = `has arrived after bobtailing to ${destinationTerminal}`
    }
    return (
      <div data-debug-id='activity-card-title'>
        <span className='u-fontWeightMedium'>
          {creator}
        </span> {message} (Route Code: {this.renderDocumentDetailLink()})
      </div>
    )
  }
}
