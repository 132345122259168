import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { CustomDocumentTypesPage } from 'browser/components/domains/firm/custom-document-types-page'
import { createAppBundleIfBasedBundleForFirm } from 'browser/components/domains/firm/firm-utils'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Settings } from 'browser/app/models/settings'

interface ICustomDocumentTypesSetting extends IBaseProps {
  settings: Settings
}

export class CustomDocumentTypesSetting extends React.Component<ICustomDocumentTypesSetting, any> {
  private bundle: any

  constructor(props) {
    super(props)
    const store = apis.getStore()

    this.bundle = this.props.settings.getApplicationBundle().getBundle()

    /*
    store.findRecord(this.bundle.uniqueId).then((entity) => {
      this.setState({appBundle: entity})
    })
    */

    const firm = this.props.settings.getFirm()

    store.findRecord(this.bundle.uniqueId)
      .then((entity) => createAppBundleIfBasedBundleForFirm(entity, firm))
      .then((appBundle) => {
        this.setState({appBundle})
      })

    this.state = {
      appBundle: null,
    }
  }

  public render() {
    const {appBundle} = this.state

    if (!appBundle) {
      return (<LoadingSpinner/>)
    }

    return (
      <div className='grid-block'>
        <div className='grid-block vertical c-appBody'>
          <CardHeader className='c-cardHeader--nonSeparating u-borderBottom'>
            <CardHeaderItem
              className='c-cardHeader-item--grow c-cardHeader-item--left'
              title='Document Types'
            />
          </CardHeader>
          <CustomDocumentTypesPage entity={appBundle}/>
        </div>
      </div>
    )
  }
}
