import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { InputGroupField } from 'browser/components/atomic-elements/molecules/fields/input-group-field/input-group-field'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

interface IPaymentInformationModalProps extends IBaseProps {
  onClose: () => void
}

export class PaymentInformationModal extends React.Component<IPaymentInformationModalProps, any> {

  public static open(props?) {
    return OverlayManager.openOverlayElement(
      <Modal
        modalDialogClassName='c-modal-dialog--md'
      >
        <PaymentInformationModal {...props} />
      </Modal>,
    )
  }

  public render() {
    const { onClose } = this.props
    return (
      <div>
        <div className='c-modalHeader'>
          <h4 className='c-modal-title'>Payment Information</h4>
          <p className='c-helpBlock'>
            LoadDocs accepts all major credit and debit cards.
          </p>
        </div>
        <div className='c-modalBody'>
          <InputField

            isHorizontalLayout={true}
            label='Card Number'
          />
          <InputField

            isHorizontalLayout={true}
            label='Name on card'
          />
          <div className='flex'>
            <div className='c-label'>
              Expiration
            </div>
            <Input
              isHorizontalLayout={true}
              placeholder='MM'
            />
            <Input
              isHorizontalLayout={true}
              placeholder='YY'
            />
          </div>
          <InputField
            isHorizontalLayout={true}
            label='CVV'
            placeholder='000'
          />
          <InputField

            isHorizontalLayout={true}
            label='Zipcode'
            placeholder='00000'
          />
        </div>
        <Footer
          isVisible={true}
          onCancelButtonClick={onClose}
          onPrimaryButtonClick={onClose}
        />
      </div>
    )
  }
}
