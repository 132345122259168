interface SandboxClientOptions {
  serverUrl: string 
  onConnected?: () => void
  onMessageReceived?: (data: MessageEvent['data']) => void
  onError?: (error: any) => void
  onDisconnected?: () => void
}

export class SandboxClient {
  private ws?: WebSocket

  public start(opts: SandboxClientOptions) {
    this.ws = new WebSocket(opts.serverUrl)
    this.ws.onopen = () => opts.onConnected?.()
    this.ws.onmessage = (event) => opts.onMessageReceived?.(event.data)
    this.ws.onerror = (error) => opts.onError?.(error)
    this.ws.onclose = () => opts.onDisconnected?.()
  }

  public send(data: any) {
    this.ws?.send(JSON.stringify(data))
  }

  public close() {
    this.ws?.close()
    this.ws = undefined
  }
}
