import React from 'react'

import { Classes as BlueprintClasses } from '@blueprintjs/core'
import classNames from 'classnames'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { PaymentInformationModal } from 'browser/components/atomic-elements/organisms/payment-information-modal'

type IBillingSettingsProps = IBaseProps

export class BillingSettings extends React.Component<IBillingSettingsProps, any> {
  public render() {
    return (
      <div className='grid-block vertical'>
        <CardHeader className='c-cardHeader--nonSeparating u-borderBottom'>
          <CardHeaderItem
            className='c-cardHeader-item--grow'
            title='Billing'
          />
        </CardHeader>
        <div className='grid-block'>
          {this.renderBody()}
        </div>
      </div>
    )
  }

  private renderBody() {
    return (
      <div className='grid-content'>
        <Section
          title='Subscription Pricing'
        >
          <div className='row'>
            <div className='col-xs-4'>
              <p>You are billed monthly for all active paid services (excluding free trials). Additional charges will be made per usage. You may also be charged sales tax if this is a legal requirement in your zip code.</p>
            </div>
            <div className='col-xs-8'>
              <div className='c-callout u-flex u-alignItemsCenter u-justifyContentSpaceBetween u-innerBumper'>
                You do not have a payment method configured.

                <Button
                  className={classNames(BlueprintClasses.INTENT_PRIMARY, 'u-floatRight')}
                  onClick={this.handleShowPaymentInformationModal}
                >
                  Add payment method
                </Button>
              </div>

              <div className='u-hide'>
                Your current payment method is your Visa ending in 5092.

                <Button
                  className='c-button--secondary u-floatRight'
                  onClick={this.handleShowPaymentInformationModal}
                >
                  Update payment method
                </Button>
              </div>

            </div>
          </div>
        </Section>

        <Section
          title='Transaction History'
        >
          <table className='c-table c-table--auto'>
            <thead className='c-table-header'>
              <tr>
                <th className='c-table-cell c-table-cell--header'>Date</th>
                <th className='c-table-cell c-table-cell--header'>Transaction #</th>
                <th className='c-table-cell c-table-cell--header'>Description</th>
                <th className='c-table-cell c-table-cell--header tr'>Billed</th>
                <th className='c-table-cell c-table-cell--header tr'>Paid</th>
              </tr>
            </thead>
            <tbody className='c-table-body'>
              <tr className='c-table-row'>
                <td className='c-table-cell'>6/22/2015</td>
                <td className='c-table-cell'>871623897</td>
                <td className='c-table-cell'>Visa ending in 8664 approved <a href='/payments/receipt/5BTDK89Y7H1R'>(receipt)</a></td>
                <td className='c-table-cell tr' />
                <td className='c-table-cell tr'>$99.00</td>
              </tr>
              <tr className='c-table-row'>
                <td className='c-table-cell'>6/22/2015</td>
                <td className='c-table-cell'>16928361278</td>
                <td className='c-table-cell'><a href='/payments/invoice?id=MH69NXJB2Z4H'>LoadDocs Pro - 25 Users (6/22/2015 to 6/22/2016)</a></td>
                <td className='c-table-cell tr'>$99.00</td>
                <td className='c-table-cell tr' />
              </tr>
              <tr className='c-table-row'>
                <td className='c-table-cell'>6/8/2015</td>
                <td className='c-table-cell'>1298371923</td>
                <td className='c-table-cell'>Billing info updated to use Credit card *****8664</td>
                <td className='c-table-cell tr' />
                <td className='c-table-cell tr' />
              </tr>
              <tr className='c-table-row'>
                <td className='c-table-cell'>6/22/2014</td>
                <td className='c-table-cell'>12341231</td>
                <td className='c-table-cell'>Credit card *****XXXX approved <a href='/payments/receipt/fshs82m'>(receipt)</a></td>
                <td className='c-table-cell tr' />
                <td className='c-table-cell tr'>$99.00</td>
              </tr>
              <tr className='c-table-row'>
                <td className='c-table-cell'>6/22/2014</td>
                <td className='c-table-cell'>11312321</td>
                <td className='c-table-cell'><a href='/payments/invoice?id=INVC73440800'>LoadDocs Pro - 25 Users (6/22/2014 to 6/22/2015)</a></td>
                <td className='c-table-cell tr'>$99.00</td>
                <td className='c-table-cell tr' />
              </tr>
              <tr className='c-table-row'>
                <td className='c-table-cell'>6/22/2013</td>
                <td className='c-table-cell'>123123142</td>
                <td className='c-table-cell'>Credit card *****4123 approved <a href='/payments/receipt/5332596302'>(receipt)</a></td>
                <td className='c-table-cell tr' />
                <td className='c-table-cell tr'>$99.00</td>
              </tr>
              <tr className='c-table-row'>
                <td className='c-table-cell'>6/22/2013</td>
                <td className='c-table-cell'>12312314123</td>
                <td className='c-table-cell'><a href='/payments/invoice?id=INVC45913088'>LoadDocs Pro - 25 Users (6/22/2013 to 6/22/2014)</a></td>
                <td className='c-table-cell tr'>$99.00</td>
                <td className='c-table-cell tr' />
              </tr>
            </tbody>
          </table>
        </Section>
      </div>
    )
  }

  private handleShowPaymentInformationModal = () => {
    PaymentInformationModal.open()
  }
}
