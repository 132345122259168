import React from 'react'
import classNames from 'classnames'

import 'browser/app/pages/app/tools/workflow/_workflow-node.scss'

interface IWorkflowNodeProps {
  children: React.ReactNode
  onClick?: () => void
}

export const WorkflowNode = (props: IWorkflowNodeProps) => {
  const isClickable = !!props.onClick

  const clickHandler: React.MouseEventHandler<HTMLDivElement> = (event) => {
    props.onClick()
    event.stopPropagation()
  }

  return (
    <div
      onClick={isClickable ? clickHandler : undefined}
      className={classNames('workflow-node', { 'workflow-node--clickable': !!isClickable })}
    >
      {props.children}
    </div>
  )
}
