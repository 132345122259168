import React from 'react'
import _ from 'lodash'
import {
  Predicate,
  StoryboardPlanEntity,
} from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { WorkflowNavEntry } from './workflow-nav-entry'
import { JSONEditor } from 'browser/components/atomic-elements/organisms/internal-tools/json-editor'
import { WorkflowNode } from './workflow-node'
import { WorkflowNavRoute } from './workflow-nav-route'
import { DropTarget } from 'react-dnd'
import { WorkflowPanel } from './workflow-panel'

interface IWorkflowScenePanelProps {
  plan: StoryboardPlanEntity
  selectedSceneId?: string
  onSelect: (id: string) => void
  sceneRoutes: Array<{ source: string; target: string; entryPredicates: Predicate[] }>
}

export const WorkflowScenePanel = (props: IWorkflowScenePanelProps) => {
  const { onSelect, plan, selectedSceneId, sceneRoutes } = props
  const scene = _.find(plan.core_storyboard_plan.scenes, { id: selectedSceneId })

  if (!scene) {
    return <p>No scene selected</p>
  }

  const preceedingScenes = _.filter(sceneRoutes, { target: selectedSceneId })
  const succeedingScenes = _.filter(sceneRoutes, { source: selectedSceneId })

  return (
    <WorkflowPanel>
      <h3>{scene.name}</h3>
      <i>{scene.id}</i>
      {preceedingScenes.length > 0 && (
        <div>
          <h4>Preceeding Scenes</h4>
          {_.map(preceedingScenes, ({ source, entryPredicates }) => (
            <WorkflowNavRoute
              onSelect={() => onSelect(source)}
              route={{
                pathId: source,
                entryPredicates,
              }}
            />
          ))}
        </div>
      )}
      {succeedingScenes.length > 0 && (
        <div>
          <h4>Succeeding Scenes</h4>
          {_.map(succeedingScenes, ({ target, entryPredicates }) => (
            <WorkflowNavRoute
              onSelect={() => onSelect(target)}
              route={{
                pathId: target,
                entryPredicates,
              }}
            />
          ))}
        </div>
      )}
      {scene.taskRoutes && scene.taskRoutes.length > 0 && (
        <div>
          <h4>Task Routes</h4>
          {_.map(scene.taskRoutes, (route) => (
            <WorkflowNavEntry entry={route} onSelect={onSelect} />
          ))}
        </div>
      )}
      {scene.uiView && (
        <JSONEditor
          entity={{ content: scene.uiView, setContent: () => null } as any}
          onChange={() => null}
        />
      )}
    </WorkflowPanel>
  )
}
