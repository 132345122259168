import _ from 'lodash'
import React from 'react'

import { SheetManager } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { FtpSettingsDetails } from 'browser/components/domains/firm/ftp-settings-details'

export const FtpSettings: React.FC = (props: any) => {
  return (
    <div className='grid-block'>
      <div className='grid-block vertical c-appBody'>
        <SheetManager className='grid-block vertical'>
          <FtpSettingsDetails />
        </SheetManager>
      </div>
    </div>
  )
}
